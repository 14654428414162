import av1 from '../../assets/images/av-1.png'
import av2 from '../../assets/images/av-2.png'
import av3 from '../../assets/images/av-3.png'
import av4 from '../../assets/images/av-4.png'
import av5 from '../../assets/images/av-5.png'
import av6 from '../../assets/images/av-6.png'
import av7 from '../../assets/images/av-7.png'
import av8 from '../../assets/images/av-8.png'

export default {
    report:[
        {
            "id":1,
            "study_circle":"10th A Class",
            "subject":"Maths",
            "topic":"Differentiability",
            "day":"8 Aug 2020",
            "avatar":av1,
            "name":"Gaurav Mehta",
            "score":75,
            "attendance":66,
            "activity":68
        },
        {
            "id":2,
            "study_circle":"10th A Class",
            "subject":"Maths",
            "topic":"Differentiability",
            "day":"8 Aug 2020",
            "avatar":av2,
            "name":"Gaurav Mehta",
            "score":75,
            "attendance":66,
            "activity":68
        },{
            "id":3,
            "study_circle":"10th A Class",
            "subject":"Maths",
            "topic":"Differentiability",
            "day":"8 Aug 2020",
            "avatar":av3,
            "name":"Gaurav Mehta",
            "score":75,
            "attendance":66,
            "activity":68
        },{
            "id":4,
            "study_circle":"10th A Class",
            "subject":"Maths",
            "topic":"Differentiability",
            "day":"8 Aug 2020",
            "avatar":av4,
            "name":"Gaurav Mehta",
            "score":75,
            "attendance":66,
            "activity":68
        },{
            "id":5,
            "study_circle":"10th A Class",
            "subject":"Maths",
            "topic":"Differentiability",
            "day":"8 Aug 2020",
            "avatar":av5,
            "name":"Gaurav Mehta",
            "score":75,
            "attendance":66,
            "activity":68
        },
        {
            "id":6,
            "study_circle":"10th A Class",
            "subject":"Maths",
            "topic":"Differentiability",
            "day":"8 Aug 2020",
            "avatar":av6,
            "name":"Gaurav Mehta",
            "score":75,
            "attendance":66,
            "activity":68
        },
        {
            "id":7,
            "study_circle":"10th A Class",
            "subject":"Maths",
            "topic":"Differentiability",
            "day":"8 Aug 2020",
            "avatar":av7,
            "name":"Gaurav Mehta",
            "score":75,
            "attendance":66,
            "activity":68
        },
        {
            "id":8,
            "study_circle":"10th A Class",
            "subject":"Maths",
            "topic":"Differentiability",
            "day":"8 Aug 2020",
            "avatar":av8,
            "name":"Gaurav Mehta",
            "score":75,
            "attendance":66,
            "activity":68
        },
        {
            "id":9,
            "study_circle":"10th A Class",
            "subject":"Maths",
            "topic":"Differentiability",
            "day":"8 Aug 2020",
            "avatar":av1,
            "name":"Gaurav Mehta",
            "score":75,
            "attendance":66,
            "activity":68
        },
        {
            "id":10,
            "study_circle":"10th A Class",
            "subject":"Maths",
            "topic":"Differentiability",
            "day":"8 Aug 2020",
            "avatar":av2,
            "name":"Gaurav Mehta",
            "score":75,
            "attendance":66,
            "activity":68
        }
    ]
}