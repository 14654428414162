import React from "react";
import { Route, Switch,Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import { Login,ResetPassword,NewPassword } from "./screens/index";
import layout from './components/Layout/Layout'
import "./App.css";

function App() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/admin" component={layout} />
      <Route exact path="/reset-password" component={ResetPassword}/>
      <Route exact path='/:user_id/new-password' component={NewPassword}/>
      <Redirect to ='/'/>
    </Switch>
  );
}

export default App;
