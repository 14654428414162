// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ManageStyle_txt__z85Fn{\n    text-align: center;\n    font-weight: 600;\n    padding-left: 7px;\n    margin-bottom: 8px;\n}\n.ManageStyle_dashboard_txt__23yYm{\n    color: #2E2B2B;\n    font-size: 16px;\n    line-height: 23px;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    font-weight: bold;\n    margin-bottom: 20px;\n  }\n  .ManageStyle_manage__QQ5HS{\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      align-content: center;\n      flex-direction: row;\n  }\n  .ManageStyle_search_box__1UmzR,\n.ManageStyle_download_list__29zaU {\n  margin-right: 15px;\n}\n.ManageStyle_search_box__1UmzR {\n  border: 1px solid #e3e3e3;\n  border-radius: 10px;\n  width: auto;\n  height: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}", ""]);
// Exports
exports.locals = {
	"txt": "ManageStyle_txt__z85Fn",
	"dashboard_txt": "ManageStyle_dashboard_txt__23yYm",
	"manage": "ManageStyle_manage__QQ5HS",
	"search_box": "ManageStyle_search_box__1UmzR",
	"download_list": "ManageStyle_download_list__29zaU"
};
module.exports = exports;
