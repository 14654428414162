import React, { useState, useEffect } from "react";
import { Box, Avatar } from "@material-ui/core";
import reportData from "./reportData";
import reportList from "./report";
import styles from "./ReportStyle.module.css";

const Reports = () => {
  const [state, setState] = useState({
    T_head: [],
    T_body: [],
    List: [],
  });
  useEffect(() => {
    setState({
      T_head: reportList.reportList[0].reportThead,
      List: reportList.reportList[0].reportlists,
      T_body: reportData.report,
    });
  }, [])// eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <div className={styles.rep_main}>
      <div className={styles.rep_left}>
        <Box className={styles.rep_left_b1} component="div">
          <div className={styles.leftdiv_box1_all}>All</div>
          <div className={styles.leftdiv_box1_bmk}>Bookmarks</div>
          <div className={styles.leftdiv_box1_rcnt}>Recent</div>
        </Box>
        <Box className={styles.rep_left_b2} component="div">
          <div className={styles.leftdiv_box2_box}></div>
        </Box>
        <Box className={styles.rep_left_b3} component="div">
          {state.List.map((el, key) => (
            <div className={styles.leftdiv_box3_reports} key={key}>
              <span
                className={styles.leftdiv_box3_reports_id}
              >{`#${el.id} - ${el.report}`}</span>
            </div>
          ))}
        </Box>
      </div>
      <div className={styles.rep_right}>
        <Box className={styles.detail} component="div"></Box>
        <Box className={styles.table_box} component="div">
          {
            <table className={styles.content_table}>
              <thead className={styles.t_head}>
                <tr>
                  {state.T_head.map((elm, key) => (
                    <th key={key} className={styles.t_h}>
                      {elm}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className={styles.t_body}>
                {state.T_body.map((elm, key) => (
                  <tr key={key} className={styles.t_r}>
                    <td>{elm.study_circle}</td>
                    <td>{elm.subject}</td>
                    <td>{elm.topic}</td>
                    <td>{elm.day}</td>
                    <td>
                      <Avatar
                        src={elm.avatar}
                        alt="avatar"
                        style={{ height: "16px", width: "16px" }}
                      />
                      {elm.name}
                    </td>
                    <td>{elm.score}</td>
                    <td>{elm.attendance} %</td>
                    <td>{elm.activity} %</td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
        </Box>
      </div>
    </div>
  );
};

export default Reports;

