// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MasterdataStyle_dashboard_txt__21EDU{\n    color: #2E2B2B;\n    font-size: 16px;\n    line-height: 23px;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    font-weight: bold;\n    margin-bottom: 20px;\n  }\n.MasterdataStyle_txt__6MZkD{\n    text-align: center;\n    font-weight: 600;\n    padding-left: 7px;\n    margin-bottom: 8px;\n}", ""]);
// Exports
exports.locals = {
	"dashboard_txt": "MasterdataStyle_dashboard_txt__21EDU",
	"txt": "MasterdataStyle_txt__6MZkD"
};
module.exports = exports;
