import React from "react";
import styles from "./resetpasswordStyle.module.css";
import { Divider, Box } from "@material-ui/core";
import { toast ,ToastContainer} from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import TextError from "../../variables/errors/Text_error";
import "react-toastify/dist/ReactToastify.css";
import Axios from "axios";
import { BASE_URL } from "../../config/Api";

const initialValues = {
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("invalid email").required("email is Required"),
});

const ResetPassword = () => {
  const onSubmit = (values) => {
    let email;
    email = values.email;
    Axios.post(`${BASE_URL}/mail/sendmail`, {
      email: email,
    }).then(
      (response) => {
        console.log(response,'resssssssssssssss')
        toast.success(response.data.message);
        if (response.data.status === true) {
          setTimeout(()=>{
            return window.location = "/"
          },3000)
        }
      },
      (error) => {
        console.log(error.response,'errrrrrrrrrrrrrrrrrrrr')
        toast.error(error.response.data.message);
        // setTimeout(()=>{
        //   return window.location = "/"
        // },3000)
      }
    );
  };

  return (
    <div className={styles.container}>
      <Box className={styles.header} p={1}>
        <div className={styles.header_body}>
          <img
            src={require("../../assets/images/stucle.png")}
            alt="header-logo"
            className={styles.image}
          />
        </div>
      </Box>
      <Divider
        style={{ background: "#A6C8A6", height: "1px", width: "100%" }}
      />
      <main className={styles.body}>
        <div className={styles.left}>
          <Box component="div" className={styles.reset_box} mb={4}>
            <Box component="p" className={styles.reset} mb={0}>
              Reset Password
            </Box>
            <p className={styles.txt}>
              Please reset the password for security reasons or if you cannot
              remember it. We will send you a link to your registered email id.
              Click on the link, reset the password and you are ready to go
              again!
            </p>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize
              className={styles.formik}
            >
              {(formik) => {
                return (
                  <div className={styles.form_div}>
                    <Form className={styles.form}>
                      <Box className={styles.username} component="div">
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Please enter your email id here"
                          className={styles.email_field}
                        />
                      </Box>
                      <ErrorMessage name="email" component={TextError}>
                        {(error) => <div className="error">{error}</div>}
                      </ErrorMessage>
                      <button
                        type="submit"
                        className={
                          !formik.isValid || formik.isSubmitting
                            ? styles.disabled
                            : styles.Button
                        }
                        disabled={!formik.isValid || formik.isSubmitting}
                      >
                        <div>Submit</div>
                      </button>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </Box>
        </div>
        <div className={styles.right}></div>
      </main>
      <footer className={styles.footer}>
        &copy; {new Date().getFullYear()} Apps'n'Devices Technologies Pvt Ltd.
        All rights reserved.
      </footer>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
    </div>
  );
};

export default ResetPassword;
