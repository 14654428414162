import React from "react";
import Datepicker from "react-datepicker";
import styles from "./Calander.module.css";

const Calander = (props) => {
  const {selected, onChange,dateFormat,showMonthYearPicker} = props
  return (
    <Datepicker
      id="DOB"
      selected={selected?selected:new Date()}
      onChange={onChange}
      className={styles.input}
      dateFormat={dateFormat ? dateFormat:"d MMM yyyy" }
      showYearDropdown
      maxDate={new Date()}
      showMonthYearPicker={showMonthYearPicker ? true : false}
      // readOnly={true}
    />
  );
};

export default Calander;
