import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,

} from "react";
import { Box, Avatar } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import Axios from "axios";
import styles from "./StudentStyle.module.css";
import { BASE_URL } from "../../../../config/Api";
import { toast, ToastContainer } from "react-toastify";
import { OverlayTrigger, Popover } from "react-bootstrap";
import ReactLoading from "react-loading";
import {debounce} from 'throttle-debounce';

var enabledarr = [];
// var disabledarr = [];
var csv = null;
var cmn_teacherID = false;
var pageNum = 1;
var rowsno = 10;
var enabled = true;
var std_circle = "study_circle_id=";
var searchtxt = "search_string=";
var IDG = "institution_defined_group=";
var default_page = 1;
var pageNo = 10;
var searching = false;

const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};
const Student = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    cmn_study_circle_json: [],
    tool_head: ["Study Circle", "Roll No."],
    cmn_student_name: "",
    cmn_student_profile_pic_file_path: "",
    t_body: [],
    allChecked: false,
    list: [],
    hover: false,
    itemChecked: [],
    inst_def_gr: [],
    studyCircle: [],
    loginEnable: false,
    t_head: [
      "Student",
      "Institution Student ID",
      "Registred Mobile Number",
      "Institution Defined Group",
      "Study Circle",
    ],
    pagecount: null,
    detInfo: [],
    login_enabled: "",
    checkAll: null,
    checkedID: null,
  });

  window.localStorage.setItem("tabIndexManage", 1);
  let schoolId = window.localStorage.getItem("school_id");
  const token = window.localStorage.getItem("token");
  const Auth = {
    headers: {
      Authorization: "Bearer "+ token,
    },
  };
  let enabled_load;
  window.localStorage.getItem("loginEnabled") == null
    ? (enabled_load = true)
    : (enabled_load = window.localStorage.getItem("loginEnabled"));

    const ApiData =  async() => {
      setIsLoading(true);
        const results = await (
          await Axios.get(
            `${BASE_URL}/institution/${schoolId}/student/manage/search?institution_defined_group=&search_string=&study_circle_id=&login_enabled=${enabled_load}&rows=&page_number=`,
            Auth
          )
        ).data;
  
        let instiDefGr = await (
          await Axios.get(
            `${BASE_URL}/institution/${schoolId}/institution-defined-group`,
            Auth
          )
        ).data;
        let studyCircle = await (
          await Axios.get(
            `${BASE_URL}/institution/${schoolId}/study-circle`,
            Auth
          )
        ).data;
  
        let extraPage = results.counts[0].count % pageNo === 0 ? 0 : 1;
        let l = [];
        for (let i = 0; i < results.data.length; i++) {
          l.push({
            name: results.data[i].cmn_student_name,
            id: results.data[i].cmn_student_id,
            isChecked: false,
          });
        }
        setIsLoading(false);
        setState({
          ...state,
          t_body: results.data,
          inst_def_gr: instiDefGr.data,
          studyCircle: studyCircle.data,
          list: l,
          pagecount: Math.floor(
            parseInt(results.counts[0].count) / pageNo + extraPage
          ),
          login_enabled: enabled_load,
        });
      }

  useEffect(() => {
    ApiData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const pagechange = (e, value) => {
    default_page = value;
    handleFilter(value, "selectPage");
  };

  useImperativeHandle(ref, () => ({
    getStudentFilter(e, selectedTxt) {
      handleFilter(e, "searchTxt");
    },
  }));

  const handleFilter = async (e, selectedvalue) => {
    //e.persist();
    
    if (selectedvalue === "selectedOpt") {
      enabled = e.target.value;
      enabled === "true"
        ? window.localStorage.setItem("loginEnabled", true)
        : window.localStorage.setItem("loginEnabled", false);
      pageNum = 1;
      default_page = 1;
    } else if (selectedvalue === "selectedSC") {
      std_circle = "study_circle_id=" + e.target.value;
      pageNum = 1;
      default_page = 1;
      if (e.target.value === "StudyCircle") {
        std_circle = "study_circle_id=";
        pageNum = 1;
        default_page = 1;
      }
    } else if (selectedvalue === "selectedIDG") {
      IDG = `institution_defined_group='${e.target.value}'`;
      pageNum = 1;
      default_page = 1;
      if (e.target.value === "InstitutionDefinedGroup") {
        IDG = "institution_defined_group=";
        pageNum = 1;
        default_page = 1;
      }
    } else if (selectedvalue === "searchTxt") {
      searchtxt = "search_string=" + e.target.value;
    } else if (selectedvalue === "selectRows") {
      rowsno = e.target.value;
      pageNum = 1;
      pageNo = e.target.value;
      default_page = 1;
    } else if (selectedvalue === "selectPage") {
      pageNum = e;
      // pageNo = e;
    }

    while (searching) {
      await sleep(500);
    }

    searching = true;
    setIsLoading(true);

    debounce(500, async (e) => {
    
      // call ajax
      await Axios.get(
        `${BASE_URL}` +
          "/institution/" +
          schoolId +
          "/student/manage/search?" +
          IDG +
          "&" +
          searchtxt +
          "&" +
          std_circle +
          "&" +
          "login_enabled=" +
          enabled +
          "&rows=" +
          rowsno +
          "&page_number=" +
          pageNum,
        Auth
      )
        .then((results) => {
          setIsLoading(false);
          let extraPage = parseInt(results.data.counts[0].count) % pageNo === 0 ? 0 : 1;
          setState({
            ...state,
            t_body: results.data.data,
            pagecount: Math.floor(
              parseInt(results.data.counts[0].count) / pageNo + extraPage
            ),
            login_enabled: enabled,
          });
        })
        .catch((err) => {
          console.log(err.response);
        });
  
    })()

    
    searching = false;
      };

  const checkboxSelect = (e, selectedopt) => {
    var enabled_value;
    enabled_value = e.target.checked;
    let val = e.target.value;

    if (enabled_value === true && selectedopt === "checkbox") {
      enabledarr.push(val);
      csv = enabledarr
        .map((elm) => {
          return elm;
        })
        .join(",")
        .replace(/(^\[)|(\]$)/gm, "");
      setState({ ...state, checkedID: enabledarr });
      document.getElementById("checkedcount").innerHTML = enabledarr.length;
    } else if (enabled_value === false && selectedopt === "checkbox") {
      let arrindex = enabledarr.indexOf(val);
      enabledarr.splice(arrindex, 1);
      csv = enabledarr
        .map((elm) => {
          return elm;
        })
        .join(",")
        .replace(/(^\[)|(\]$)/gm, "");
      setState({ ...state, checkedID: enabledarr });
      document.getElementById("checkedcount").innerHTML = enabledarr.length;
    } else if (enabled_value === true && selectedopt === "checkedall") {
      enabledarr = [];
      csv = state.t_body
        .map((elm) => {
          enabledarr.push(elm.cmn_student_id.toString());
          return elm.cmn_student_id;
        })
        .join(",")
        .replace(/(^\[)|(\]$)/gm, "");
      setState({
        ...state,
        checkAll: e.target.checked,
        checkedID: enabledarr,
      });
      document.getElementById("checkedcount").innerHTML = state.t_body.length;
    } else if (enabled_value === false && selectedopt === "checkedall") {
      enabledarr = [];
      csv = null;
      setState({
        ...state,
        checkAll: e.target.checked,
        checkedID: enabledarr,
      });
      document.getElementById("checkedcount").innerHTML = 0;
    }
    if (selectedopt === "selectbtn" && csv !== null) {
      if (state.login_enabled === "true") {
        cmn_teacherID = false;
      } else if (state.login_enabled === "false") {
        cmn_teacherID = true;
      }
      Axios.put(
        `${BASE_URL}/institution/${schoolId}/student/manage/login`,
        {
          enable_login: cmn_teacherID,
          cmn_student_ids: csv,
        },
        Auth
      )
        .then((res) => {
          if (state.login_enabled === "false") {
            toast.success("Student is Enabled");
          } else if (state.login_enabled === "true") {
            toast.success("Student is Disabled");
          }
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        })
        .catch((err) => {
          toast.error(err.response.data.data.message);
        });
    }
  };

  // const clickEnable = (e) => {
  //   console.log(state.itemChecked, "itemchecked");
  // };

  const Example = ({ type, color }) => (
    <div className="loading_animation">
      <ReactLoading type="bars" color="#DCEEDC" height="10%" width="10%" />
    </div>
  );

  const handleClick = (elm) => {
    setState({
      ...state,
      cmn_study_circle_json: elm.cmn_study_circle_json,
      cmn_student_name: elm.cmn_student_name,
      cmn_student_profile_pic_file_path: elm.cmn_student_profile_pic_file_path,
    });
    setShow(!show);
  };

  const popover = (
    <Popover
      id="popover-basic"
      style={{ marginLeft: "-50px" }}
      className={styles.tool}
    >
      <Popover.Title as="h3" className={styles.popT}>
        {" "}
        <Avatar
          src={require("../../../../assets/images/av-1.png")}
          style={{ width: "20px", height: "20px", marginRight: "15px" }}
        />{" "}
        {state.cmn_student_name}
      </Popover.Title>
      <Popover.Content className={styles.popB}>
        <table className={styles.toolTbl}>
          <thead style={{ padding: "20px" }}>
            <tr className={styles.trs}>
              {state.tool_head.map((el, key) => (
                <th key={key}>{el}</th>
              ))}
            </tr>
          </thead>
          <tbody style={{height:'100%',display:'flow-root'}}>
            {state.cmn_study_circle_json.map((elm, key) => (
              <tr
                key={key}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "34px",
                  paddingRight: "34px",
                  paddigTop:'5px',
                  paddingBottom:'5px',
                  marginTop:'5px'
                }}
              >
                <td>{elm.cmn_study_circle_name}</td>
                <td>{elm.student_roll_number}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Popover.Content>
    </Popover>
  );

  const addTableData = () => {
    return (
      <>
        {isLoading === true ? (
          <Example />
        ) : (
          // <div className={styles.schoolList_Table}>
          //       <div className={styles.schoolList_Table_head}>
          //         {state.study_circle_head.map((elm, key) => (
          //           <div
          //             key={key}
          //             className={styles.schoolList_Table_head_data}
          //           >
          //             {elm}
          //           </div>
          //         ))}
          //       </div>
          //       {
          //         <div className={styles.schoolList_Table_body}>
          //           {state.study_circle_body.length === 0
          //             ? "no data found"
          //             : state.study_circle_body.map((elm, key) => (
          //                 <div
          //                   key={key}
          //                   className={styles.schoolList_Table_body_row}
          //                   style={{ cursor: "pointer" }}
          //                 >
          //                   <div
          //                     className={styles.schoolList_Table_body_row_td2}
          //                   >
          //                     {elm.cmn_study_circle_name}
          //                   </div>
          //                   <div
          //                     className={styles.schoolList_Table_body_row_td3}
          //                   >
          //                     {elm.cmn_subject_name}
          //                   </div>
          //                   <div
          //                     className={styles.schoolList_Table_body_row_td3}
          //                   >
          //                     {elm.role}
          //                   </div>
          //                 </div>
          //               ))}
          //         </div>
          //       }
          //     </div>

          <table className={styles.content_table}>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    name="checkAll"
                    defaultChecked={state.checkAll}
                    value="checkedall"
                    onClick={(e) => checkboxSelect(e, "checkedall")}
                  />
                </th>
                {state.t_head.map((elm, key) => (
                  <th key={key}>{elm}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {state.t_body.length === 0 ? (
                <tr>
                  <td colSpan={6}>{"No Data Found"}</td>
                </tr>
              ) : (
                state.t_body &&
                state.t_body.map((elm, key) => (
                  <tr
                    key={key}
                    className={
                      state.checkedID !== null &&
                      state.checkedID.find((el) => `${elm.cmn_student_id}` === el)
                        ? styles.chkStd
                        : styles.name
                    }
                  >
                    <td>
                      <input
                        type="checkbox"
                        name={elm.cmn_student_id}
                        value={elm.cmn_student_id}
                        defaultChecked={state.checkAll}
                        // checked={state.list[key].isChecked}
                        onClick={(e) => checkboxSelect(e, "checkbox")}
                      />
                    </td>
                    <td>
                      <OverlayTrigger
                        trigger={(`${"focus"}`, `${"click"}`)}
                        placement="right"
                        overlay={popover}
                      >
                        <div className={styles.student_avt}>
                          <div>
                            <Avatar
                              src={elm.avatar}
                              alt="avatar"
                              style={{
                                width: "22px",
                                height: "22px",
                                marginRight: "10px",
                              }}
                            />
                          </div>
                          <div onClick={() => handleClick(elm)}>
                            {elm.cmn_student_name}
                          </div>
                        </div>
                      </OverlayTrigger>
                    </td>
                    <td id="name">
                      <div>{elm.cmn_student_id}</div>
                    </td>
                    <td>
                      <div>{elm.cmn_student_phone_number}</div>
                    </td>
                    <td>
                      <div>{elm.cmn_student_institution_defined_group}</div>
                    </td>
                    <td>
                      <div>{elm.cmn_study_circle_count}</div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        )}
      </>
    );
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.topBox}>
        <Box component="div" className={styles.left}>
          <Box
            component="select"
            className={styles.options}
            value={state.login_enabled}
            onChange={(e) => handleFilter(e, "selectedOpt")}
          >
            <option value={true}>Login Enabled</option>
            <option value={false}>Login Disabled</option>
          </Box>
          <Box component="div">
            <select
              className={styles.options}
              onChange={(e) => handleFilter(e, "selectedSC")}
            >
              <option value="StudyCircle">Study Circle</option>
              {state.studyCircle.map((el, key) => (
                <option value={el.cmn_study_circle_id} key={key}>
                  {el.cmn_study_circle_name}
                </option>
              ))}
            </select>
          </Box>
          <Box component="div">
            <Box
              component="select"
              className={styles.options}
              onChange={(e) => handleFilter(e, "selectedIDG")}
            >
              <option value="InstitutionDefinedGroup">
                Institution Defined Group
              </option>
              {state.inst_def_gr.map((el, key) => (
                <option
                  value={el.cmn_student_institution_defined_group}
                  key={key}
                >
                  {el.cmn_student_institution_defined_group}
                </option>
              ))}
            </Box>
          </Box>
        </Box>
        <Box component="div" className={styles.right}>
          <Box component="div" className={styles.educator_sel}>
            <span id="checkedcount">0</span>
            <span> Student Selected</span>
          </Box>
          <Box
            component="button"
            className={
              state.login_enabled === "false"
                ? styles.buttons_enable
                : styles.buttons_disable
            }
            onClick={(e) => checkboxSelect(e, "selectbtn")}
          ></Box>
        </Box>
      </Box>
      <Box component="div" className={styles.tableBox}>
        <div className={styles.table_box}>{addTableData()}</div>
      </Box>
      <Box className={styles.bottomBox} component="div">
        <div
          className="col-md-10"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "50px",
          }}
        >
          <Pagination
            count={state.pagecount}
            page={default_page}
            onChange={pagechange}
          />
        </div>
        <div className="col-md-2">
          <Box
            component="select"
            className={styles.options}
            onChange={(e) => handleFilter(e, "selectRows")}
          >
            <option value="10">{"Show 10"}</option>
            <option value="20">{"Show 20"}</option>
          </Box>
        </div>
      </Box>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
    </Box>
  );
});

export default Student;
