import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
// import { Pagination } from "@material-ui/lab";
// import { fade, makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { BASE_URL } from "../../../config/Api";
import styles from "./StudentStyle.module.css";
import Calander from "../../../components/calander/Calander";
// import fakeImg from "../../../assets/images/av-1.png";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { saveAs } from "file-saver";

// let months = [
//   "January",
//   "February",
//   "March",
//   "April",
//   "May",
//   "June",
//   "July",
//   "August",
//   "September",
//   "October",
//   "November",
//   "December",
// ];
let t_head = [
  "Study Circle",
  "Institution Defined Group",
  "Subject",
  "Day",
  "Student",
  "Score",
  "Attendance",
  "Activeness",
];
let prevDate;
let nextDate;
let totaldaysInMonth;
let studyCircleID = 0;
let subjectId = 0;
let daySelected = 0;
let studentId = 0;
let idf = 0;
let cmn_study_circle_id = "";
let qb_class_id = "";
let subject_id = "";

const LiveClass = (props) => {
  let date_format;
  if (props.location.dashboardProps) {
    date_format = new Date(props.location.dashboardProps);
    localStorage.setItem("studentCal", date_format);
  } else
    date_format =
      localStorage.getItem("studentCal") === null
        ? new Date()
        : new Date(localStorage.getItem("studentCal"));

  let month = date_format.getMonth();
  let year = date_format.getFullYear();

  const [date, setDate] = useState(date_format);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    t_head: [],
    t_body: [],
    Study_circle_opt: [],
    subject_opt: [],
    student_opt: [],
    idf_opt: [],
    pagecount: null,
  });

  const schoolID = window.localStorage.getItem("school_id");
  const token = window.localStorage.getItem("token");
  const Auth = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };
  totaldaysInMonth = getDaysInMonth(month + 1, year);

  useEffect(() => {
    prevDate = `${year}/${month + 1}/01`;
    nextDate = year + "/" + (month + 1) + "/" + totaldaysInMonth;

    async function fetchData() {
      setIsLoading(true);
      const studyCircleList = axios.get(
        `${BASE_URL}/institution/${schoolID}/status/study-circle`,
        Auth
      );

      const idgList = axios.get(
        `${BASE_URL}/institution/${schoolID}/institution-defined-group`,
        Auth
      );

      const studentList = axios.get(
        `${BASE_URL}/institution/${schoolID}/status/student-list?cmn_study_circle_id=${studyCircleID}`,
        Auth
      );

      const subjectList = axios.get(
        `${BASE_URL}/institution/${schoolID}/status/subject`,
        Auth
      );

      const tableLists = axios.get(
        `${BASE_URL}/institution/${schoolID}/status/student?cmn_study_circle_id=${studyCircleID}&cmn_subject_id=${subjectId}&cmn_student_id=${studentId}&day=${daySelected}&idf=${idf}&from_date=${prevDate}&to_date=${nextDate}`,
        Auth
      );

      await axios
        .all([studyCircleList, subjectList, tableLists, idgList, studentList])
        .then(
          axios.spread((...res) => {
            setIsLoading(false);
            const studyCircleData = res[0].data;
            const subjectListData = res[1].data;
            const tableDataList = res[2].data;
            const institutionDefinedGroup = res[3].data;
            const studentListData = res[4].data;
            setState({
              ...state,
              t_body: tableDataList.data,
              Study_circle_opt: studyCircleData.data,
              subject_opt: subjectListData.data,
              idf_opt: institutionDefinedGroup.data,
              student_opt: studentListData.data,
            });
          })
        )
        .catch((err) => {
          console.log(err.response);
          setIsLoading(false);
        });
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleChange = async (e, selectedvalue) => {
    setIsLoading(true);
    if (selectedvalue === "studyCircle") {
      studyCircleID = e.target.value;
      const { selectedIndex } = e.target.options;
      let index = selectedIndex < 2 ? selectedIndex : selectedIndex - 2;
      const { primary_qb_class_id } = state.Study_circle_opt[index];
      qb_class_id = selectedIndex < 2 ? "" : primary_qb_class_id;
      subject_id = "";
    } else if (selectedvalue === "Subject") {
      subjectId = e.target.value;
      const { selectedIndex } = e.target.options;
      let index = selectedIndex < 2 ? selectedIndex : selectedIndex - 2;
      const { cmn_subject_id } = state.subject_opt[index];
      subject_id = selectedIndex < 2 ? "" : cmn_subject_id;
    } else if (selectedvalue === "searchField") {
    } else if (selectedvalue === "idg") {
      idf = e.target.value;
    } else if (selectedvalue === "student") {
      studentId = e.target.value;
      // const { selectedIndex } = e.target.options;
      // let index = selectedIndex < 2 ? selectedIndex : selectedIndex - 2;
      // const { cmn_student_id } = state.student_opt[index];
      // subject_id = selectedIndex < 2 ? "" : cmn_student_id;
    } else if (selectedvalue === "day") {
      daySelected = e.target.value;
    } else if (selectedvalue === "calander") {
      let date_val = new Date(e);
      month = date_val.getMonth();
      year = date_val.getFullYear();
      localStorage.setItem("studentCal", date_val);
      const getDaysInMonth_handlechange = (month, year) => {
        return new Date(year, month, 0).getDate();
      };
      const totaldaysInMonth_handlechange = getDaysInMonth_handlechange(
        month + 1,
        year
      );
      prevDate = `${year}/${month + 1}/01`;
      nextDate = year + "/" + (month + 1) + "/" + totaldaysInMonth_handlechange;
      setDate(e);
    }
    const studyCircleList = axios.get(
      `${BASE_URL}/institution/${schoolID}/status/study-circle`,
      Auth
    );

    const subjectList = axios.get(
      `${BASE_URL}/institution/${schoolID}/status/subject?qb_class_id=${qb_class_id}`,
      Auth
    );

    const tableList = axios.get(
      `${BASE_URL}/institution/${schoolID}/status/student?cmn_study_circle_id=${studyCircleID}&cmn_subject_id=${subjectId}&cmn_student_id=${studentId}&day=${daySelected}&idf=${idf}&from_date=${prevDate}&to_date=${nextDate}`,
      Auth
    );

    const studentListData = axios.get(
      `${BASE_URL}/institution/${schoolID}/status/student-list?cmn_study_circle_id=${studyCircleID}`,
      Auth
    );

    await axios
      .all([studyCircleList, subjectList, tableList, studentListData])
      .then(
        axios.spread((...res) => {
          setIsLoading(false);
          const studyCircleData = res[0].data;
          const subjectListData = res[1].data;
          const tableListData = res[2].data;
          const studentOptions = res[3].data;

          setState({
            ...state,
            t_body: tableListData.data,
            Study_circle_opt: studyCircleData.data,
            subject_opt: subjectListData.data,
            student_opt: studentOptions.data,
          });
        })
      )
      .catch((err) => {
        console.log(err.response);
      });
  };

  const downloadAttendanceFile = async () =>{
    if(daySelected >= 1){
      const getDaysInMonth_handlechange = (month, year) => {
        return new Date(year, month, 0).getDate();
      };
      const totaldaysInMonth_handlechange = getDaysInMonth_handlechange(
        month + 1,
        year
      );
  
      prevDate = `${year}/${month + 1}/01`;
      nextDate = year + "/" + (month + 1) + "/" + totaldaysInMonth_handlechange;
      await axios
        .get(
          `${BASE_URL}/institution/${schoolID}/status/student/attendance/export?&day=${daySelected}&from_date=${prevDate}&to_date=${nextDate}`,
          {
            ...Auth,
            responseType: "arraybuffer",
          }
        )
        .then((response) => {
          var blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          saveAs(blob, "LiveAttendanceStudentReport.xlsx");
          toast.success("file downloaded");
        })
        // .catch((err) => console.log(JSON.parse(String.fromCharCode.apply(null, new Uint8Array(err.response.data))).message));
        // .catch((err) => console.log(err.response));
        .catch((err) => {
          let error = JSON.parse(
            String.fromCharCode.apply(null, new Uint8Array(err.response.data))
          ).message;
          toast.error(error);
        });
    } else {
      toast.error("Select a day");
    }
  }

  const downloadFile = async () => {
    const getDaysInMonth_handlechange = (month, year) => {
      return new Date(year, month, 0).getDate();
    };
    const totaldaysInMonth_handlechange = getDaysInMonth_handlechange(
      month + 1,
      year
    );

    prevDate = `${year}/${month + 1}/01`;
    nextDate = year + "/" + (month + 1) + "/" + totaldaysInMonth_handlechange;
    await axios
      .get(
        `${BASE_URL}/institution/${schoolID}/status/student/export?cmn_study_circle_id=${studyCircleID}&cmn_subject_id=${subjectId}&cmn_student_id=${studentId}&day=${daySelected}&idf=${idf}&from_date=${prevDate}&to_date=${nextDate}`,
        {
          ...Auth,
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        var blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        saveAs(blob, "LiveClassStudentReport.xlsx");
        toast.success("file downloaded");
      })
      // .catch((err) => console.log(JSON.parse(String.fromCharCode.apply(null, new Uint8Array(err.response.data))).message));
      // .catch((err) => console.log(err.response));
      .catch((err) => {
        let error = JSON.parse(
          String.fromCharCode.apply(null, new Uint8Array(err.response.data))
        ).message;
        toast.error(error);
      });
  };

  let datesofMonth = [];
  for (let k = 1; k <= totaldaysInMonth; k++) {
    datesofMonth.push(k);
  }
  const Example = ({ type, color }) => (
    <div className="loading_animation">
      <ReactLoading type="bars" color="#DCEEDC" height="10%" width="10%" />
    </div>
  );

  return (
    <Box className={styles.container} component="div">
      <Box className={styles.header} component="div">
        <Box component="div" className={styles.top_bar1}>
          <Box className={styles.live_txt} component="div">
            STUDENT
          </Box>
          <div style={{display:"flex"}}>
          <div className={styles.upload} onClick={downloadAttendanceFile}>
            <label
              htmlFor="filetodownloadStudentAttendance"
              style={{
                borderRadius: "10px",
                width: "98px",
                height: "32px",
                display: "flex",
                textAlign:"center",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "25px",
                cursor: "pointer",
                marginRight: "10px",
                border: "1px solid ",
                paddingRight:'12px'
              }}
            >
              <img
                src={require("../../../assets/icons/down.png")}
                alt="up"
                style={{ marginRight: "10px",paddingLeft:'12px'}}
              />
              Daily Attendance
            </label>
          </div>
          <div className={styles.upload} onClick={downloadFile}>
            <label
              htmlFor="filetouploadEducatorSubject"
              style={{
                borderRadius: "10px",
                width: "98px",
                height: "32px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "25px",
                cursor: "pointer",
                marginRight: "10px",
                border: "1px solid ",
              }}
            >
              <img
                src={require("../../../assets/icons/down.png")}
                alt="up"
                style={{ marginRight: "10px" }}
              />
              Download
            </label>
          </div>
          </div>
          {/* <Box component="div" className={styles.search_box}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <img
                  src={require("../../../assets/icons/search.png")}
                  alt="search"
                />
              </div>
              <InputBase
                placeholder="Search"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => {
                  handleChange(e, "searchField");
                }}
              />
            </div>
          </Box> */}
        </Box>
        <Box component="div" className={styles.top_bar2}>
          <div className={styles.top_bar2_left}>
            <div>
              <label htmlFor="study_circle" className={styles.labels}>
                Study Circle
              </label>
              <div className={styles.study_cir_opt}>
                <select
                  name=""
                  id=""
                  className={styles.select1}
                  onChange={(e) => {
                    handleChange(e, "studyCircle");
                  }}
                  value={studyCircleID}
                >
                  <option value="0">All Split</option>
                  <option value="-1">All Together</option>
                  {state.Study_circle_opt.map((el, key) => (
                    <option value={el.cmn_study_circle_id} key={key}>
                      {el.cmn_study_circle_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="idg" className={styles.labels}>
                Institution Defined Group
              </label>
              <div className={styles.subject_opt}>
                <select
                  name=""
                  id=""
                  className={styles.select2}
                  onChange={(e) => {
                    handleChange(e, "idg");
                  }}
                  value={idf}
                >
                  <option value="0">All Split</option>
                  <option value="-1">All Together</option>

                  {state.idf_opt.map((el, key) => (
                    <option
                      value={el.cmn_student_institution_defined_group}
                      key={key}
                    >
                      {el.cmn_student_institution_defined_group}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="subject" className={styles.labels}>
                Subject
              </label>
              <div className={styles.subject_opt}>
                <select
                  name=""
                  id=""
                  className={styles.select2}
                  onChange={(e) => {
                    handleChange(e, "Subject");
                  }}
                  value={subjectId}
                >
                  <option value="0">All Split</option>
                  <option value="-1">All Together</option>

                  {state.subject_opt.map((el, key) => (
                    <option value={el.cmn_subject_id} key={key}>
                      {el.cmn_subject_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="educator" className={styles.labels}>
                Day
              </label>
              <div className={styles.educator_opt}>
                <select
                  name=""
                  id=""
                  className={styles.select3}
                  onChange={(e) => {
                    handleChange(e, "day");
                  }}
                  value={daySelected}
                >
                  <option value="0">All Split</option>
                  <option value="-1">All Together</option>
                  {datesofMonth.map((el, key) => (
                    <option value={el} key={key}>
                      {el}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="student" className={styles.labels}>
                Student
              </label>
              <div className={styles.educator_opt}>
                <select
                  name=""
                  id=""
                  className={styles.select3}
                  onChange={(e) => {
                    handleChange(e, "student");
                  }}
                  value={studentId}
                >
                  <option value="0">All Split</option>
                  <option value="-1">All Together</option>
                  {state.student_opt.map((el, key) => (
                    <option value={el.cmn_student_id} key={key}>
                      {el.cmn_student_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className={styles.top_bar2_right}>
            <Calander
              selected={date}
              onChange={(e) => handleChange(e, "calander")}
              id="date"
              name="date"
              type="date"
              dateFormat="MMM yyyy"
              showMonthYearPicker
            />
          </div>
        </Box>
      </Box>
      <Box className={styles.body} component="div">
        <div className={styles.table_box}>
          {isLoading === true ? (
            <Example />
          ) : (
            <div className={styles.schoolList_Table}>
              <div className={styles.schoolList_Table_head}>
                {t_head.map((elm, key) => (
                  <div key={key} className={styles.schoolList_Table_head_data}>
                    {elm}
                  </div>
                ))}
              </div>
              {
                <div className={styles.schoolList_Table_body}>
                  {state.t_body.length === 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                      }}
                    >
                      No Data Found
                      <span role="img" aria-label="jsx-a11y/accessible-emoji">
                        😔
                      </span>
                    </div>
                  ) : (
                    state.t_body.map((elm, key) => (
                      <div
                        key={key}
                        className={styles.schoolList_Table_body_row}
                        style={{ cursor: "pointer" }}
                      >
                        <div className={styles.schoolList_Table_body_row_td3}>
                          {elm.cmn_study_circle_name}
                        </div>
                        <div className={styles.schoolList_Table_body_row_td3}>
                          {elm.inst_defined_group}
                        </div>
                        <div className={styles.schoolList_Table_body_row_td3}>
                          {elm.cmn_subject_name}
                        </div>
                        <div className={styles.schoolList_Table_body_row_td3}>
                          {elm.activity_date}
                        </div>
                        <div className={styles.schoolList_Table_body_row_td3}>
                          {elm.cmn_student_name}
                        </div>
                        <div className={styles.schoolList_Table_body_row_td3}>
                          {elm.avg_score.toFixed(2)}
                        </div>
                        <div className={styles.schoolList_Table_body_row_td3}>
                          {Number(elm.avg_attendance * 100).toFixed(2)} %
                        </div>
                        <div className={styles.schoolList_Table_body_row_td3}>
                          {elm.avg_activeness ? elm.avg_activeness : 0} %
                        </div>
                      </div>
                    ))
                  )}
                </div>
              }
            </div>
          )}
        </div>
      </Box>
      <Box className={styles.footer} component="div">
        {/* <Pagination
          count={10}
          // count={state.pagecount}
          // page={default_page}
          // defaultPage={default_page}
          // onChange={pagechange}
        /> */}
      </Box>
    </Box>
  );
};

export default LiveClass;
