import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import axios from "axios";

import styles from "./Settings.module.css";
import { BASE_URL } from "../../config/Api";

const Settings = (props) => {
  const [state, setState] = useState({
    att_activeness_cutoff: "",
    att_late_cutoff: "",
    att_viewtime_cutoff: "",
    mark_present_for_offline_viewing: false,
    parent_report_interval: "",
    score_type: "",
    show_att_to_child: false,
  });

  const schoolID = window.localStorage.getItem("school_id");
  const token = window.localStorage.getItem("token");
  const Auth = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${BASE_URL}/institution/${schoolID}/settings`, Auth)
        .then((res) => {
          console.log(res.data, "res");
          setState({
            ...state,
            att_activeness_cutoff: res.data.data.att_activeness_cutoff,
            att_late_cutoff: res.data.data.att_late_cutoff,
            att_viewtime_cutoff: res.data.data.att_viewtime_cutoff,
            mark_present_for_offline_viewing:
              res.data.data.mark_present_for_offline_viewing,
            parent_report_interval: res.data.data.parent_report_interval,
            score_type: res.data.data.score_type,
            show_att_to_child: res.data.data.show_att_to_child,
          });
        })
        .catch((err) => {
          console.log(err.response);
        });
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleSave = async () => {
    return await axios
      .put(
        `${BASE_URL}/institution/${schoolID}/settings`,
        {
          score_type: state.score_type,
          att_late_cutoff: state.att_late_cutoff,
          att_activeness_cutoff: state.att_activeness_cutoff,
          att_viewtime_cutoff: state.att_viewtime_cutoff,
          show_att_to_child: state.show_att_to_child,
          mark_present_for_offline_viewing:
            state.mark_present_for_offline_viewing,
          parent_report_interval: state.parent_report_interval,
        },
        Auth
      )
      .then((res) => {
        setState({
          ...state,
          att_activeness_cutoff: res.data.data.att_activeness_cutoff,
          att_late_cutoff: res.data.data.att_late_cutoff,
          att_viewtime_cutoff: res.data.data.att_viewtime_cutoff,
          mark_present_for_offline_viewing:
            res.data.data.mark_present_for_offline_viewing,
          parent_report_interval: res.data.data.parent_report_interval,
          score_type: res.data.data.score_type,
          show_att_to_child: res.data.data.show_att_to_child,
        });
      })
      .catch((err) => console.log(err, "error"));
  };

  return (
    <>
      <Box component="div" className={styles.settingsTxt} mb={3}>
        SETTINGS
      </Box>
      <Box className={styles.mainBox} component="div">
        <Box className={styles.score} component="div">
          <label htmlFor="score" className={styles.label}>
            Score
          </label>
          <div className={styles.scrt}>Select Score type</div>
          <select
            name="score"
            id="score"
            className={styles.select}
            onChange={(e) => {
              setState({
                ...state,
                score_type: e.target.value,
              });
            }}
            value={state.score_type}
          >
            <option value="Base 5">Base 5</option>
            <option value="Base 10">Base 10</option>
            <option value="Base 100">Base 100</option>
          </select>
        </Box>
        <Box className={styles.student_attendance} component="div">
          <label htmlFor="student_attendance" className={styles.label}>
            Student Attendance
          </label>
          <div className={styles.attendance}>
            <div className={styles.select_1}>
              <div className={styles.scrt}>Present if Late by</div>
              <select
                name="student_attendance"
                id="student_attendance"
                className={styles.select}
                onChange={(e) => {
                  setState({
                    ...state,
                    att_late_cutoff: e.target.value,
                  });
                }}
                value={state.att_late_cutoff}
              >
                <option value="10">10 Min</option>
                <option value="5">5 Min</option>
                <option value="15">15 Min</option>
                <option value="20">20 Min</option>
              </select>
            </div>
            <div className={styles.select_1} style={{ paddingLeft: "25px" }}>
              <div className={styles.scrt}>Present if activeness more than</div>
              <select
                name="student_attendance"
                id="student_attendance"
                className={styles.select}
                onChange={(e) => {
                  setState({
                    ...state,
                    att_activeness_cutoff: e.target.value,
                  });
                }}
                value={state.att_activeness_cutoff}
              >
                <option value="10">10%</option>
                <option value="20">20%</option>
                <option value="50">50%</option>
                <option value="80">80%</option>
                <option value="100">100%</option>
              </select>
            </div>
            <div className={styles.select_1} style={{ paddingLeft: "25px" }}>
              <div className={styles.scrt}>Present if view time more than</div>
              <select
                name="student_attendance"
                id="student_attendance"
                className={styles.select}
                onChange={(e) => {
                  setState({
                    ...state,
                    att_viewtime_cutoff: e.target.value,
                  });
                }}
                value={state.att_viewtime_cutoff}
              >
                <option value="10">10%</option>
                <option value="20">20%</option>
                <option value="50">50%</option>
                <option value="80">80%</option>
                <option value="100">100%</option>
              </select>
            </div>
          </div>
          <div className={styles.ckbx}>
            <div className={styles.check}>
              <div className={styles.txt}>
                <form action="" method="get">
                  <input
                    type="checkbox"
                    name="network"
                    id="network"
                    className={styles.checkbox}
                    onChange={(e) => {
                      setState({
                        ...state,
                        mark_present_for_offline_viewing: e.target.checked,
                      });
                    }}
                    checked={state.mark_present_for_offline_viewing}
                    value={state.mark_present_for_offline_viewing}
                  />
                </form>
                Mark Present for offline viewing
              </div>
            </div>
            <div className={styles.check}>
              <div className={styles.txt}>
                <form action="" method="get">
                  <input
                    type="checkbox"
                    name="attendance"
                    id="attendance"
                    className={styles.checkbox}
                    value={state.show_att_to_child}
                    onChange={(e) => {
                      setState({
                        ...state,
                        show_att_to_child: e.target.checked,
                      });
                    }}
                    checked={state.show_att_to_child}
                  />
                </form>
                {/* {state.att_viewtime_cutoff} */}
                Show Live class attendance to Child
              </div>
            </div>
          </div>
        </Box>
        <Box className={styles.parent_report} component="div">
          <label htmlFor="Parent_report" className={styles.label}>
            Student Report to Parent
          </label>
          <div className={styles.scrt}>Interval</div>
          <select
            name="Parent_report"
            id="Parent_report"
            className={styles.select}
            onChange={(e) => {
              setState({
                ...state,
                parent_report_interval: e.target.value,
              });
            }}
            value={state.parent_report_interval}
          >
            <option value="Every 10days">Every 10days</option>
            <option value="Weekly">Weekly</option>
            <option value="Monthly">Monthly</option>
            <option value="Fortnightly">Fortnightly</option>
          </select>
        </Box>
        <Box component="div" className={styles.last}>
          {/* <Box component="div" className={styles.boxes}>
            <Box component="div" className={styles.box1}>
              
              <div >Select Score Type</div>
            <div >{state.score_type}</div>
            </Box>
            <Box component="div" className={styles.box2}>
              <div>Present if late by</div>
              <div>{state.att_late_cutoff} min</div>
            </Box>
            <Box component="div" className={styles.box3}>
              <div>Interval</div>
              <div>{state.parent_report_interval}</div>
            </Box>
          </Box> */}
          <Box className={styles.buttons} component="div">
            <Box className={styles.cancel} component="button">
              Cancel
            </Box>
            <Box
              className={styles.save}
              component="button"
              onClick={handleSave}
            >
              Save
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Settings;
