import React, { useState, useEffect, useRef } from "react";
import { Box, Icon, Avatar, InputBase } from "@material-ui/core";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import TextError from "../../../../variables/errors/Text_error";
import styles from "./EducatorStyle.module.css";
import Axios from "axios";
import * as ReactBootStrap from "react-bootstrap";
import { saveAs } from "file-saver";
import { BASE_URL } from "../../../../config/Api";
import {
  ModalComponent,
  Dialogbox,
  Calander,
} from "../../../../components/index";

import dum from "../../../../assets/images/av-3.png";

const initialValues = {
  Name: "",
  DOB: "",
  reg_mob_no: "",
  alt_mob: "",
  city: "",
  pincode: "",
  isp: "",
};
const initialAddVal = {
  subject: "",
  board: "",
  class: "",
};
var DateOfBirth;

const validationAdd = Yup.object({
  subject: Yup.string(),
  board: Yup.string(),
  class: Yup.string(),
});
const validationSchema = Yup.object({
  Name: Yup.string().min(1, "educator name must contain a character"),
  // .required('An Educator Must Be a Name'),
  DOB: Yup.string(),
  reg_mob_no: Yup.number(),
  // .min(10,'Number must be 10 digits')
  // .required('An Educator Must Be a Contact Number')
  alt_mob: Yup.number(),
  city: Yup.string(),
  pincode: Yup.number(),
  int_service_provider: Yup.string(),
});

var boardID;
var classID;
var subjectID;

const Educator = () => {
  const [open, setOpen] = useState(false);
  const [errorUpload, setErrorUpload] = useState(false);
  const [msg, setMsg] = useState("");
  const [errMsg, setErrMsg] = useState([]);
  const [addEd, setEd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editVal, setEditVal] = useState(false);
  // const [calander, setCalander] = useState(false);
  const [state, setState] = useState({
    educatorList: [],
    subject_head: ["Board", "Grade", "Subject", ""],
    subject_body: [],
    study_circle_head: ["Study Circle", "Subject", "Role"],
    study_circle_body: [],
    drop_Board: [],
    drop_class: [],
    drop_Subject: [],
    cmn_teacher_alt_phone_number: "",
    cmn_teacher_city: "",
    cmn_teacher_dob: new Date().toDateString(),
    cmn_teacher_id: "",
    cmn_teacher_name: "",
    cmn_teacher_phone_number: "",
    cmn_teacher_pincode: "",
    cmn_teacher_isp: "",
    educatorId: "",
    isp: "",
    board: "",
    class: "",
    subject: "",
    showHide: false,
    activeId: null,
    activeEducatorId: null,
    Name: "",
    DOB: "",
    reg_mob_no: "",
  });

  const uploadEducatorList = useRef(null);
  // const uploadSubjectList = useRef(null);

  const school_id = window.localStorage.getItem("school_id");
  window.localStorage.setItem("tabIndex", 3);
  const token = window.localStorage.getItem("token");
  const Auth = {
    headers: {
      Authorization: "Bearer "+ token,
    },
  };

  const handleClose = (value) => {
    setState({ ...state, showHide: false });
    setOpen(false);
  };
  const handleClickOpen = () => {
    setState({ ...state, showHide: true });
  };

  const handleClick = (event) => {
    uploadEducatorList.current.click();
  };

  useEffect(() => {
    const fetchResults = async () => {
      const educatorList = await (
        await Axios.get(
          `${BASE_URL}/institution/${school_id}/educator/list`,
          Auth
        )
      ).data;
      const getBoard = await (
        await Axios.get(`${BASE_URL}/institution/${school_id}/board`, Auth)
      ).data;
      setState({
        ...state,
        educatorList: educatorList.data,
        drop_Board: getBoard.data,
      });
      if (window.localStorage.getItem("detailInfo_id") == null) {
        if (educatorList.data.length === 0) {
          console.log(educatorList.data.length);
        } else {
          getDetail(educatorList.data[0].cmn_teacher_id);
          window.localStorage.setItem(
            "detailInfo_id",
            educatorList.data[0].cmn_teacher_id
          );
        }
      } else {
        getDetail(window.localStorage.getItem("detailInfo_id"));
      }
    };
    fetchResults();
    // eslint-disable-next-line
  }, [])

  const getDetail = async (id) => {
    const id_gd = window.localStorage.getItem("activedEducator");
    if (id_gd) id = id_gd;

    let DetailInfo = await (
      await Axios.get(`${BASE_URL}/educator/${id}/personal-info`, Auth)
    ).data;

    const educatorList = await (
      await Axios.get(
        `${BASE_URL}/institution/${school_id}/educator/list`,
        Auth
      )
    ).data;
    const getBoard = await (
      await Axios.get(`${BASE_URL}/institution/${school_id}/board`, Auth)
    ).data;

    let educatorsId = id;
    const educatorSub = await (
      await Axios.get(`${BASE_URL}/educator/${educatorsId}/subject`, Auth)
    ).data;
    const educatorStudyCir = await (
      await Axios.get(`${BASE_URL}/educator/${educatorsId}/study-circle`, Auth)
    ).data;

    setState({
      ...state,
      cmn_teacher_alt_phone_number: DetailInfo.data[0]
        ? DetailInfo.data[0].cmn_teacher_alt_phone_number
        : "",
      cmn_teacher_city: DetailInfo.data[0]
        ? DetailInfo.data[0].cmn_teacher_city
        : "",
      cmn_teacher_dob: DetailInfo.data[0]
        ? DetailInfo.data[0].cmn_teacher_dob
        : new Date().toDateString(),
      cmn_teacher_id: DetailInfo.data[0]
        ? DetailInfo.data[0].cmn_teacher_id
        : "",
      cmn_teacher_name: DetailInfo.data[0]
        ? DetailInfo.data[0].cmn_teacher_name
        : "",
      cmn_teacher_phone_number: DetailInfo.data[0]
        ? DetailInfo.data[0].cmn_teacher_phone_number
        : "",
      cmn_teacher_pincode: DetailInfo.data[0]
        ? DetailInfo.data[0].cmn_teacher_pincode
        : "",
      cmn_teacher_isp: DetailInfo.data[0]
        ? DetailInfo.data[0].cmn_teacher_isp
        : "",
      study_circle_body: educatorStudyCir.data,
      subject_body: educatorSub.data,
      educatorList: educatorList.data,
      drop_Board: getBoard.data,
    });
  };

  const changedBoard = async (e) => {
    boardID = e.target.value;
    setState({ ...state, board: boardID });
    await Axios.get(
      `${BASE_URL}/institution/${school_id}/board/${boardID}/class`,
      Auth
    )
      .then((res) => {
        setState({
          ...state,
          drop_class: res.data.data,
        });
      })
      .catch((error) => console.log(error, "error"));
  };

  const changedClass = async (e) => {
    classID = e.target.value;
    setState({ ...state, class: classID });
    await Axios.get(
      `${BASE_URL}/institution/${school_id}/board/${boardID}/class/${classID}/subject`,
      Auth
    )
      .then((res) => {
        console.log(res.data.data, "subject .....");
        setState({
          ...state,
          drop_Subject: res.data.data,
        });
      })
      .catch((error) => console.log(error, "error"));
  };

  const changedSubject = (e) => {
    subjectID = e.target.value;
  };

  const saveEducator = async (values, submitProps) => {
    submitProps.setSubmitting(false);
    submitProps.resetForm();

    let educatorsId = window.localStorage.getItem("detailInfo_id");
    if (editVal) {
      await Axios.put(
        `${BASE_URL}/institution/${school_id}/educator/${educatorsId}`,
        {
          name: values.Name,
          dob: state.cmn_teacher_dob,
          phone_number: values.reg_mob_no,
          alt_phone_number: values.alt_mob,
          city: values.city,
          pincode: values.pincode,
          isp: values.isp,
        },
        Auth
      )
        .then((res) => {
          toast.success("Educator Info Edited successfully");
          if (res.data.status === true) {
            toast.success(res.datastatusText);
            setTimeout(() => {
              window.location.reload(false);
            }, 3000);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    } else {
      await Axios.post(
        `${BASE_URL}/institution/${school_id}/educator`,
        {
          name: values.Name,
          dob: state.cmn_teacher_dob,
          phone_number: values.reg_mob_no,
          alt_phone_number: values.alt_mob,
          city: values.city,
          pincode: values.pincode,
          isp: values.isp,
        },
        Auth
      )
        .then((res) => {
          toast.success("new Educator Added Successfully");
          if (res.data.status === true) {
            toast.success(res.datastatusText);
            setTimeout(() => {
              window.location.reload(false);
            }, 3000);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  const addSubject = async (values, submitProps) => {
    submitProps.setSubmitting(false);
    submitProps.resetForm();
    let educatorsId = window.localStorage.getItem("detailInfo_id");
    await Axios.post(
      `${BASE_URL}/educator/${educatorsId}/subject`,
      {
        cmn_subject_id: subjectID,
        // qb_class_id :classID,
        // qb_subject_id :boardID
      },
      Auth
    )
      .then((res) => {
        toast.success("Subject Added");
        if (res.data.status === true) {
          window.location.reload(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const deleteSubject = async (e) => {
    let educatorsId = window.localStorage.getItem("detailInfo_id");
    Axios.delete(`${BASE_URL}/educator/${educatorsId}/subject/${e}`, Auth)
      .then((res) => {
        if (res.data.status === true) {
          toast.error("data deleted successfully");
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        window.location.reload(false);
      });
  };

  const deleteEducator = async (e) => {
    let educatorsId = window.localStorage.getItem("detailInfo_id");
    Axios.delete(
      `${BASE_URL}/institution/${school_id}/educator/${educatorsId}`,
      Auth
    )
      .then((res) => {
        toast.error(`${res.statusText},educator deleted successfully`);
        if (res.data.status === true) {
          window.location.reload(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      });
  };

  const handleClosePopup = () => {
    setOpen(false);
    setErrorUpload(false);
  };
  const handleEducatorAdd = () => {
    setOpen(true);
    setEd(true);
    setEditVal(false);
  };

  const handleEditEducator = () => {
    setOpen(true);
    setEd(true);
    setEditVal(true);
  };

  const downloadFile = async (val) => {
    if (val === "educatorDownload") {
      await Axios.get(`${BASE_URL}/institution/${school_id}/educator/export`, {
        ...Auth,
        responseType: "arraybuffer",
      }).then((response) => {
        var blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        saveAs(blob, "EducatorList.xlsx");
        toast.success("file downloaded");
      });
    } else {
      await Axios.get(
        `${BASE_URL}/institution/${school_id}/educator-subject/export`,
        {
          ...Auth,
          responseType: "arraybuffer",
        }
      ).then((response) => {
        var blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        saveAs(blob, "educatorSubject.xlsx");
        toast.success("file downloaded");
      });
    }
  };

  const uploadFile = (e, val) => {
    let formdata = new FormData();
    let file = e.target.files[0];
    setLoading(true);
    formdata.append("filetoupload", file);

    if (val === "educatorList") {
      Axios({
        url: `${BASE_URL}/institution/${school_id}/educator/import`,
        mode: "no-cors",
        method: "POST",
        headers: {
          ...Auth.headers,
          "Content-Type": "multipart/form-data",
          Accept: "*",
          type: "formData",
          "Access-Control-Allow-Headers": "*",
        },
        data: formdata,
      })
        .then((res) => {
          setLoading(false);
          toast.success("File Uploaded Successfully");
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        })
        .catch((err) => {
          setLoading(false);
          if (err) {
            setErrorUpload(true);
            toast.error(`oops! error while upload file  check in the modal..`);
            setMsg(err.response.data.message);
            setErrMsg(err.response.data.data);
          }
        });
    } else if (val === "educatorSubject") {
      Axios({
        url: `${BASE_URL}/institution/${school_id}/educator-subject/import`,
        mode: "no-cors",
        method: "POST",
        headers: {
          ...Auth.headers,
          "Content-Type": "multipart/form-data",
          Accept: "*",
          type: "formData",
          "Access-Control-Allow-Headers": "*",
        },
        data: formdata,
      })
        .then((res) => {
          setLoading(false);
          toast.success("File Uploaded Successfully");
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        })
        .catch((err) => {
          setLoading(false);
          if (err) {
            setErrorUpload(true);
            toast.error(`oops! error while upload file  check in the modal..`);
            setMsg(err.response.data.message);
            setErrMsg(err.response.data.data);
          }
        });
    } else {
      return false;
    }
  };

  const ErrModal = () => {
    let errHead = ["Teacher Id", "Teacher Name", "Error"];
    return (
      <Dialogbox Dialogbox open={errorUpload} handleClose={handleClosePopup}>
        <div className={styles.errMod}>
          <div className={styles.Msg}>
            <div>{`${msg}`}</div>
            <div className={styles.closeBtn} onClick={handleClosePopup}>
              <img
                src={require("../../../../assets/icons/close.png")}
                alt="closeBtn"
                style={{ height: "16px", width: "16px", cursor: "pointer" }}
              />
            </div>
          </div>
          <table className={styles.content_table}>
            <thead>
              <tr>
                {errHead.map((elm, key) => (
                  <th key={key}>{elm}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {errMsg.map((elm, key) => {
                return (
                  <tr key={key}>
                    <td>{elm.stucle_teacher_id}</td>
                    <td>{elm.teacher_name}</td>
                    <td>{elm.result ? elm.result : elm.message}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dialogbox>
    );
  };

  const addEducator = () => {
    return (
      <Dialogbox open={open} handleClose={handleClosePopup}>
        <Box className={styles.mainBox} component="div">
          <Box className={styles.closeIcon} component="div">
            <Box component="div" className={styles.persionalInfo}>
              {editVal ? `Edit Personal Info` : `Add Personal Info`}
            </Box>
            <Box
              component="div"
              className={styles.closeBtn}
              onClick={() => setOpen(!open)}
            >
              <img
                src={require("../../../../assets/icons/close.png")}
                alt="closeBtn"
                style={{ height: "16px", width: "16px" }}
              />
            </Box>
          </Box>
          <Box>
            <Box component="div" className={styles.form}>
              <Formik
                initialValues={initialValues || state}
                validationSchema={validationSchema}
                onSubmit={saveEducator}
                enableReinitialize
                // className={styles.formik}
              >
                {(formik) => {
                  return (
                    <Box className={styles.form_container} component="div">
                      <Form className={styles.form_box}>
                        <Box className={styles.form_box_fields} component="div">
                          <Box
                            component="div"
                            className={styles.form_box_fields_r}
                          >
                            <label htmlFor="name" className={styles.labels}>
                              Name <span style={{ color: "red" }}>*</span>
                            </label>
                            <Box
                              component="div"
                              className={styles.institute_name}
                            >
                              <Field
                                type="text"
                                id="Name"
                                name="Name"
                                value={
                                  editVal ? state.cmn_teacher_name : undefined
                                }
                                onChange={(e) => {
                                  e.currentTarget.value = e.currentTarget.value.replace(
                                    /^\s+/,
                                    ""
                                  );
                                  setState({
                                    ...state,
                                    cmn_teacher_name: e.target.value,
                                  });
                                }}
                                required={true}
                                className={styles.institute_name_f}
                              />
                              <ErrorMessage name="Name" component={TextError}>
                                {(error) => (
                                  <div className="error">{error}</div>
                                )}
                              </ErrorMessage>
                            </Box>
                          </Box>
                          <Box
                            component="div"
                            className={styles.form_box_fields_r}
                          >
                            <label htmlFor="name" className={styles.labels}>
                              Date of birth{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Box
                              component="div"
                              className={styles.institute_name}
                            >
                              <Calander
                                id="DOB"
                                name="DOB"
                                type="date"
                                selected={new Date(state.cmn_teacher_dob)}
                                style={{ width: "50px" }}
                                onChange={(e) => {
                                  DateOfBirth =
                                    e && e.toDateString()
                                      ? e.toDateString()
                                      : new Date().toDateString();
                                  setState({
                                    ...state,
                                    cmn_teacher_dob: DateOfBirth,
                                  });
                                  // setCalander(true);
                                }}
                              />{" "}
                              <ErrorMessage name="DOB" component={TextError}>
                                {(error) => (
                                  <div className="error">{error}</div>
                                )}
                              </ErrorMessage>
                            </Box>
                          </Box>
                          <Box
                            component="div"
                            className={styles.form_box_fields_r}
                          >
                            <label htmlFor="name" className={styles.labels}>
                              Registred Mobile Number{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Box
                              component="div"
                              className={styles.institute_name}
                            >
                              <Field
                                type="Number"
                                id="reg_mob_no"
                                name="reg_mob_no"
                                required={true}
                                value={
                                  editVal
                                    ? state.cmn_teacher_phone_number
                                    : undefined
                                }
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    cmn_teacher_phone_number: e.target.value,
                                  });
                                }}
                                className={styles.institute_name_f}
                              />
                              <ErrorMessage
                                name="reg_mob_no"
                                component={TextError}
                              >
                                {(error) => (
                                  <div className="error">{error}</div>
                                )}
                              </ErrorMessage>
                            </Box>
                          </Box>
                          <Box
                            component="div"
                            className={styles.form_box_fields_r}
                          >
                            <label htmlFor="name" className={styles.labels}>
                              Alternate Mobile Number
                            </label>
                            <Box
                              component="div"
                              className={styles.institute_name}
                            >
                              <Field
                                type="Number"
                                id="alt_mob"
                                name="alt_mob"
                                value={
                                  editVal
                                    ? state.cmn_teacher_alt_phone_number
                                    : undefined
                                }
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    cmn_teacher_alt_phone_number:
                                      e.target.value,
                                  });
                                }}
                                className={styles.institute_name_f}
                              />
                              <ErrorMessage
                                name="alt_mob"
                                component={TextError}
                              >
                                {(error) => (
                                  <div className="error">{error}</div>
                                )}
                              </ErrorMessage>
                            </Box>
                          </Box>
                          <Box
                            component="div"
                            className={styles.form_box_fields_r}
                          >
                            <label htmlFor="name" className={styles.labels}>
                              City
                            </label>
                            <Box
                              component="div"
                              className={styles.institute_name}
                            >
                              <Field
                                type="text"
                                id="city"
                                name="city"
                                value={
                                  editVal ? state.cmn_teacher_city : undefined
                                }
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    cmn_teacher_city: e.target.value,
                                  });
                                }}
                                className={styles.institute_name_f}
                              />
                              <ErrorMessage name="city" component={TextError}>
                                {(error) => (
                                  <div className="error">{error}</div>
                                )}
                              </ErrorMessage>
                            </Box>
                          </Box>
                          <Box
                            component="div"
                            className={styles.form_box_fields_r}
                          >
                            <label htmlFor="name" className={styles.labels}>
                              Pincode
                            </label>
                            <Box
                              component="div"
                              className={styles.institute_name}
                            >
                              <Field
                                type="number"
                                id="pincode"
                                name="pincode"
                                value={
                                  editVal
                                    ? state.cmn_teacher_pincode
                                    : undefined
                                }
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    cmn_teacher_pincode: e.target.value,
                                  });
                                }}
                                className={styles.institute_name_f}
                              />
                              <ErrorMessage
                                name="pincode"
                                component={TextError}
                              >
                                {(error) => (
                                  <div className="error">{error}</div>
                                )}
                              </ErrorMessage>
                            </Box>
                          </Box>
                          <Box
                            component="div"
                            className={styles.form_box_fields_r}
                          >
                            <label htmlFor="name" className={styles.labels}>
                              Internet Service Provider
                            </label>
                            <Box
                              component="div"
                              className={styles.institute_name}
                            >
                              <Field
                                type="text"
                                id="isp"
                                name="isp"
                                className={styles.institute_name_f}
                                value={
                                  editVal ? state.cmn_teacher_isp : undefined
                                }
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    int_service_provider: e.target.value,
                                    cmn_teacher_isp: e.target.value,
                                  });
                                }}
                              />
                              <ErrorMessage
                                name="int_service_provider"
                                component={TextError}
                              >
                                {(error) => (
                                  <div className="error">{error}</div>
                                )}
                              </ErrorMessage>
                            </Box>
                          </Box>
                          <Box className={styles.buttonsss}>
                            <div>
                              {editVal ? (
                                <div
                                  className={styles.dltBtn}
                                  onClick={deleteEducator}
                                >
                                  <img
                                    src={require("../../../../assets/icons/DeleteBox.png")}
                                    alt="deletebox"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              ) : null}
                            </div>
                            <div className={styles.scBtn}>
                              <button
                                className={styles.cancelBtn}
                                onClick={handleClose}
                                type="reset"
                              >
                                Cancel
                              </button>
                              <button
                                className={styles.saveBtn}
                                type="submit"
                                onClick={(e) => {
                                  let ispVal = document.getElementById("isp")
                                    .value;
                                  let NameVal = document.getElementById("Name")
                                    .value;
                                  // let DOBVal = document.getElementById("DOB").value;
                                  let reg_mob_noVal = document.getElementById(
                                    "reg_mob_no"
                                  ).value;
                                  let alt_mobVal = document.getElementById(
                                    "alt_mob"
                                  ).value;
                                  let cityVal = document.getElementById("city")
                                    .value;
                                  let pincodeVal = document.getElementById(
                                    "pincode"
                                  ).value;
                                  formik.setFieldValue("isp", ispVal);
                                  formik.setFieldValue("Name", NameVal);
                                  formik.setFieldValue(
                                    "reg_mob_no",
                                    reg_mob_noVal
                                  );
                                  formik.setFieldValue("alt_mob", alt_mobVal);
                                  formik.setFieldValue("city", cityVal);
                                  formik.setFieldValue("pincode", pincodeVal);
                                }}
                                disabled={
                                  !formik.isValid || formik.isSubmitting
                                }
                              >
                                Save
                              </button>
                            </div>
                          </Box>
                        </Box>
                      </Form>
                    </Box>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Dialogbox>
    );
  };

  return (
    <Box component="div" className={styles.main_Container}>
      <Box component="div" className={styles.top}>
        <Box className={styles.buttons} component="div">
          <Box
            component="div"
            className={styles.buttons_add}
            onClick={handleEducatorAdd}
          >
            Add Educator
          </Box>
          <Box
            component="div"
            className={styles.buttons_format}
            onClick={() => downloadFile("educatorDownload")}
          >
            <img
              src={require("../../../../assets/icons/down.png")}
              alt="down"
              style={{ paddingRight: "10px" }}
            />
            Download Educator's List
          </Box>
          <Box component="div" className={styles.buttons_data}>
            <div className={styles.upload} onClick={handleClick}>
              <InputBase
                type="file"
                name="filetouploadEducatorList"
                id="filetouploadEducatorList"
                onChange={(e) => {
                  uploadFile(e, "educatorList");
                  e.target.value = null;
                }}
                multiple
                accept="*"
                style={{ display: "none" }}
                ref={uploadEducatorList}
              />
              <label
                htmlFor="filetouploadEducatorList"
                style={{
                  borderRadius: "10px",
                  width: "100px",
                  height: "32px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={require("../../../../assets/icons/up.png")}
                  alt="up"
                  style={{ marginRight: "10px" }}
                />
                Upload Educator's List
              </label>
            </div>
          </Box>
        </Box>
      </Box>
      {loading ? (
        <div className={styles.loader}>
          <ReactBootStrap.Spinner animation="border" variant="success" /> &nbsp;
          <span className={styles.loaderTxt}>Loading...</span>
        </div>
      ) : null}
      <Box component="div" className={styles.body}>
        <Box component="div" className={styles.educatorList}>
          <Box component="div" className={styles.educator}>
            <Box component="div" className={styles.educator_txt}>
              Educator List
            </Box>
            <Box component="div" className={styles.educators}>
              {state.educatorList.length === 0 ? (
                <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                No Data Found<span role='img' aria-label='jsx-a11y/accessible-emoji'>😔</span>
              </div>
              ) : (
                state.educatorList
                  .sort((a, b) =>
                    a.cmn_teacher_name.localeCompare(b.cmn_teacher_name)
                  )
                  .map((elm) => (
                    <div
                      key={elm.cmn_teacher_id}
                      className={styles.lists}
                      onClick={() => {
                        window.localStorage.setItem(
                          "activedEducator",
                          elm.cmn_teacher_id
                        );
                        window.localStorage.setItem(
                          "detailInfo_id",
                          elm.cmn_teacher_id
                        );
                        getDetail(elm.cmn_teacher_id);
                      }}
                    >
                      <div className={styles.image}>
                        <Avatar
                          src={dum}
                          alt="img"
                          style={{ height: "16px", width: "16px" }}
                        />
                      </div>
                      <div
                        className={
                          elm.cmn_teacher_id ===
                          Number(window.localStorage.getItem("detailInfo_id"))
                            ? styles.activeEdu
                            : styles.name
                        }
                      >
                        {elm.cmn_teacher_name}
                      </div>
                    </div>
                  ))
              )}
            </Box>
          </Box>
        </Box>
        <Box component="div" className={styles.Form_fields}>
          <Box component="div" className={styles.form}>
            <Box className={styles.edit} component="div">
              <Box component="div" className={styles.persional_info}>
                Personal Info
              </Box>
              <Box onClick={handleEditEducator} component="div">
                {state.educatorList.length === 0 ? (
                  <div></div>
                ) : (
                  <img
                    src={require("../../../../assets/icons/Edit1.png")}
                    alt="edit educator"
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Box>
            </Box>
            <Box className={styles.form_containerr} component="div">
              <Box className={styles.form_box} component="div">
                <Box className={styles.form_box_fields} component="div">
                  <Box component="div" className={styles.form_box_fields_r}>
                    <label htmlFor="name" className={styles.labels}>
                      Name
                    </label>
                    <Box component="div" className={styles.institute_name}>
                      {state.cmn_teacher_name && state.cmn_teacher_name}
                    </Box>
                  </Box>
                  <Box component="div" className={styles.form_box_fields_r}>
                    <label htmlFor="name" className={styles.labels}>
                      Date of birth
                    </label>
                    <Box component="div" className={styles.institute_name}>
                      {state.educatorList.length === 0 ? (
                        <div></div>
                      ) : (
                        new Date(state.cmn_teacher_dob).toDateString()
                      )}
                    </Box>
                  </Box>
                  <Box component="div" className={styles.form_box_fields_r}>
                    <label htmlFor="name" className={styles.labels}>
                      Registred Mobile Number
                    </label>
                    <Box component="div" className={styles.institute_name}>
                      {state.cmn_teacher_phone_number}
                    </Box>
                  </Box>
                  <Box component="div" className={styles.form_box_fields_r}>
                    <label htmlFor="name" className={styles.labels}>
                      Alternate Mobile Number
                    </label>
                    <Box component="div" className={styles.institute_name}>
                      {state.cmn_teacher_alt_phone_number}
                    </Box>
                  </Box>
                  <Box component="div" className={styles.form_box_fields_r}>
                    <label htmlFor="name" className={styles.labels}>
                      City
                    </label>
                    <Box component="div" className={styles.institute_name}>
                      {state.cmn_teacher_city}
                    </Box>
                  </Box>
                  <Box component="div" className={styles.form_box_fields_r}>
                    <label htmlFor="name" className={styles.labels}>
                      Pincode
                    </label>
                    <Box component="div" className={styles.institute_name}>
                      {state.cmn_teacher_pincode}
                    </Box>
                  </Box>
                  <Box component="div" className={styles.form_box_fields_r}>
                    <label htmlFor="name" className={styles.labels}>
                      Internet Service Provider
                    </label>
                    <Box component="div" className={styles.institute_name}>
                      {state.cmn_teacher_isp}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box component="div" className={styles.subject_table}>
            <div className={styles.subText}>
              <div className={styles.subTxt}>Subject</div>
              <div
                style={{
                  display: "flex",
                  flexDireaction: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className={styles.addBtn} onClick={handleClickOpen}>
                  Add
                </div>
                <Box
                  component="div"
                  className={styles.buttons_format}
                  onClick={downloadFile}
                >
                  <img
                    src={require("../../../../assets/icons/down.png")}
                    alt="down"
                    style={{ paddingRight: "10px" }}
                  />
                  Download Subject
                </Box>
                <Box component="div" className={styles.buttons_data}>
                  <div className={styles.upload}>
                    <InputBase
                      type="file"
                      name="filetouploadEducatorSubject"
                      id="filetouploadEducatorSubject"
                      onChange={(e) => {
                        uploadFile(e, "educatorSubject");
                        e.target.value = null;
                      }}
                      multiple
                      accept="*"
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="filetouploadEducatorSubject"
                      style={{
                        borderRadius: "10px",
                        width: "98px",
                        height: "32px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={require("../../../../assets/icons/up.png")}
                        alt="up"
                        style={{ marginRight: "10px" }}
                      />
                      Upload Subject
                    </label>
                  </div>
                </Box>
              </div>
            </div>
            <div className={styles.table_box}>
              <div className={styles.schoolList_Table}>
                <div className={styles.schoolList_Table_head}>
                  {state.subject_head &&
                    state.subject_head.map((elm, key) => (
                      <div
                        key={key}
                        className={styles.schoolList_Table_head_data}
                      >
                        {elm}
                      </div>
                    ))}
                </div>
                <div className={styles.schoolList_Table_body}>
                  {state.subject_body.map((elm, key) => (
                    <div key={key} className={styles.schoolList_Table_body_row}>
                      <div className={styles.schoolList_Table_body_row_td2}>
                        {elm.cmn_syllabus_name}
                      </div>
                      <div className={styles.schoolList_Table_body_row_td3}>
                        {elm.qb_class_number}
                      </div>
                      <div className={styles.schoolList_Table_body_row_td3}>
                        {elm.cmn_subject_name}
                      </div>
                      <div
                        className={styles.schoolList_Table_body_row_td3}
                        onClick={() =>
                          deleteSubject(elm.cmn_teacher_subject_id)
                        }
                      >
                        <Icon
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <img
                            src={require("../../../../assets/icons/DeleteBox.png")}
                            alt="deletebox"
                            style={{ cursor: "pointer" }}
                          />
                        </Icon>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Box>
          <Box component="div" className={styles.study_circle}>
            <div className={styles.subTxt}>
              Study Circle ( Go to Study Circle tab to modify data )
            </div>
            <div className={styles.table_box}>
            <div className={styles.table_box}>
              <div className={styles.schoolList_Table}>
                <div className={styles.schoolList_Table_head}>
                  {state.study_circle_head &&
                      state.study_circle_head.map((elm, key) => (
                      <div
                        key={key}
                        className={styles.schoolList_Table_head_data}
                      >
                        {elm}
                      </div>
                    ))}
                </div>
                <div className={styles.schoolList_Table_body}>
                  {state.study_circle_body &&
                    state.study_circle_body.map((elm, key) => (
                    <div key={key} className={styles.schoolList_Table_body_row}>
                      <div className={styles.schoolList_Table_body_row_td2}>
                      {elm.cmn_study_circle_name}
                      </div>
                      <div className={styles.schoolList_Table_body_row_td3}>
                      {elm.cmn_subject_name}
                      </div>
                      <div className={styles.schoolList_Table_body_row_td3}>
                      {elm.cmn_teacher_role}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
             
            </div>
          </Box>
        </Box>
      </Box>
      <ModalComponent show={state.showHide} onClick={handleClose}>
        <Box className={styles.main_box} component="div">
          <Formik
            initialValues={initialAddVal || state}
            validationSchema={validationAdd}
            onSubmit={addSubject}
            enableReinitialize
          >
            {(formik) => {
              // console.log(formik, "formik get values");
              return (
                <Box component="div" style={{ width: "100%", height: "auto" }}>
                  <Form className={styles.modal_form}>
                    <label htmlFor="board">Board</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        as="select"
                        name="board"
                        id="board"
                        value={undefined}
                        className={styles.d_options}
                        onChange={(e) => changedBoard(e)}
                      >
                        <option>{"Board"}</option>
                        {(state.drop_Board, "board")}
                        {state.drop_Board.map((el, key) => (
                          <option value={el.cmn_school_syllabus_id} key={key}>
                            {el.cmn_syllabus_name
                              ? el.cmn_syllabus_name
                              : el.qb_syllabus_name}
                          </option>
                        ))}
                      </Field>
                    </Box>
                    <label htmlFor="class">Grade</label>
                    <Box className={styles.main_box_2} component="div">
                      <Field
                        as="select"
                        name="class"
                        id="class"
                        value={undefined}
                        className={styles.d_options}
                        onChange={(e) => changedClass(e)}
                      >
                        <option>{"Grade"}</option>
                        {state.drop_class.map((el, key) => (
                          <option value={el.qb_class_id} key={key}>
                            {el.qb_class_number}
                          </option>
                        ))}
                      </Field>
                    </Box>
                    <label htmlFor="board">Subject</label>

                    <Box className={styles.main_box_3} component="div">
                      <Field
                        as="select"
                        name="subject"
                        id="subject"
                        value={undefined}
                        // value={editVal ? selectedBoard() : undefined}
                        // onChange={(e)=>setState({...state,subject:e.terget.value})}
                        onChange={(e) => changedSubject(e)}
                        className={styles.d_options}
                      >
                        <option>{"Subject"}</option>
                        {console.log(state.drop_Subject, "cmn sub")}
                        {state.drop_Subject.map((el, key) => (
                          <option value={el.cmn_subject_id} key={key}>
                            {el.cmn_subject_name}
                          </option>
                        ))}
                      </Field>
                    </Box>
                    <Box className={styles.buttonss}>
                      <button
                        className={styles.cancelBtn}
                        onClick={handleClose}
                        type="reset"
                      >
                        Cancel
                      </button>
                      <button className={styles.saveBtn} type="submit">
                        Save
                      </button>
                    </Box>
                  </Form>
                </Box>
              );
            }}
          </Formik>
        </Box>
      </ModalComponent>
      {addEd ? addEducator() : null}
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
      {errorUpload ? <ErrModal /> : null}
    </Box>
  );
};

export default Educator;
