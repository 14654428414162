import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Divider, Box } from "@material-ui/core";
import Axios from "axios";
import * as Yup from "yup";
import styles from "./NewPasswordStyle.module.css";
import TextError from "../../variables/errors/Text_error";
import { BASE_URL } from "../../config/Api";

// debugger;
const initialValues = {
  NewPassword: "",
  passwordConfirm: "",
};

const validationSchema = Yup.object({
  NewPassword: Yup.string()
    .required("New password is Required")
    .min(5, "password too short"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('NewPassword'), null], 'Password must match')
    .required("Password confirm is required"),
});

const NewPassword = (props) => {

  let user_id = props.match.params.user_id
  let reset = true

  useEffect(() => {
    const fetchRes = async () => {
      try {
        const results = await Axios.post(`${BASE_URL}/user/check-renew`, {
          user_id: user_id,
        }).catch((err) => {
          toast.error(err.response.data.message)
          setTimeout(() => {
            return props.history.push("/");
          }, 3000);
        });
        if (results.data.status === false) {
          setTimeout(() => {
            return props.history.push("/");
          }, 3000);
        } else {
          console.log(results);
        }
      } catch (err) {
        console.log(err.response, "error");
      }
    };
    fetchRes();
  }, [props.history, user_id])// eslint-disable-next-line react-hooks/exhaustive-deps

  const onSubmit = async (values) => {
    await Axios.post(`${BASE_URL}/user/save`, {
      user_id: user_id,
      password: values.NewPassword,
    })
      .then((response) => {
        toast.success('New Password Created Successfully')
        setTimeout(() => {
          return (window.location = "/");
        }, 3000);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setTimeout(() => {
          return (window.location = "/");
        }, 3000);
      });
  };

  function showPassword() {
    var temp = document.getElementById("passwordConfirm");
    if (temp.type === "password") {
      temp.type = "text";
    } else {
      temp.type = "password";
    }
  }
  return (
    <div className={styles.container}>
      <Box className={styles.header} p={1}>
        <div className={styles.header_body}>
          <img
            src={require("../../assets/images/stucle.png")}
            alt="header-logo"
            className={styles.image}
          />
        </div>
      </Box>
      <Divider
        style={{ background: "#A6C8A6", height: "1px", width: "100%" }}
      />
      <main className={styles.body}>
        <div className={styles.left}>
          <Box component="div" className={styles.reset_box} mb={4}>
            <Box component="p" className={styles.reset} mb={0}>
              New Password
            </Box>
            <p className={styles.txt}>
              To change your current Password, type the new password, and then
              retype it. Click Save. We recommend you to have long password with
              a mix of capital and lowercase letters, numbers, and symbols.
            </p>
            {reset ? (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
                className={styles.formik}
              >
                {(formik) => {
                  return (
                    <div className={styles.form_div}>
                      <Form className={styles.form}>
                        <Box className={styles.password} component="div">
                          <Field
                            type="password"
                            id="NewPassword"
                            name="NewPassword"
                            placeholder="Password"
                            className={styles.password_field}
                          />
                        </Box>
                        <ErrorMessage name="NewPassword" component={TextError}>
                          {(error) => <div className="error">{error}</div>}
                        </ErrorMessage>
                        <Box className={styles.password} component="div">
                          <Field
                            type="password"
                            id="passwordConfirm"
                            name="passwordConfirm"
                            placeholder="Re-enter Password"
                            className={styles.password_field}
                          />
                          <Box
                            component="span"
                            className={styles.show_password}
                            onClick={() => showPassword()}
                          >
                            show password
                          </Box>
                        </Box>
                        <ErrorMessage
                          name="passwordConfirm"
                          component={TextError}
                        >
                          {(error) => <div className="error">{error}</div>}
                        </ErrorMessage>
                        <button
                          type="submit"
                          className={styles.Button}
                          disabled={!formik.isValid || formik.isSubmitting}
                        >
                          <div>Save</div>
                        </button>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            ) : (
              <div className={styles.reset_msg}>
                Your new password has been saved.
              </div>
            )}
          </Box>
        </div>
        <div className={styles.right}></div>
      </main>
      <footer className={styles.footer}>
        &copy; {new Date().getFullYear()} Apps'n'Devices Technologies Pvt Ltd.
        All rights reserved.
      </footer>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
    </div>
  );
};

export default NewPassword;
