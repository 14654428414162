import dashboardIcon from "./assets/sidebar_icons/Dashboard.png";
import ManageIcon from "./assets/sidebar_icons/Action.png";
import MasterDataIcon from "./assets/sidebar_icons/MasterData.png";
// import ReportIcon from "./assets/sidebar_icons/Report.png";
import StatusIcon from "./assets/sidebar_icons/Status.png";

export default {
  data: [
    {
      name: "Dashboard",
      path: "/admin/dashboard",
      icon: dashboardIcon,
      layout: "/admin",
    },
    {
      name: "Status",
      icon: StatusIcon,
      layout: "/admin",
      children: [
        {
          name: "Live Class",
          path: "/admin/status/live-class",
          icon: null,
        },
        {
          name: "Educator",
          path: "/admin/status/educator",
          icon: null,
        },
        {
          name: "Student",
          path: "/admin/status/student",
          icon: null,
        },
      ],
    },
    // {
    //   name: "Reports",
    //   path: "/admin/reports",
    //   icon: ReportIcon,
    //   layout: "/admin",

    // },
    {
      name: "Master Data",
      path: "/admin/master-data",
      icon: MasterDataIcon,
      layout: "/admin",
    },
    {
      name: "Manage",
      path: "/admin/manage",
      icon: ManageIcon,
      layout: "/admin",

    },
    {
      name: "Settings",
      path: "/admin/settings",
      icon: ManageIcon,
      layout: "/admin",
    },
  ],
};
