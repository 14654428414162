// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@600&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap);"]);
// Module
exports.push([module.id, ":root {\n  --main-green: #4fb64b;\n  --main-white: #fff;\n  --main-grey: #919191;\n  --main-black: #000;\n  --main-red: rgb(248, 7, 7);\n  --main-greyy: #f6f8f7;\n  --footer: #2e2b2b;\n  --boxes: #f5f7f6;\n  --values: #2e2b2b;\n  --icons-color: #5d9c5a;\n  --titles: #7b7b7b;\n  --list-names: #353131;\n  --list-time: #797373;\n}\nbody {\n  margin: 0;\n  padding: 0;\n  background: #fff;\n  overflow-x: hidden;\n}\n::-webkit-scrollbar {\n  width: 10px;\n  height: 70px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  background: #f7efdb;\n  border-radius: 10px;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: rgb(219, 208, 219);\n  border-radius: 10px;\n}\n\n.MuiOutlinedInput-root{\n  border-radius: 10px !important;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n  background: var(--main-green);\n  border-radius: 10px;\n}\n\ntable {\n  overflow: scroll;\n}\n\n* {\n  font-family: Poppins;\n  box-sizing: border-box;\n  outline: none;\n  border: none;\n}\n.react-datepicker__input-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n}\n.loading_animation{\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  align-content: center;\n  width: 100%;\n  height: 100%;\n  flex-direction: column;\n}\n.active {\n  background: var(--main-white);\n  border-radius: 14px;\n  color: var(--main-green);\n  font-size: 16px;\n  font-weight: 600;\n  width: 100%;\n  padding-top: 4px;\n  padding-bottom: 4px;\n}\na:hover {\n  text-decoration: none;\n}\n.error {\n  color: red;\n  font-weight: bold;\n  font-size: 10px;\n  /* margin-top: 10px; */\n}\nlabel {\n  margin-bottom: 0px;\n  color: #868686;\n  color: #868686;\n  font-size: 10px;\n  line-height: 12px;\n  text-align: left;\n  width: 250px;\n  margin-bottom: 2px;\n}\ninput[type=number]::-webkit-inner-spin-button,\ninput[type=number]::-webkit-outer-spin-button { \n\t-webkit-appearance: none;\n}", ""]);
// Exports
module.exports = exports;
