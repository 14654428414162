export default {
  reportList: [
    {
      reportlists: [
        {
          id: 548745,
          report: "class 9th english student list 4+ velocity",
        },
        {
          id: 548799,
          report: "disovery could help scientists class 9 consciousness ",
        },
        {
          id: 548748,
          report: "class 9 understand consciousness, writing skills",
        },
        {
          id: 5421478,
          report: "this instagram account documents lstening skills",
        },
        {
          id: 548799,
          report:
            "brain cell discovery could help scientists class 9 consciousness",
        },
        {
          id: 548748,
          report: "class 9 understand consciousness writing skills",
        },
        {
          id: 5421478,
          report: "this instagram account documents listening skills ",
        },
        {
          id: 548799,
          report:
            "brain cell discovery could help scientists class 9 consciousness",
        },
        {
          id: 5421478,
          report: "this instagram account documents listening skills",
        },
        {
          id: 548799,
          report:
            "brain cell discovery could help scientists class 9 consciousness",
        },
        {
          id: 548745,
          report: "class 9th english student list 4+ velocity",
        },
        {
          id: 548799,
          report: "disovery could help scientists class 9 consciousness ",
        },
        {
          id: 548748,
          report: "class 9 understand consciousness, writing skills",
        },
        {
          id: 5421478,
          report: "this instagram account documents lstening skills",
        },
        {
          id: 548799,
          report:
            "brain cell discovery could help scientists class 9 consciousness",
        },
        {
          id: 548748,
          report: "class 9 understand consciousness writing skills",
        },
        {
          id: 5421478,
          report: "this instagram account documents listening skills ",
        },
        {
          id: 548799,
          report:
            "brain cell discovery could help scientists class 9 consciousness",
        },
        {
          id: 5421478,
          report: "this instagram account documents listening skills",
        },
        {
          id: 548799,
          report:
            "brain cell discovery could help scientists class 9 consciousness",
        },
      ],
      reportThead: [
        "Study Circle",
        "Subject",
        "Topic",
        "Day",
        "Student",
        "Score",
        "Attendance",
        "Activity",
      ],
    },
  ],
};
