import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Avatar, Box } from "@material-ui/core";
import styles from "./EducatorStyle.module.css";
import DialogBox from "../../../../components/dialog/Modal";
import Axios from "axios";
import { BASE_URL } from "../../../../config/Api";
import { toast, ToastContainer } from "react-toastify";

var enabledarr = [];
var csv;
var cmn_teacherID = false;

const Educator = forwardRef((props, ref) => {
  // const EducatorRef = useRef();
  const [open, setOpen] = useState(false);
  // const classes = useStyles();
  const [state, setState] = useState({
    educatorList: [],
    subject_head: ["Board", "Grade", "Subject"],
    subject_body: [],
    study_circle_head: ["Study Circle", "Subject", "Role"],
    study_circle_body: [],
    cmn_teacher_name: "",
    login_enabled: "",
    checkedID: null,
  });

  window.localStorage.setItem("tabIndexManage", 0);
  let schoolId = window.localStorage.getItem("school_id");
  const token = window.localStorage.getItem("token");
  const Auth = {
    headers: {
      Authorization: "Bearer "+ token,
    },
  };
  let enabled_load;
  let enabled;
  // var searching = props.searchVal
  if (window.localStorage.getItem("loginEnabled") == null) {
    enabled_load = true;
    enabled = true;
  } else {
    enabled_load = window.localStorage.getItem("loginEnabled");
    enabled = window.localStorage.getItem("loginEnabled");
  }

  const handleClose = (value) => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchResult = async () => {
      try {
        const getEducatorData = await (
          await Axios.get(
            `${BASE_URL}/institution/${schoolId}/educator/manage/search?&search_string=&study_circle_id=&login_enabled=${enabled_load}`,
            Auth
          )
        ).data;

        setState({
          ...state,
          educatorList: getEducatorData.data,
          subject_body:
            getEducatorData.data.length === 0
              ? null
              : getEducatorData.data[0].teacher_subject_json,
          study_circle_body:
            getEducatorData.data.length === 0
              ? null
              : getEducatorData.data[0].teacher_study_circle_json,
          cmn_teacher_name:
            getEducatorData.data.length === 0
              ? null
              : getEducatorData.data[0].cmn_teacher_name,
          login_enabled: enabled_load,
        });
      } catch (err) {
        console.log(err, "error");
      }
    };

    fetchResult();
    // eslint-disable-next-line
  }, [])

  const selectEducator = (index) => {
    setState({
      ...state,
      subject_body: state.educatorList[index].teacher_subject_json,
      study_circle_body: state.educatorList[index].teacher_study_circle_json,
      cmn_teacher_name: state.educatorList[index].cmn_teacher_name,
    });
  };

  useImperativeHandle(ref, () => ({
    getEducatorFilter(e, selectedTxt) {
      getFilter(e, "searchTxt");
    },
  }));

  const getFilter = async (e, selectedTxt) => {
    let searchString = "search_string=";
    if (selectedTxt === "dropDownTxt") {
      enabled = e.target.value;
      enabled === "true"
        ? window.localStorage.setItem("loginEnabled", true)
        : window.localStorage.setItem("loginEnabled", false);
    } else if (selectedTxt === "searchTxt") {
      searchString = "search_string=" + e.target.value;
    }

    try {
      const getEducatorData = await (
        await Axios.get(
          `${BASE_URL}/institution/${schoolId}/educator/manage/search?&${searchString}&study_circle_id=&login_enabled=${enabled}`,
          Auth
        )
      ).data;

      setState({
        ...state,
        educatorList: getEducatorData.data,
        subject_body:
          getEducatorData.data.length === 0
            ? null
            : getEducatorData.data[0].teacher_subject_json,
        study_circle_body:
          getEducatorData.data.length === 0
            ? null
            : getEducatorData.data[0].teacher_study_circle_json,
        cmn_teacher_name:
          getEducatorData.data.length === 0
            ? null
            : getEducatorData.data[0].cmn_teacher_name,
        login_enabled: enabled,
      });
    } catch (err) {
      console.log(err, "error");
    }
  };

  const checkboxSelect = (e, selectedopt) => {
    window.localStorage.setItem("seleducatorID", e.target.value);

    var enabled_value;
    enabled_value = e.target.checked;
    let val = e.target.value;

    if (enabled_value === true) {
      enabledarr.push(val);

      csv = enabledarr
        .map((elm) => {
          return elm;
        })
        .join(",")
        .replace(/(^\[)|(\]$)/gm, "");
      setState({ ...state, checkedID: enabledarr });
      document.getElementById("checkedcount").innerHTML = enabledarr.length;
    } else if (enabled_value === false) {
      let arrindex = enabledarr.indexOf(val);
      enabledarr.splice(arrindex, 1);
      csv = enabledarr
        .map((elm) => {
          return elm;
        })
        .join(",")
        .replace(/(^\[)|(\]$)/gm, "");
      setState({ ...state, checkedID: enabledarr });
      document.getElementById("checkedcount").innerHTML = enabledarr.length;
    }

    if (selectedopt === "selectbtn") {
      if (state.login_enabled === "true") {
        cmn_teacherID = false;
      } else if (state.login_enabled === "false") {
        cmn_teacherID = true;
      }
      Axios.put(
        `${BASE_URL}/institution/${schoolId}/educator/manage/login`,
        {
          enable_login: cmn_teacherID,
          cmn_teacher_ids: csv,
        },
        Auth
      )
        .then((res) => {
          if (state.login_enabled === "false") {
            toast.success("Educator is Enabled");
          } else if (state.login_enabled === "true") {
            toast.success("Educator is Disabled");
          }
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  return (
    <Box component="div" className={styles.main_Container}>
      <Box component="div" className={styles.top}>
        <Box className={styles.left}>
          <Box component="div">
            {
              <select
                name="login"
                id="login"
                className={styles.selects}
                onChange={(e) => getFilter(e, "dropDownTxt")}
                value={state.login_enabled}
                // ref={EducatorRef.current}
              >
                <option value={true}>Login Enabled</option>
                <option value={false}>Login Disabled</option>
              </select>
            }
          </Box>
        </Box>
        <Box className={styles.right} component="div">
          <Box component="div" className={styles.educator_sel}>
            <span id="checkedcount">0</span>
            <span> Educator Selected</span>
          </Box>
          <Box
            component="button"
            className={
              state.login_enabled === "false"
                ? styles.buttons_enable
                : styles.buttons_disable
            }
            onClick={(e) => checkboxSelect(e, "selectbtn")}
          ></Box>
        </Box>
      </Box>
      <Box component="div" className={styles.body}>
        <Box component="div" className={styles.educatorList}>
          <Box component="div" className={styles.educator}>
            <Box component="div" className={styles.educator_txt}>
              Educator List
            </Box>
            <Box component="div" className={styles.educators}>
              {state.educatorList.length === 0 ? (
                <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              >
                No Data Found<span role='img' aria-label='jsx-a11y/accessible-emoji'>😔</span>
              </div>
              ) : (
                state.educatorList.map((elm, key) => (
                  <div key={elm.cmn_teacher_id} className={styles.lists}>
                    <form action="" method="get" className={styles.form}>
                      <input
                        type="checkbox"
                        name={elm.cmn_teacher_id}
                        value={elm.cmn_teacher_id}
                        onChange={(e) => checkboxSelect(e, "checkbox")}
                      />
                    </form>
                    <div className={styles.image}>
                      <Avatar
                        src={elm.img}
                        alt="img"
                        style={{ height: "16px", width: "16px" }}
                      />
                    </div>
                    {state.checkedID === null ? (
                      <div
                        className={
                          state.cmn_teacher_name === elm.cmn_teacher_name
                            ? styles.selEdu
                            : styles.name
                        }
                        onClick={() => selectEducator(key)}
                      >
                        {/* {elm.cmn_teacher_name}  */}
                        <div className={styles.eduLst}>
                          <div>{elm.cmn_teacher_name}</div>
                          <div>
                            {" "}
                            {state.cmn_teacher_name === elm.cmn_teacher_name ? (
                              <img
                                src={require("../../../../assets/icons/ar.png")}
                                alt="arrrgt"
                                style={{
                                  height: "10px",
                                  width: "10px",
                                  display: "flex",
                                }}
                              />
                            ) : null}{" "}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={
                          state.checkedID.find((el) => elm.cmn_teacher_id === el)
                            ? styles.chkEdu
                            : styles.name
                        }
                        onClick={() => selectEducator(key)}
                      >
                        {elm.cmn_teacher_name}
                      </div>
                    )}
                  </div>
                ))
              )}
            </Box>
          </Box>
        </Box>
        <Box component="div" className={styles.Form_fields}>
          <Box className={styles.detail} component="div">
            <Box component="div" className={styles.detail_selected}>
              <Box component="div" className={styles.selected_avatar}>
                <Avatar
                  src={require("../../../../assets/images/selected.png")}
                  alt="selected_img"
                  style={{ height: "22px", width: "22px" }}
                />
              </Box>
              <Box component="div" className={styles.selected_name}>
                {state.cmn_teacher_name}
              </Box>
            </Box>
          </Box>
          <Box component="div" className={styles.subject_table}>
            <div className={styles.subTxt}>Subject</div>
            <div className={styles.table_box}>
              <div className={styles.schoolList_Table}>
                <div className={styles.schoolList_Table_head}>
                  {state.subject_head.map((elm, key) => (
                    <div
                      key={key}
                      className={styles.schoolList_Table_head_data}
                    >
                      {elm}
                    </div>
                  ))}
                </div>
                {
                  <div className={styles.schoolList_Table_body}>
                    {!state.subject_body
                      ? "no data found"
                      : state.subject_body.map((elm, key) => (
                          <div
                            key={key}
                            className={styles.schoolList_Table_body_row}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className={styles.schoolList_Table_body_row_td2}
                            >
                              {elm.cmn_syllabus_name}
                            </div>
                            <div
                              className={styles.schoolList_Table_body_row_td3}
                            >
                              {elm.qb_class_number}{" "}
                            </div>
                            <div
                              className={styles.schoolList_Table_body_row_td3}
                            >
                              {elm.cmn_subject_name}
                            </div>
                          </div>
                        ))}
                  </div>
                }
              </div>
            </div>
          </Box>
          <Box component="div" className={styles.study_circle}>
            <div className={styles.subTxt}>
              Study Circle ( Go to Study Circle tab to modify data )
            </div>
            <div className={styles.table_box}>
              <div className={styles.schoolList_Table}>
                <div className={styles.schoolList_Table_head}>
                  {state.study_circle_head.map((elm, key) => (
                    <div
                      key={key}
                      className={styles.schoolList_Table_head_data}
                    >
                      {elm}
                    </div>
                  ))}
                </div>
                {
                  <div className={styles.schoolList_Table_body}>
                    {!state.study_circle_body
                      ? "no data found"
                      : state.study_circle_body.map((elm, key) => (
                          <div
                            key={key}
                            className={styles.schoolList_Table_body_row}
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className={styles.schoolList_Table_body_row_td2}
                            >
                              {elm.cmn_study_circle_name}
                            </div>
                            <div
                              className={styles.schoolList_Table_body_row_td3}
                            >
                              {elm.cmn_subject_name}
                            </div>
                            <div
                              className={styles.schoolList_Table_body_row_td3}
                            >
                              {elm.role}
                            </div>
                          </div>
                        ))}
                  </div>
                }
              </div>
            </div>
          </Box>
        </Box>
      </Box>
      {open ? (
        <DialogBox open={open} close={handleClose}>
          helllo world from educator
        </DialogBox>
      ) : null}
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
    </Box>
  );
});

export default Educator;
