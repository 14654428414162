import React from 'react';
import { Modal } from 'react-bootstrap';
import './Modalcustomstyles.css';

const ModalComponent = (props) => {
  const { children, show, onClick,  } = props;
  return (
    <Modal show={show} onHide={onClick}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default ModalComponent
