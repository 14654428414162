import React, { useState, useEffect} from "react";
import { Formik, Form, Field } from "formik";
import styles from "./StudyCircleStyle.module.css";
import Axios from "axios";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import { Box, Avatar, Icon } from "@material-ui/core";
import { ModalComponent, Dialogbox } from "../../../../components/index";
import { BASE_URL } from "../../../../config/Api";
import { saveAs } from "file-saver";
import * as ReactBootStrap from "react-bootstrap";
import ReactLoading from "react-loading";

const initialBox = {
  study_circleName: "",
  incharge_studyCircle: "",
  primary_qb_class_id:''
};

const validationBox = Yup.object({
  study_circleName: Yup.string(),
  incharge_studyCircle: Yup.string(),
  primary_qb_class_id:Yup.string()
});

const initialFormBox = {
  board_value: "",
  class_value: "",
  subject_value: "",
  educator_value: "",
};

const validationFormBox = Yup.object({
  board_value: Yup.string(),
  class_value: Yup.string(),
  subject_value: Yup.string(),
  educator_value: Yup.string(),
});

var study_circle_ID;
var cmn_study_circle_teacher_subject_ID;
var callback_value;

const StudyCircle = () => {
  const [errorUpload, setErrorUpload] = useState(false);
  const [msg, setMsg] = useState("");
  const [errMsg, setErrMsg] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    study_circle_t_head: [
      "Study Circle Name",
      "Grade Connected",
      "No Of Subjects",
      "No of Students",
      "Incharge",
    ],
    student_head: [
      "Roll No",
      "Student",
      "Institution Student ID",
      "Institution Defined Group",
    ],
    educator_head: ["Educator", "Board", "Grade", "Subject", ""],
    study_circle_t_body: [],
    educator_body: [],
    student_body: [],
    drop_board: [],
    drop_class: [],
    drop_sub: [],
    drop_edu: [],
    incharge_data: [],
    selected_class:[],
    board: "",
    classchange: "",
    subjectchange: "",
    study_circle_Selected_Name: "",
    study_circle_Index: "",
    incharge_teacher_Selected_ID: "",
    primary_class_Selected_ID:""
  });

  const school_id = window.localStorage.getItem("school_id");
  window.localStorage.setItem("tabIndex", 5);
  const token = window.localStorage.getItem("token");
  const Auth = {
    headers: {
      Authorization: "Bearer "+ token,
    },
  };

  const fetchResult = async() => {
    try {
      const getBoardDropdown = await (
        await Axios.get(`${BASE_URL}/institution/${school_id}/board`, Auth)
      ).data;

      const getClassDrop = await (await Axios.get(`${BASE_URL}/institution/${school_id}/subject/class`,Auth)).data
      const getTableData = await (
        await Axios.get(
          `${BASE_URL}/institution/${school_id}/study-circle`,
          Auth
        )
      ).data;

      const getInchargeData = await (
        await Axios.get(
          `${BASE_URL}/educator?cmn_school_id=${school_id}`,
          Auth
        )
      ).data;

      setIsLoading(false);
      setState({
        ...state,
        study_circle_t_body: getTableData.data,
        drop_board: getBoardDropdown.data,
        incharge_data: getInchargeData.data,
        selected_class:getClassDrop.data
      });
    } catch (err) {
      console.log(err, "error");
    }
  }

  useEffect(() => {
    setIsLoading(true);
    fetchResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onTableClick = async (
    index,
    study_circle_Selected_Name,
    incharge_teacher_Selected_id,
    primary_qb_class_id
  ) => {
    
    study_circle_ID = index;
    window.localStorage.setItem("table_index", index);
    window.localStorage.setItem(
      "table_study_circle_Selected_Name",
      study_circle_Selected_Name
    );
    window.localStorage.setItem(
      "table_incharge_teacher_Selected_id",
      incharge_teacher_Selected_id
    );
    localStorage.setItem("primary_qb_class_id",primary_qb_class_id)

    const educatorList = await (
      await Axios.get(
        `${BASE_URL}/study-circle/${study_circle_ID}/educator`,
        Auth
      )
    ).data;

    const studentList = await (
      await Axios.get(
        `${BASE_URL}/study-circle/${study_circle_ID}/student`,
        Auth
      )
    ).data;

    setState({
      ...state,
      educator_body: educatorList.data,
      student_body: studentList.data,
      study_circle_Selected_Name: study_circle_Selected_Name,
      incharge_teacher_Selected_ID: incharge_teacher_Selected_id,
      primary_class_Selected_ID:primary_qb_class_id,
      showHidePlus: false,
    });

    window.localStorage.setItem("page_Index", "sub_Index");
    setNextPage(true);
  };

  const handleDelete = async (e) => {
    cmn_study_circle_teacher_subject_ID = e.target.dataset.value;
    window.localStorage.setItem("page_Index", "sub_Index");

    await Axios.delete(
      `${BASE_URL}/study-circle/${study_circle_ID}/educator/${cmn_study_circle_teacher_subject_ID}`,
      Auth
    )
      .then((response) => {
        toast.warn("data deleted successfully");
        onTableClick(
          window.localStorage.getItem("table_index"),
          window.localStorage.getItem("table_study_circle_Selected_Name"),
          window.localStorage.getItem("table_incharge_teacher_Selected_id")
        );
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      });
  };

  const handleClosePlus = (value) => {
    setState({ ...state, showHidePlus: false });
  };

  const handleClickOpenAdd = () => {
    setState({ ...state, showHidePlus: true });
  };

  // const handleClickOpenPlus = () => {
  //   setState({ ...state, showHidePlus: true });
  // };
  const handleClose = (value) => {
    setState({ ...state, showHide: false });
  };
  const handleClickOpen = () => {
    setState({ ...state, showHide: true });
  };

  const callBack = () => {
    callback_value ? window.location.reload(false) : (callback_value = null);
    setNextPage(false);
    setState({ ...state, showHide: false });
  };

  const handleBoardChange = async (e) => {
    // board_var = e.target.options[e.target.selectedIndex].text;
    let board = e.target.value;
    const getClassDropdown = await (
      await Axios.get(
        `${BASE_URL}/institution/${school_id}/class?cmn_school_syllabus_id=${board}`,
        Auth
      )
    ).data;
    setState({ ...state, board: board, drop_class: getClassDropdown.data });
  };

  const handleClassChange = async (e) => {
    // class_var = e.target.options[e.target.selectedIndex].text;
    let classchange = e.target.value;
    const getSubjectDropdown = await (
      await Axios.get(
        `${BASE_URL}/institution/${school_id}/subject?cmn_school_syllabus_id=${state.board}&qb_class_id=${classchange}`,
        Auth
      )
    ).data;
    setState({
      ...state,
      classchange: classchange,
      drop_sub: getSubjectDropdown.data,
    });
  };

  const handleSubjectChange = async (e) => {
    // subject_var = e.target.options[e.target.selectedIndex].text;
    let subjectchange = e.target.value;
    const getEducatorDropdown = await (
      await Axios.get(
        `${BASE_URL}/institution/${school_id}/educator?cmn_subject_id=${subjectchange}`,
        Auth
      )
    ).data;
    setState({
      ...state,
      subjectchange: subjectchange,
      drop_edu: getEducatorDropdown.data,
    });
  };

  const downloadFile = async () => {
    await Axios.get(
      `${BASE_URL}/institution/${school_id}/study-circle/${study_circle_ID}/student/export`,
      {
        ...Auth,
        responseType: "arraybuffer",
      }
    ).then((response) => {
      var blob = new Blob([response.data], {
        type: "application/octet-stream",
      });
      saveAs(blob, "StudyCircleReports.xlsx");
    });
  };

  const uploadFile = async (e) => {
    setLoading(true);
    let formdata = new FormData();
    let file = e.target.files[0];

    formdata.append("filetoupload", file);
    Axios({
      url: `${BASE_URL}/study-circle/${study_circle_ID}/import`,
      mode: "no-cors",
      method: "POST",
      headers: {
        ...Auth.headers,
        "Content-Type": "multipart/form-data",
        Accept: "*",
        type: "formData",
        "Access-Control-Allow-Headers": "*",
      },
      data: formdata,
    })
      .then((res) => {
        setLoading(false);
        toast.success("File Uploaded Successfully");
        onTableClick(
          window.localStorage.getItem("table_index"),
          window.localStorage.getItem("table_study_circle_Selected_Name"),
          window.localStorage.getItem("table_incharge_teacher_Selected_id")
        );
      })
      .catch((err) => {
        if (err) {
          setErrorUpload(true);
        }
        setLoading(false);
        toast.error(
          `oops! error while upload file , ${err.response.data.message} check in the modal..`
        );
        setMsg(err.response.data.message);
        setErrMsg(err.response.data.data);
      });
  };

  const handleSave = (values, submitProps, formik) => {
    window.localStorage.setItem("page_Index", "sub_Index");
    submitProps.setSubmitting(false);
    submitProps.resetForm();
    Axios.post(
      `${BASE_URL}/study-circle/${study_circle_ID}/educator`,
      {
        cmn_teacher_subject_id: values.educator_value,
      },
      Auth
    )
      .then((res) => {
        toast.success("teacher subject added successfully");
        if (res.data.status === true) {
          // setOpen(false);
          onTableClick(
            window.localStorage.getItem("table_index"),
            window.localStorage.getItem("table_study_circle_Selected_Name"),
            window.localStorage.getItem("table_incharge_teacher_Selected_id")
          );
          setState({ ...state, showHidePlus: false });
        }
        window.localStorage.setItem("page", "nextScreen");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      });
  };

  const addStudyCircle = (values, submitProps, formik) => {
    submitProps.setSubmitting(false);
    submitProps.resetForm();
    Axios.post(
      `${BASE_URL}/study-circle`,
      {
        name: values.study_circleName,
        cmn_school_id: school_id,
        incharge_teacher_id: values.incharge_studyCircle,
        primary_qb_class_id:values.primary_qb_class_id
      },
      Auth
    )
      .then((res) => {
        toast.success("study circle added successfully");
        if (res.data.status === true) {
          // setOpen(false);
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      });
  };

  const addStudyCircleTab = () => {
    Axios.put(
      `${BASE_URL}/study-circle/${study_circle_ID}`,
      {
        name: state.study_circle_Selected_Name,
        incharge_teacher_id: state.incharge_teacher_Selected_ID,
        primary_qb_class_id:  state.primary_class_Selected_ID
      },
      Auth
    )
      .then((res) => {
        toast.success("Data Edited Successfully");
        callback_value = true;
        if (res.data.status === true) {
          // setOpen(false);
          setTimeout(() => {
            setState({
              ...state,
              study_circle_Selected_Name: state.study_circle_Selected_Name,
              incharge_teacher_Selected_name:
                state.incharge_teacher_Selected_name,
            });
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      });
  };

  const handleClosePopup = () => setErrorUpload(false);
  const Example = ({ type, color }) => (
    <div className="loading_animation">
      <ReactLoading type="bars" color="#DCEEDC" height="10%" width="10%" />
    </div>
  );
  const ErrModal = () => {
    let errHead = ["row Number", "Student Name", "Error"];
    return (
      <Dialogbox Dialogbox open={errorUpload} handleClose={handleClosePopup}>
        <div className={styles.errMod}>
          <div className={styles.Msg}>
            <div>{`${msg}`}</div>
            <div className={styles.closeBtn} onClick={handleClosePopup}>
              <img
                src={require("../../../../assets/icons/close.png")}
                alt="closeBtn"
                style={{ height: "16px", width: "16px", cursor: "pointer" }}
              />
            </div>
          </div>
          <table className={styles.content_table}>
            <thead>
              <tr>
                {errHead.map((elm, key) => (
                  <th key={key}>{elm}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {errMsg.map((elm, key) => {
                return (
                  <tr key={key}>
                    <td>{elm.row_number}</td>
                    <td>{elm.student_name}</td>
                    <td>{elm.result ? elm.result : elm.message}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dialogbox>
    );
  };

  const NewScreen = () => {
    return (
      <div>
        <div className={styles.top}>
          <div className={styles.backBtn}>
            <div className={styles.bck} onClick={callBack}>
              <span className={styles.back}>
                <img
                  src={require("../../../../assets/icons/back.png")}
                  alt="back"
                />
              </span>
              <span className={styles.study}>Study Circle List</span>
            </div>
          </div>
        </div>
        {loading ? (
          <div className={styles.loader}>
            <ReactBootStrap.Spinner animation="border" variant="success" />{" "}
            &nbsp;<span className={styles.loaderTxt}>Loading...</span>
          </div>
        ) : null}
        <div className={styles.study_dtl}>
          <div className={styles.table_box_1}>
            <div className={styles.study_txt}>Study Circle</div>
            <Box className={styles.boxes} component="div">
              <Formik>
                {(formik) => {
                  return (
                    <Form className={styles.boxes}>
                      <div className={styles.box1}>
                        <label
                          htmlFor="study_circle_name"
                          className={styles.labels}
                        >
                          Study Circle Name
                        </label>
                        <Box className={styles.std_name} component="div">
                          <Field
                            // name="study_circleName"
                            type="text"
                            className={styles.std_name}
                            id="study_circleName"
                            value={state.study_circle_Selected_Name}
                            onChange={(e) =>
                              setState({
                                ...state,
                                study_circle_Selected_Name: e.target.value,
                              })
                            }
                          />
                        </Box>
                      </div>
                      <div className={styles.box2}>
                        <label
                          htmlFor="inchange_of_study_circle"
                          className={styles.labels}
                        >
                          Incharge of Study Circle
                        </label>
                        <Box className={styles.std_name} component="div">
                          <Field
                            as="select"
                            // name="incharge_studyCircle"
                            className={styles.d_options}
                            value={state.incharge_teacher_Selected_ID}
                            onChange={(e) => {
                              setState({
                                ...state,
                                incharge_teacher_Selected_ID: e.target.value,
                              });
                            }}
                          >
                            {state.incharge_data
                              .sort((a, b) =>
                                a.cmn_teacher_name.localeCompare(
                                  b.cmn_teacher_name
                                )
                              )
                              .map((elm, key) => {
                                return (
                                  <option value={elm.cmn_teacher_id} key={key}>
                                    {elm.cmn_teacher_name}
                                  </option>
                                );
                              })}
                          </Field>
                        </Box>
                      </div>
                      <div className={styles.box2}>
                        <label
                          htmlFor="inchange_of_study_circle"
                          className={styles.labels}
                        >
                          Primary Class
                        </label>
                        <Box className={styles.std_name} component="div">
                          {console.log(state.primary_class_Selected_ID,'matching value here.......................')}
                          <Field
                            as="select"
                            // name="primary_class_Selected_ID"
                            className={styles.d_options}
                            value={state.primary_class_Selected_ID}
                            onChange={(e) => {
                              setState({
                                ...state,
                                primary_class_Selected_ID: e.target.value,
                              });
                            }}
                          >
                            {state.selected_class.sort((a,b)=>a.qb_class_number-b.qb_class_number)
                              .map((elm, key) => {
                                return (
                                  <option value={elm.qb_class_id} key={key}>
                                    {elm.qb_class_number}
                                  </option>
                                );
                              })}
                          </Field>
                        </Box>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </div>
        </div>
        <div
          className={styles.file_container}
          style={{ margin: "-30px 0 -10px 0" }}
        >
          <Box className={styles.file_box} component="div">
            <div className={styles.file_text}>
              <label>Educator List ({state.educator_body.length})</label>
            </div>
            <div className={styles.box_btn_add}>
              <Box className={styles.btn_add} onClick={handleClickOpenAdd}>
                Add
              </Box>
            </div>
          </Box>
        </div>
        <div className={styles.educatorTable}>
          <div className={styles.table_box_1}>
            {isLoading === true ? (
              <Example />
            ) : (
              <div className={styles.table_box}>
                {isLoading === true ? (
                  <Example />
                ) : (
                  <div className={styles.schoolList_Table}>
                    <div className={styles.schoolList_Table_head}>
                      {state.educator_head.map((elm, key) => (
                        <div
                          key={key}
                          className={styles.schoolList_Table_head_data}
                        >
                          {elm}
                        </div>
                      ))}
                    </div>
                    {
                      <div className={styles.schoolList_Table_body}>
                        {state.educator_body.length === 0
                          ? <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "bold",
                          }}
                        >
                          No Data Found<span role='img' aria-label='jsx-a11y/accessible-emoji'>😔</span>
                        </div>
                          : state.educator_body.map((elm, key) => (
                              <div
                                key={key}
                                className={styles.schoolList_Table_body_row}
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className={
                                    styles.schoolList_Table_body_row_td2
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Avatar
                                      src={
                                        elm.cmn_teacher_profile_pic_file_path
                                      }
                                      alt="avatar"
                                      style={{
                                        width: "16px",
                                        height: "16px",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    />
                                    <div>{elm.cmn_teacher_name}</div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    styles.schoolList_Table_body_row_td3
                                  }
                                >
                                  {elm.cmn_syllabus_name}
                                </div>
                                <div
                                  className={
                                    styles.schoolList_Table_body_row_td3
                                  }
                                >
                                  {elm.qb_class_number}
                                </div>
                                <div
                                  className={
                                    styles.schoolList_Table_body_row_td3
                                  }
                                >
                                  {elm.cmn_subject_name}
                                </div>
                                <div
                                  className={
                                    styles.schoolList_Table_body_row_td3
                                  }
                                >
                                  <Icon
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <img
                                      src={require("../../../../assets/icons/DeleteBox.png")}
                                      alt="deletebox"
                                      style={{ cursor: "pointer" }}
                                      data-value={
                                        elm.cmn_study_circle_teacher_subject_id
                                      }
                                      onClick={(e) => handleDelete(e)}
                                    />
                                  </Icon>
                                </div>
                              </div>
                            ))}
                      </div>
                    }
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className={styles.file_container}>
          <Box className={styles.file_box} component="div">
            <div className={styles.file_text}>
              <label>Student List ({state.student_body.length})</label>
            </div>
            <div className={styles.file_opt}>
              <Box
                component="div"
                onClick={downloadFile}
                className={styles.file_download}
              >
                
                  <img
                    src={require("../../../../assets/icons/down.png")}
                    alt="down"
                    style={{
                      marginRight: "10px",
                      width: "13px",
                      height: "16px",
                    }}
                  />
                
                Download Student List
              </Box>
              <Formik>
                {(formik) => {
                  return (
                    <div>
                      <Form>
                        <Field
                          type="file"
                          id="filetoupload"
                          name="filetoupload"
                          onChange={(e) => {
                            uploadFile(e);
                            e.target.value = null;
                          }}
                          style={{ display: "none" }}
                        />
                        <Box component="div" className={styles.file_upload}>
                          <label
                            href="#"
                            htmlFor="filetoupload"
                            style={{
                              borderRadius: "10px",
                              width: "220px",
                              height: "32px",
                              textAlign: "center",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={require("../../../../assets/icons/up.png")}
                              alt="up"
                              style={{
                                marginRight: "10px",
                                width: "13px",
                                height: "16px",
                              }}
                            />
                            Upload New Sutdent List
                          </label>
                        </Box>
                      </Form>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </Box>
        </div>
        <div>
          <div className={styles.table_box_1}>
            {isLoading === true ? (
              <Example />
            ) : (
              <div className={styles.table_box}>
                {isLoading === true ? (
                  <Example />
                ) : (
                  <div className={styles.schoolList_Table}>
                    <div className={styles.schoolList_Table_head}>
                      {state.student_head.map((elm, key) => (
                        <div
                          key={key}
                          className={styles.schoolList_Table_head_data}
                        >
                          {elm}
                        </div>
                      ))}
                    </div>
                    {
                      <div className={styles.schoolList_Table_body}>
                        {state.student_body.length === 0
                          ? <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "bold",
                          }}
                        >
                          No Data Found<span role='img' aria-label='jsx-a11y/accessible-emoji'>😔</span>
                        </div>
                          : state.student_body.map((elm, key) => (
                              <div
                                key={key}
                                className={styles.schoolList_Table_body_row}
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className={
                                    styles.schoolList_Table_body_row_td2
                                  }
                                >
                                  {elm.cmn_student_roll_number}
                                </div>
                                <div
                                  className={
                                    styles.schoolList_Table_body_row_td3
                                  }
                                >
                                  <div className={styles.dds}>
                                    <div>
                                      <Avatar
                                        src={
                                          elm.cmn_student_profile_pic_file_path
                                        }
                                        alt="avatar"
                                        style={{
                                          width: "16px",
                                          height: "16px",
                                        }}
                                      />
                                    </div>
                                    <div>{elm.cmn_student_name}</div>
                                  </div>
                                </div>
                                <div
                                  className={
                                    styles.schoolList_Table_body_row_td3
                                  }
                                >
                                  {elm.cmn_student_institution_student_id}
                                </div>
                                <div
                                  className={
                                    styles.schoolList_Table_body_row_td3
                                  }
                                >
                                  {elm.cmn_student_institution_defined_group}
                                </div>
                              </div>
                            ))}
                      </div>
                    }
                  </div>
                )}
              </div>
            )}
          </div>
          <Box className={styles.buttons}>
            <div className={styles.buttons_div}>
              <Box component="button" className={styles.btn_cancel}>
                Cancel
              </Box>
              <Box
                component="button"
                className={styles.btn_save}
                onClick={addStudyCircleTab}
              >
                Save
              </Box>
            </div>
          </Box>
        </div>
        <ModalComponent show={state.showHidePlus} onClick={handleClosePlus}>
          <Box
            className={styles.main_box}
            component="div"
            style={{ marginTop: "-20px" }}
          >
            <Formik
              initialValues={initialFormBox || state}
              validationSchema={validationFormBox}
              onSubmit={handleSave}
              enableReinitialize
            >
              {(formik) => {
                return (
                  <Box
                    component="div"
                    style={{ width: "100%", height: "auto" }}
                  >
                    <Form className={styles.modal_Form}>
                      <label htmlFor="board">Board</label>
                      <Box className={styles.main_box_1} component="div">
                        <Field
                          as="select"
                          name=" board_value"
                          className={styles.d_options}
                          value={undefined}
                          onChange={(e) => handleBoardChange(e)}
                        >
                          <option>{"Board"}</option>
                          {state.drop_board.map((elm, key) => {
                            return (
                              <option
                                value={elm.cmn_school_syllabus_id}
                                key={key}
                              >
                                {elm.qb_syllabus_name}
                              </option>
                            );
                          })}
                        </Field>
                      </Box>
                      <label htmlFor="board">Grade</label>
                      <Box className={styles.main_box_2} component="div">
                        <Field
                          as="select"
                          name="class_value"
                          className={styles.d_options}
                          value={undefined}
                          onChange={(e) => handleClassChange(e)}
                        >
                          <option>{"Grade"}</option>
                          {state.drop_class.map((elm, key) => {
                            return (
                              <option value={elm.qb_class_id} key={key}>
                                {elm.qb_class_number}
                              </option>
                            );
                          })}
                        </Field>
                      </Box>
                      <label htmlFor="board">Subject</label>
                      <Box className={styles.main_box_2} component="div">
                        <Field
                          as="select"
                          name="subject_value"
                          className={styles.d_options}
                          value={undefined}
                          onChange={(e) => handleSubjectChange(e)}
                        >
                          <option>{"Subject"}</option>
                          {state.drop_sub.map((elm, key) => {
                            return (
                              <option value={elm.cmn_subject_id} key={key}>
                                {elm.cmn_subject_name}
                              </option>
                            );
                          })}
                        </Field>
                      </Box>
                      <label htmlFor="board">Educator</label>
                      <Box className={styles.main_box_3} component="div">
                        <Field
                          as="select"
                          name="educator_value"
                          className={styles.d_options}
                        >
                          <option>{"Educator"}</option>
                          {state.drop_edu.map((elm, key) => {
                            return (
                              <option
                                value={elm.cmn_teacher_subject_id}
                                key={key}
                              >
                                {elm.cmn_teacher_name}
                              </option>
                            );
                          })}
                        </Field>
                      </Box>
                      <Box className={styles.buttonss}>
                        <button
                          className={styles.cancelBtn}
                          onClick={handleClosePlus}
                          type="reset"
                        >
                          Cancel
                        </button>
                        <button
                          className={styles.saveBtn}
                          type="submit"
                          onClick={(e) => setNextPage(true)}
                          disabled={!formik.isValid || formik.isSubmitting}
                        >
                          Save
                        </button>
                      </Box>
                    </Form>
                  </Box>
                );
              }}
            </Formik>
          </Box>
        </ModalComponent>
        <div></div>
      </div>
    );
  };

  return (
    <div>
      {nextPage === false ? (
        <Box className={styles.container}>
          <Box className={styles.top_box} component="div">
            <Box
              className={styles.add_box}
              component="div"
              onClick={handleClickOpen}
            >
              Add Study Circle
            </Box>
            <Box className={styles.format} component="div">
             
                <img
                  src={require("../../../../assets/icons/down.png")}
                  alt="down"
                  style={{ marginRight: "10px", width: "13px", height: "16px" }}
                />
              
              Download StudyCircle List
            </Box>
            <Box component="div" className={styles.data}>
              <img
                src={require("../../../../assets/icons/up.png")}
                alt="up"
                style={{ marginRight: "10px", width: "13px", height: "16px" }}
              />
              Upload StudyCircle List
            </Box>
          </Box>
          <Box>
            <div className={styles.table_box_list}>
              {isLoading === true ? (
                <Example />
              ) : (
                <div className={styles.table_box}>
                  {isLoading === true ? (
                    <Example />
                  ) : (
                    <div className={styles.schoolList_Table}>
                      <div className={styles.schoolList_Table_head}>
                        {state.study_circle_t_head.map((elm, key) => (
                          <div
                            key={key}
                            className={styles.schoolList_Table_head_data}
                          >
                            {elm}
                          </div>
                        ))}
                      </div>
                      {
                        
                        <div className={styles.schoolList_Table_body}>
                          {state.study_circle_t_body === 0
                            ? <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: "bold",
                            }}
                          >
                            No Data Found<span role='img' aria-label='jsx-a11y/accessible-emoji'>😔</span>
                          </div>
                            : state.study_circle_t_body
                                .sort(
                                  (a, b) =>
                                    parseInt(a.cmn_student_roll_number) -
                                    parseInt(b.cmn_student_roll_number)
                                )
                                .map((elm, key) => (
                                  <div
                                    className={styles.schoolList_Table_body_row}
                                    key={key}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      onTableClick(
                                        elm.cmn_study_circle_id,
                                        elm.cmn_study_circle_name,
                                        elm.incharge_teacher_id,
                                        elm.primary_qb_class_id
                                      );
                                    }}
                                  >
                                    <div
                                      className={
                                        styles.schoolList_Table_body_row_td2
                                      }
                                    >
                                      {elm.cmn_study_circle_name}
                                    </div>
                                    <div
                                      className={
                                        styles.schoolList_Table_body_row_td3
                                      }
                                    >
                                      {elm.classes_connected}
                                    </div>
                                    <div
                                      className={
                                        styles.schoolList_Table_body_row_td3
                                      }
                                    >
                                      {elm.subject_count}
                                    </div>
                                    <div
                                      className={
                                        styles.schoolList_Table_body_row_td3
                                      }
                                    >
                                      {elm.student_count}
                                    </div>
                                    <div
                                      className={
                                        styles.schoolList_Table_body_row_td3
                                      }
                                    >
                                      {elm.incharge_teacher_name}
                                    </div>
                                  </div>
                                ))}
                        </div>
                      }
                    </div>
                  )}
                </div>
              )}
            </div>
          </Box>
          <ModalComponent show={state.showHide} onClick={handleClose}>
            <Box className={styles.main_box} component="div">
              <Formik
                initialValues={initialBox || state}
                validationSchema={validationBox}
                onSubmit={addStudyCircle}
                enableReinitialize
              >
                {(formik) => {
                  return (
                    <Box
                      component="div"
                      style={{ width: "100%", height: "auto" }}
                    >
                      <Form className={styles.modal_Form}>
                        <Box
                          component="div"
                          style={{ width: "68%", height: "auto" }}
                        >
                          <div className={styles.modal_Text}>
                            Study Circle Name
                          </div>
                        </Box>
                        <Box className={styles.main_box_1} component="div">
                          <Field
                            name="study_circleName"
                            type="text"
                            className={styles.B_Field}
                            id="study_circleName"
                          />
                        </Box>
                        <Box
                          component="div"
                          style={{ width: "68%", height: "auto" }}
                        >
                          <div className={styles.modal_Text}>
                            Incharge of Study Circle
                          </div>
                        </Box>
                        <Box className={styles.main_box_2} component="div">
                          <Field
                            as="select"
                            name="incharge_studyCircle"
                            className={styles.d_options}
                          >
                            <option>{"Select"}</option>
                            {state.incharge_data.map((elm, key) => {
                              return (
                                <option value={elm.cmn_teacher_id} key={key}>
                                  {elm.cmn_teacher_name}
                                </option>
                              );
                            })}
                          </Field>
                        </Box>
                        <Box
                          component="div"
                          style={{ width: "68%", height: "auto" }}
                        >
                          <div className={styles.modal_Text}>
                            Primary Class 2
                          </div>
                        </Box>
                        <Box className={styles.main_box_2} component="div">
                          <Field
                            as="select"
                            name="primary_qb_class_id"
                            className={styles.d_options}
                          >
                            <option>{"Select"}</option>
                            {state.selected_class.sort((a,b)=>a.qb_class_number-b.qb_class_number).map((elm, key) => {
                              return (
                                <option value={elm.qb_class_id} key={key}>
                                  {elm.qb_class_number}
                                </option>
                              );
                            })}
                          </Field>
                        </Box>
                        <Box className={styles.buttonss}>
                          <button
                            className={styles.cancelBtn}
                            onClick={handleClose}
                            type="reset"
                          >
                            Cancel
                          </button>
                          <button
                            className={styles.saveBtn}
                            type="submit"
                            disabled={!formik.isValid || formik.isSubmitting}
                          >
                            Save
                          </button>
                        </Box>
                      </Form>
                    </Box>
                  );
                }}
              </Formik>
            </Box>
          </ModalComponent>
        </Box>
      ) : (
        NewScreen()
      )}
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
      {errorUpload ? <ErrModal /> : null}
    </div>
  );
};

export default StudyCircle;
