import React, { useState, useRef } from "react";
import { TabPanel, TabList, Tabs, Tab } from "react-tabs";
import { Box } from "@material-ui/core";
import "./TabStyle.css";
import { Student, Educator} from "./index";
import styles from "./ManageStyle.module.css";
import EducatorAct from "../../assets/icons/Teacher_grey_Act.png";
import EducatorInact from "../../assets/icons/Teacher.png";
import StudentAct from "../../assets/icons/Study Circle_Act.png";
import StudentInact from "../../assets/icons/Study.png";
import ReferenceAct from "../../assets/icons/Reference.png";
import ReferenceInact from "../../assets/icons/Study Circle_Inact.png";
import { useStyles } from "../../assets/styles/components/searchBoxStyle";
import SearchComponent from "../../components/searchBox/searchBox";

var findID;
var tabID;
var Educator_Icon;
var Student_Icon;
var Reference_Icon;
var Educator_load;
var Student_load;
var Reference_load;

window.localStorage.getItem("tabIndexManage") == null
  ? (tabID = 0)
  : (tabID = Number(window.localStorage.getItem("tabIndexManage")));

findID = tabID;

const MasterData = () => {

  tabID === 0 ? (Educator_load = EducatorAct) : (Educator_load = EducatorInact);
  tabID === 1 ? (Student_load = StudentAct) : (Student_load = StudentInact);
  tabID === 2
    ? (Reference_load = ReferenceAct)
    : (Reference_load = ReferenceInact);

  const classes = useStyles();
  const [state, setState] = useState({
    Educator_link: Educator_load,
    Student_link: Student_load,
    Reference_link: Reference_load,
    initailSearch: '',
  });

  const tabSelected = (ID) => {
    findID = ID;
    ID === 0 ? (Educator_Icon = EducatorAct) : (Educator_Icon = EducatorInact);
    ID === 1 ? (Student_Icon = StudentAct) : (Student_Icon = StudentInact);
    ID === 2
      ? (Reference_Icon = ReferenceAct)
      : (Reference_Icon = ReferenceInact);

    setState({
      ...state,
      Educator_link: Educator_Icon,
      Student_link: Student_Icon,
      Reference_link: Reference_Icon,
      initailSearch: '',
    });
  };
  
  const EducatorRef = useRef();
  const StudentRef = useRef();
  // const ReferenceRef = useRef();

  // const selTab = window.localStorage.getItem("tabIndexManage");
  // const ChildCall = (e, selectVal) => {
  //   return EducatorRef.current.getFilter(e, selectVal);
  // };

  return (
    <div>
      <div className={styles.manage}>
        <div className={styles.dashboard_txt}>MANAGE</div>
        <div className={styles.manage_search}>
          <Box component="div" className={styles.search_box}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <img
                  src={require("../../assets/icons/search.png")}
                  alt="search"
                />
              </div>
              <SearchComponent
                id="searchBox"
                name="searchBox"
                value={state.initailSearch}
                onChange={(e) => {
                  if(findID === 0){
                    EducatorRef.current.getEducatorFilter(e, "searchTxt");
                    setState({
                      ...state,
                      initailSearch: e.target.value
                    })
                  }
                  else if(findID === 1){
                    StudentRef.current.getStudentFilter(e, "searchTxt");
                    setState({
                      ...state,
                      initailSearch: e.target.value
                    })
                  }
                  // else if(findID === 2){
                  //   ReferenceRef.current.getFilter(e, "searchTxt")
                  // }
                }}
                classess={classes}
              />
            </div>
          </Box>
        </div>
      </div>
      <Tabs defaultIndex={tabID}>
        <TabList>
          <Tab onClick={() => tabSelected(0)}>
            <img src={state.Educator_link} alt="inst" height={18} width={18} />
            <span className={styles.txt}>Educator</span>
          </Tab>
          <Tab onClick={() => tabSelected(1)}>
            <img src={state.Student_link} alt="inst" height={18} width={18} />
            <span className={styles.txt}>Student</span>
          </Tab>
          {/* <Tab onClick={() =>tabSelected(2)}>
            <img
              src={state.Reference_link}
              alt="inst"
              height={18}
              width={18}
            />
            <span className={styles.txt}>Reference</span>
          </Tab> */}
        </TabList>
        <TabPanel>
          <Box>
            <Educator ref={EducatorRef} />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <Student ref={StudentRef} />
          </Box>
        </TabPanel>
        {/* <TabPanel>
          <Box>
            <Reference />
          </Box>
        </TabPanel> */}
      </Tabs>
    </div>
  );
};

export default MasterData;
