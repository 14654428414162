import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import referenceData from "./ReferenceData";
import styles from "./ReferenceStyle.module.css";

const Reference = () => {

  window.localStorage.setItem("tabIndexManage", 2);
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(referenceData.reference);
  }, [])
  return (
    <>
      <Box className={styles.top} component="div">
        <Box className={styles.left_div}>
          <div className={styles.ref_link}>
            <label htmlFor="ref_link" className={styles.label}>
              Reference Link
            </label>
            <div className={styles.ref_link_box}>Paste Link here</div>
          </div>
          <div className={styles.ref_title}>
            <label htmlFor="ref_title" className={styles.label}>
              Reference Title
            </label>
            <div className={styles.ref_title_box}>Title</div>
          </div>
          <div className={styles.logo}>
            <label htmlFor="logo" className={styles.label}>
              Logo
            </label>
            <div className={styles.logo_box}>
              <div className={styles.logo_box_img}></div>
              <div className={styles.logo_box_txt}>+ Add Logo</div>
            </div>
          </div>
        </Box>
        <Box className={styles.right_div}>Save</Box>
      </Box>
      <Box component="div" className={styles.body}>
        {data.map((elm, key) => (
          <div key={key} className={styles.body_box}>
            <div className={styles.body_img}>
              <img
                src={elm.logo}
                alt="logo"
                style={{ width: "100px", height: "50px", padding: "10px" }}
              />
              <span></span>
            </div>
            <div className={styles.body_txt}>{elm.text}</div>
            <div className={styles.body_link}>{elm.link}</div>
          </div>
        ))}
      </Box>
      <Box className={styles.pagination} component="div">
        <Pagination count={10} />
      </Box>
    </>
  );
};

export default Reference;
