import React, { useState } from "react";
import { Divider, Box, Icon } from "@material-ui/core";
import { Redirect, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Axios from "axios";
import { BASE_URL } from "../../config/Api";
import { useStyles } from "../../assets/styles/components/searchBoxStyle";
import TextError from "../../variables/errors/Text_error";
import styles from "./Login.module.css";
import DialogBox from "../../components/dialog/DialogBox";
import SearchComponent from "../../components/searchBox/searchBox";


const addSchoolInit = {
  school_name: "",
  school_code: "",
};

const schoolSchema = Yup.object({
  school_name: Yup.string().required("School name is required!"),
  school_code: Yup.string().required("School code is required!"),
});

const Login = () => {
  const classes = useStyles();
  const [schoolId, setSchoolId] = useState(null);
  const [state, setState] = useState({
    email: "",
    password: "",
    school_name: "",
    school_code: "",
    formErrors: {email: '', password: ''},
    emailValid: false,
    passwordValid: false,
    formValid: false
  });
  let loggedIn = false;
  const [LogedIn, setLogedIn] = useState(loggedIn);
  const [token, setToken] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [userSchools, setUserSchools] = useState([]);

  let schoolhead = ["school Code", "school Name"];



  const onSubmit = (e) => {
    e.preventDefault();

      Axios.post(`${BASE_URL}/user/signin`, {
        email: state.email,
        password: state.password,
      }).then(
        (response) => {
          if (response.data.status === true) {
            setSchoolId(response.data.data.result[0].cmn_school_id);
  
            window.localStorage.setItem("token", response.data.data.token);
            window.localStorage.setItem("sessionExp", response.data.data.expiry);
  
            setToken(response.data.data.token);
            window.localStorage.setItem(
              "school_id",
              response.data.data.result[0].cmn_school_id
            );
            window.localStorage.setItem(
              "cmn_user_email",
              response.data.data.result[0].cmn_user_email
            );
            window.localStorage.setItem(
              "cmn_user_full_name",
              response.data.data.result[0].cmn_user_full_name
            );
            window.localStorage.setItem(
              "cmn_school_name",
              response.data.data.result[0].cmn_school_name
            );
            window.localStorage.setItem(
              "cmn_user_id",
              response.data.data.result[0].cmn_user_id
            );
            if (response.data.data.result) {
              const fetchData = async () => {
                try {
                  const result = await (
                    await Axios.get(
                      `${BASE_URL}/user/${response.data.data.result[0].cmn_user_id}/schools`,
                      {
                        headers: {
                          Authorization: "Bearer " + response.data.data.token,
                        },
                      }
                    )
                  ).data;
                  if (result.data.length === 0) {
                    setOpenModal(false);
                    setLogedIn(true);
                    toast.success("login successful !");
                  } else if (result.data.length === 1) {
                    setUserSchools(result.data);
                    // setOpenModal(true);
                    setLogedIn(true);
                    toast.success("login successful !");
                  } else if (result.data.length > 0) {
                    setUserSchools(result.data);
                    setOpenModal(true);
                  }
                } catch (err) {
                  toast.error(err.response.data.message);
                }
              };
              fetchData();
            } else {
              setLogedIn(true);
              setOpenModal(false);
            }
          }
        },
        (error) => {
          toast.error(error.response.data.message)
          setLogedIn(false);
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        }
      );
  };

  const onSubmitSchool = async (values, submitProps) => {
    submitProps.setSubmitting(false);
    submitProps.resetForm();

    await Axios.post(
      `${BASE_URL}/institution`,
      {
        school_name: values.school_name,
        school_code: values.school_code,
      },
      {
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("token"),
        },
      }
    )
      .then(async (res) => {
        toast.success("School Successfully added!");
        try {
          document.getElementById("searchBox").value = "";
          const getTableData = await (
            await Axios.get(
              `${BASE_URL}/user/${window.localStorage.getItem(
                "cmn_user_id"
              )}/schools?search_text=`,
              {
                headers: {
                  Authorization:
                    "Bearer " + window.localStorage.getItem("token"),
                },
              }
            )
          ).data;
          setUserSchools(getTableData.data);
        } catch (err) {
          console.log(err.response, "error response");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleClose = () => {
    setOpenModal(false);
    window.location.reload(false);
  };

  const getUserInfo = (e, f) => {
    toast.success(`selected School : ${f}`);
    toast.success("login successful !");
    setTimeout(() => {
      window.localStorage.setItem("school_id", e);
      window.localStorage.setItem("school_name", f);
      setLogedIn(true);
      setOpenModal(false);
    }, 3000);
  };

  function showPassword() {
    var temp = document.getElementById("password");
    if (temp.type === "password") {
      temp.type = "text";
    } else {
      temp.type = "password";
    }
  }

  if (LogedIn) {
    return <Redirect from="/" to="/admin" SchoolId={schoolId} token={token} />;
  }

  const forget = () => {
    return <Redirect to="/reset-pasword" />;
  };

  const changedBoard = async (e) => {
    try {
      const getTableData = await (
        await Axios.get(
          `${BASE_URL}/user/${window.localStorage.getItem(
            "cmn_user_id"
          )}/schools?search_text=${e.target.value}`,
          {
            headers: {
              Authorization: "Bearer " + window.localStorage.getItem("token"),
            },
          }
        )
      ).data;
      setUserSchools(getTableData.data);
    } catch (err) {
      console.log(err.response, "error response");
    }
  };

  return (
    <div className={styles.container}>
      <Box className={styles.header} p={1}>
        <div className={styles.header_body}>
          <img
            src={require("../../assets/images/stucle.png")}
            alt="header-logo"
            className={styles.image}
          />
        </div>
      </Box>
      <Divider
        style={{ background: "#A6C8A6", height: "1px", width: "100%" }}
      />
      <main className={styles.body}>
        <div className={styles.left}>
          <form method="post" onSubmit={onSubmit} className={styles.formik}>
            <div className={styles.form_div}>
              <div className={styles.form}>
                <Box className={styles.username} component="div">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Username"
                    value={state.email}
                    onChange={(e) =>
                      setState({ ...state, email: e.target.value })
                    }
                    className={styles.username_field}
                  />
                </Box>
                <Box className={styles.password} component="div">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={state.password}
                    onChange={(e) =>
                      setState({ ...state, password: e.target.value })
                    }
                    className={styles.password_field}
                  />
                  <Box
                    component="span"
                    className={styles.show_password}
                    onClick={() => showPassword()}
                  >
                    show password
                  </Box>
                </Box>
                <button
                  type="submit"
                  className={styles.Button}
                  disabled={!state.email || !state.password ? true : false}
                >
                  <div>Sign in</div>
                  <div style={{ display: "flex" }}>
                    <Icon style={{ display: "flex" }}>
                      <ExitToAppIcon />
                    </Icon>
                  </div>
                </button>
              </div>
              <Box component="div" className={styles.forget}>
                <Box className={styles.sign}>
                  <input type="checkbox" name="check" id="check" />
                  <span className={styles.keep}>Keep me signed in</span>
                </Box>
                <Link to="/reset-password">
                  <Box
                    className={styles.forget_password}
                    onClick={() => forget()}
                  >
                    Forgot password ?
                  </Box>
                </Link>
              </Box>
              <Box className={styles.knowMore} component="div">
                <Box component="div" className={styles.not_register}>
                  Not Registred Yet?
                </Box>
                <Box component="div" className={styles.know_more}>
                  Know More
                  <Icon fontSize="small" className={styles.know_more}>
                    <ArrowForwardIosIcon style={{ height: "15px" }} />
                  </Icon>
                </Box>
              </Box>
            </div>
          </form>
        </div>
        <div className={styles.right}></div>
      </main>
      <footer className={styles.footer}>
        &copy; {new Date().getFullYear()} Apps'n'Devices Technologies Pvt Ltd.
        All rights reserved.
      </footer>
      {openModal ? (
        <DialogBox
          open={openModal}
          close={handleClose}
          style={{ overflow: "hidden", width: "100%", height: "100%" }}
        >
          <Box className={styles.school_add}>
            <Box component="div" className={styles.closeLine} p={5}>
              <div className={styles.userInfo}>User School List</div>
              <Box component="div" className={styles.search_box}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <img
                      src={require("../../assets/icons/search.png")}
                      alt="search"
                    />
                  </div>
                  <SearchComponent
                    id="searchBox"
                    name="searchBox"
                    onChange={(e) => {
                      changedBoard(e);
                    }}
                    classess={classes}
                  />
                </div>
              </Box>
              <div className={styles.close} onClick={handleClose}>
                <img
                  src={require("../../assets/icons/close.png")}
                  alt="close"
                  style={{
                    height: "16px",
                    width: "16px",
                    cursor: "pointer",
                    border: "none",
                    outline: "none",
                  }}
                />
              </div>
            </Box>
            <Box component="div" p={5} className={styles.tbl}>
              <div className={styles.schoolList_Table}>
                <div className={styles.schoolList_Table_head}>
                  {schoolhead.map((elm, key) => (
                    <div
                      key={key}
                      className={styles.schoolList_Table_head_data}
                    >
                      {elm}
                    </div>
                  ))}
                </div>
                <div className={styles.schoolList_Table_body}>
                  {userSchools.map((elm, key) => (
                    <div
                      key={key}
                      onClick={() =>
                        getUserInfo(elm.cmn_school_id, elm.cmn_school_name)
                      }
                      className={styles.schoolList_Table_body_row}
                    >
                      {/* <div className={styles.schoolList_Table_body_row_td1}>
                    {elm.cmn_school_id}
                  </div> */}
                      <div className={styles.schoolList_Table_body_row_td2}>
                        {elm.cmn_school_code}
                      </div>
                      <div className={styles.schoolList_Table_body_row_td3}>
                        {elm.cmn_school_name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Box>
            <Box component="div" className={styles.btm} p={5}>
              <Formik
                initialValues={addSchoolInit}
                validationSchema={schoolSchema}
                onSubmit={onSubmitSchool}
                enableReinitialize
                className={styles.addSchool}
              >
                {(formik) => {
                  return (
                    <Form style={{ width: "100%" }}>
                      <div className={styles.bdy}>
                        <div className={styles.lft}>
                          <div className={styles.bx2}>
                            <Field
                              type="text"
                              id="school_code"
                              name="school_code"
                              required={true}
                              placeholder="Enter School Code"
                              className={styles.bx22}
                            />
                          </div>
                          <ErrorMessage
                            name="school_code"
                            component={TextError}
                          >
                            {(error) => <div className="error">{error}</div>}
                          </ErrorMessage>
                          <div className={styles.bx1}>
                            <Field
                              type="text"
                              id="school_name"
                              name="school_name"
                              required={true}
                              placeholder="Enter School Name"
                              className={styles.bx11}
                            />
                          </div>
                          <ErrorMessage
                            name="school_name"
                            component={TextError}
                          >
                            {(error) => <div className="error">{error}</div>}
                          </ErrorMessage>
                        </div>

                        <div className={styles.rgt}>
                          <button
                            type="submit"
                            className={styles.btn}
                            disabled={!formik.isValid || formik.isSubmitting}
                          >
                            <div>Add School</div>
                          </button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </DialogBox>
      ) : null}
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
    </div>
  );
};

export default Login;
