import React, { useState, useEffect} from "react";
import { Box, InputBase } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { Pagination } from "@material-ui/lab";
import { toast, ToastContainer } from "react-toastify";
import { saveAs } from "file-saver";
import Axios from "axios";
import { fade, makeStyles } from "@material-ui/core/styles";
import { BASE_URL } from "../../../../config/Api";
import styles from "./StudentStyle.module.css";
import { Dialogbox } from "../../../../components/index";
import * as ReactBootStrap from "react-bootstrap";
import ReactLoading from "react-loading";

var default_page = 1;
var selectedGroup;
var searched;
var isd = "";
let IDF = "";
var searchString = "";
var rows = 10;
var pageNum = 1;

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Student = () => {
  const classes = useStyles();
  const [errorUpload, setErrorUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [errMsg, setErrMsg] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    t_head: [
      "Serial Number",
      "Student",
      "Institution Student ID",
      "Institution Defined Group",
      "Reg. Mobile no.",
      "Alternate Mobile Number",
      "DOB",
      "City",
      // "Pincode",
      // "Internet Service Provider",
    ],
    t_body: [],
    t_opt: [],
    pagecount: null,
  });

  window.localStorage.setItem("tabIndex", 4);
  const school_id = window.localStorage.getItem("school_id");
  const token = window.localStorage.getItem("token");
  const Auth = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  let pageNo = 10;

  const fetchResult = async() => {
    try {
      const dropDownDate = await (
        await Axios.get(
          `${BASE_URL}/institution/${school_id}/institution-defined-group`,
          Auth
        )
      ).data;

      const getTableData = await (
        await Axios.get(
          `${BASE_URL}/institution/${school_id}/student/search?institution_defined_group=&search_string=&rows=&page_number=`,
          Auth
        )
      ).data;
      let extraPage = getTableData.count % pageNo === 0 ? 0 : 1;
      setIsLoading(false);
      setState({
        ...state,
        t_body: getTableData.data,
        t_opt: dropDownDate.data,
        pagecount: Math.floor(getTableData.count / pageNo + extraPage),
      });
    } catch (err) {
      console.log(err, "error");
    }
  }

  useEffect(() => {
    setIsLoading(true);
    fetchResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pagechange = (e, value) => {
    default_page = value;
    changedBoard(value, "pagenumber");
  };

  const changedBoard = async (e, selectedvalue) => {
    if (selectedvalue === "selectedgroup") {
      selectedGroup = e.target.value;
      pageNum = 1;
      default_page = 1;
      isd = `'${e.target.value}'`;
      IDF = `${e.target.value}`
      if (selectedGroup === "Institution Defined Group") {
        isd = "";
        IDF = "";
      }
    } else if (selectedvalue === "selectedsearch") {
      searched = e.target.value;
      searchString = searched;
      pageNum = 1;
      default_page = 1;
      if (searched === "") {
        searchString = "";
        pageNum = 1;
        default_page = 1;
      }
    } else if (selectedvalue === "pagenumber") {
      pageNum = e;
    } else if (selectedvalue === "showrow") {
      rows = e.target.value;
      pageNo = rows;
      setState({ ...state });
      pageNum = 1;
      default_page = 1;
    }

    try {
      const getTableData = await (
        await Axios.get(
          `${BASE_URL}/institution/${school_id}/student/search?institution_defined_group=${isd}&search_string=${searchString}&rows=${rows}&page_number=${pageNum}`,
          Auth
        )
      ).data;
      let extraPage = getTableData.count % pageNo === 0 ? 0 : 1;
      setState({
        ...state,
        t_body: getTableData.data,
        pagecount: Math.floor(getTableData.count / pageNo + extraPage),
      });
    } catch (err) {
      console.log(err.response, "error");
    }
  };

  const downloadFile = async () => {
    await Axios.get(`${BASE_URL}/institution/${school_id}/student/export?institution_defined_group=${IDF}`, {
      ...Auth,
      responseType: "arraybuffer",
    }).then((response) => {
      console.log(response,'res')
      var blob = new Blob([response.data], {
        type: "application/octet-stream",
      });
      saveAs(blob, "StudentReports.xlsx");
      toast.success("file downloaded");
    }).catch(error=>console.log(error.response,'error happened'))
  };

  const uploadFile = async (e) => {
    let formdata = new FormData();
    let file = e.target.files[0];
    setLoading(true);
    // const options = {
    //   onUploadProgress: (progressEvent) => {
    //     const { loaded, total } = progressEvent;
    //     let percent = Math.floor((loaded * 100) / total);
    //     console.log(`${loaded}kb of ${total}kb | ${percent}%`);
    //   }
    // }

    formdata.append("filetoupload", file);
    Axios({
      url: `${BASE_URL}/institution/${school_id}/student`,
      mode: "no-cors",
      method: "POST",
      headers: {
        ...Auth.headers,
        "Content-Type": "multipart/form-data",
        Accept: "*",
        type: "formData",
        "Access-Control-Allow-Headers": "*",
      },
      data: formdata,
    })
      .then((res) => {
        toast.success("File Uploaded Successfully");
        setLoading(false);
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      })
      .catch((err) => {
        if (err) {
          setErrorUpload(true);
          setLoading(false);
          toast.error(`oops! error while upload file  check in the modal..`);
          setMsg(err.response.data.message);
          setErrMsg(err.response.data.data);
        }
      });
  };

  const handleClosePopup = () => setErrorUpload(false);
  const Example = ({ type, color }) => (
    <div className="loading_animation">
      <ReactLoading type="bars" color="#DCEEDC" height="10%" width="10%" />
    </div>
  );

  const ErrModal = () => {
    let errHead = ["Student Id", "Student Name", "Error"];
    return (
      <Dialogbox Dialogbox open={errorUpload} handleClose={handleClosePopup}>
        <div className={styles.errMod}>
          <div className={styles.Msg}>
            <div>{`${msg}`}</div>
            <div className={styles.closeBtn} onClick={handleClosePopup}>
              <img
                src={require("../../../../assets/icons/close.png")}
                alt="closeBtn"
                style={{ height: "16px", width: "16px", cursor: "pointer" }}
              />
            </div>
          </div>
          <table className={styles.content_table}>
            <thead>
              <tr>
                {errHead.map((elm, key) => (
                  <th key={key}>{elm}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {errMsg.map((elm, key) => {
                return (
                  <tr key={key}>
                    <td>{elm.student_id}</td>
                    <td>{elm.student_name}</td>
                    <td>{elm.result ? elm.result : elm.message}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dialogbox>
    );
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.topBox}>
        <Box component="div" className={styles.left}>
          <Box
            component="select"
            className={styles.options}
            onChange={(e) => {
              changedBoard(e, "selectedgroup");
            }}
          >
            <option>{"Institution Defined Group"}</option>
            {state.t_opt.map((el, key) => (
              <option
                value={el.cmn_student_institution_defined_group}
                key={key}
              >
                {el.cmn_student_institution_defined_group}
              </option>
            ))}
          </Box>
        </Box>
        <Box component="div" className={styles.right}>
          <Box
            component="div"
            className={styles.search_box}
            onChange={(e) => {
              changedBoard(e, "selectedsearch");
            }}
          >
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <img
                  src={require("../../../../assets/icons/search.png")}
                  alt="search"
                />
              </div>
              <InputBase
                placeholder="Search"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </Box>
          <Box
            component="div"
            className={styles.download_list}
            onClick={downloadFile}
          >
            <img
              src={require("../../../../assets/icons/down.png")}
              alt="down"
              style={{ marginRight: "10px" }}
            />
            Download Student List
          </Box>
          <Box component="div" className={styles.upload_list}>
            <Formik>
              {(formik) => {
                return (
                  <div className={styles.upload}>
                    <Form>
                      <Field
                        type="file"
                        id="filetoupload"
                        name="filetoupload"
                        // multiple
                        // accept=".*"
                        onChange={(e) => {
                          uploadFile(e);
                          e.target.value = null;
                        }}
                        style={{ display: "none" }}
                      />
                      <label
                        htmlFor="filetoupload"
                        style={{
                          borderRadius: "10px",
                          width: "120px",
                          height: "32px",
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "10px",
                        }}
                      >
                        <img
                          src={require("../../../../assets/icons/up.png")}
                          alt="up"
                          style={{ marginRight: "10px" }}
                        />
                        Upload New Student List
                      </label>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </Box>
      {loading ? (
        <div className={styles.loader}>
          <ReactBootStrap.Spinner animation="border" variant="success" /> &nbsp;
          <span className={styles.loaderTxt}>Loading...</span>
        </div>
      ) : null}
      <Box component="div" className={styles.tableBox}>
        <div className={styles.table_box}>
          {isLoading === true ? (
            <Example />
          ) : (
            <div className={styles.schoolList_Table}>
              <div className={styles.schoolList_Table_head}>
                {state.t_head.map((elm, key) => (
                  <div key={key} className={styles.schoolList_Table_head_data}>
                    {elm}
                  </div>
                ))}
              </div>
              {
                <div className={styles.schoolList_Table_body}>
                  {state.t_body.length === 0
                    ? <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                    }}
                  >
                    No Data Found<span role='img' aria-label='jsx-a11y/accessible-emoji'>😔</span>
                  </div>
                    : state.t_body.map((elm, key) => (
                        <div
                          key={key}
                          className={styles.schoolList_Table_body_row}
                        >
                          <div className={styles.schoolList_Table_body_row_td2}>
                            {elm.cmn_student_id}
                          </div>
                          <div className={styles.schoolList_Table_body_row_td3}>
                            <div className={styles.student_avt}>
                              {/* <div>
                          <Avatar
                            src={elm.cmn_student_profile_pic_file_path}
                            style={{
                              height: "22px",
                              width: "22px",
                              marginRight: "10px",
                            }}
                            alt="avatar"
                          />
                        </div> */}
                              <div>{elm.cmn_student_name}</div>
                            </div>
                          </div>
                          <div className={styles.schoolList_Table_body_row_td3}>
                            {elm.cmn_student_institution_student_id}
                          </div>
                          <div className={styles.schoolList_Table_body_row_td3}>
                            {elm.cmn_student_institution_defined_group}
                          </div>
                          <div className={styles.schoolList_Table_body_row_td3}>
                            {elm.cmn_student_phone_number}
                          </div>
                          <div className={styles.schoolList_Table_body_row_td3}>
                            {elm.cmn_student_alt_phone_number}
                          </div>
                          <div className={styles.schoolList_Table_body_row_td3}>
                            {elm.cmn_student_dob}
                          </div>
                          <div className={styles.schoolList_Table_body_row_td3}>
                            {elm.cmn_student_city}
                          </div>
                          {/* <div className={styles.schoolList_Table_body_row_td3}>
                      {elm.pincode}
                    </div>
                    <div className={styles.schoolList_Table_body_row_td3}>
                      {elm.internet}
                    </div> */}
                        </div>
                      ))}
                </div>
              }
            </div>
          )}
        </div>
      </Box>
      <Box className={styles.bottomBox} component="div">
        <div
          className="col-md-10"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "50px",
          }}
        >
          <Pagination
            count={state.pagecount}
            page={default_page}
            // defaultPage={default_page}
            onChange={pagechange}
          />
        </div>
        <div className="col-md-2">
          <Box
            component="select"
            className={styles.options}
            onChange={(e) => {
              changedBoard(e, "showrow");
            }}
          >
            <option value="10">{"Show 10"}</option>
            <option value="20">{"Show 20"}</option>
          </Box>
        </div>
      </Box>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
      {errorUpload ? <ErrModal /> : null}
    </Box>
  );
};

export default Student;
