import React from "react";
import { Dialog, DialogContent, Slide } from "@material-ui/core";
import './Modalcustomstyles.css'

const Transition = React.forwardRef(function Transition(prop, ref) {
  return <Slide direction="up" ref={ref} {...prop} />;
});

const DialogBox = (props) => {
  const { handleClose, open, children } = props;
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
      // style={{maxWidth:'800px'}}
      maxWidth='lg'
    >
      <DialogContent style={{width:'660px',height:'540px',padding:'0px'}}>{children}</DialogContent>
    </Dialog>
  );
};

export default DialogBox;
