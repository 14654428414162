import UE from "../../../../assets/images/UE.png";
import EngClub from "../../../../assets/images/EngClub.png";
import GTransfer from "../../../../assets/images/GTransfer.png";
import Oxford from "../../../../assets/images/Oxford.png";
import DSeng from "../../../../assets/images/DSeng.png";
import memrise from "../../../../assets/images/memrise.png";

export default {
  reference: [
    {
      id: 1,
      logo: UE,
      text: "English Language Reference",
      link: "www.usingenglish.com",
    },
    {
      id: 2,
      logo: EngClub,
      text: "Learners + teachers of English",
      link: "www.englishclub.com",
    },
    {
      id: 3,
      logo: GTransfer,
      text: "Translate Any Language",
      link: "www.translate.google.com",
    },
    {
      id: 4,
      logo: EngClub,
      text: "Learners + teachers of English",
      link: "www.englishclub.com",
    },
    {
      id: 5,
      logo: Oxford,
      text: "Dictionaries",
      link: "www.oxfordlearnersdictionaries.com",
    },
    {
      id: 6,
      logo: GTransfer,
      text: "Translate Any Language",
      link: "www.translate.google.com",
    },
    {
      id: 7,
      logo: Oxford,
      text: "Dictionaries",
      link: "www.oxfordlearnersdictionaries.com",
    },
    {
      id: 8,
      logo: Oxford,
      text: "Dictionaries",
      link: "www.oxfordlearnersdictionaries.com",
    },
    {
      id: 9,
      logo: EngClub,
      text: "Learners + teachers of English",
      link: "www.englishclub.com",
    },
    {
      id: 10,
      logo: EngClub,
      text: "Self Study Tips - DailyStep English",
      link: "www.dailystep.com",
    },
    {
      id: 11,
      logo: memrise,
      text: "Memrise - Learn a language. Meet the world.",
      link: "https://tinyurl.com/loanf56",
    },
    {
      id: 12,
      logo: UE,
      text: "English Language Reference",
      link: "www.usingenglish.com",
    },
    {
      id: 13,
      logo: DSeng,
      text: "Self Study Tips - DailyStep English",
      link: "www.dailystep.com",
    },
    {
      id: 14,
      logo: memrise,
      text: "Memrise - Learn a language. Meet the world.",
      link: "https://tinyurl.com/loanf56",
    },
    {
      id: 15,
      logo: DSeng,
      text: "Self Study Tips - DailyStep English",
      link: "www.dailystep.com",
    },
  ],
};

