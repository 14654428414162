export const drawerWidth = 260;
export const whiteColor = "#ffffff";
export const sidebarbackgroundColor = "#F5F7F6";
export const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
};
export const boxShadow = { boxShadow: "rgba(255, 0, 0, 0.2)" };
export const container = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
};
