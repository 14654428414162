import React, { useState } from "react";
import { TabPanel, TabList, Tabs, Tab } from "react-tabs";
import { Box } from "@material-ui/core";
import InstitutionAct from "../../assets/icons/Institution.png";
import InstitutionInact from "../../assets/icons/Institution_inact.png";
import SubjectAct from "../../assets/icons/Subject_act.png";
import SubjectInact from "../../assets/icons/Subject.png";
import TopicAct from "../../assets/icons/Topic_act.png";
import TopicInact from "../../assets/icons/Topic.png";
import TeacherAct from "../../assets/icons/Teacher_grey.png";
import TeacherInact from "../../assets/icons/Teacher.png";
import StudentAct from "../../assets/icons/Study Circle_Grey Copy.png";
import StudentInact from "../../assets/icons/Study.png";
import StudyCircleAct from "../../assets/icons/Study Circle_Grey.png";
import StudyCircleInact from "../../assets/icons/StudyCircle.png";

// import "react-tabs/style/react-tabs.css";
import "./Tabs_style.css";
import {
  Institution,
  Subject,
  Topic,
  Educator,
  Student,
  Studycircle,
} from "./Tabs/TabScreens";
import styles from "./MasterdataStyle.module.css";

var tabID;
var Institution_Icon;
var Subject_Icon;
var Topic_Icon;
var Teacher_Icon;
var student_Icon;
var StudyCircle_Icon;
var Institution_load;
var Subject_load;
var Topic_load;
var Teacher_load;
var Student_load;
var StudyCircle_load;

window.localStorage.getItem("tabIndex") == null ? tabID = 0 : tabID = Number(window.localStorage.getItem("tabIndex"));

const MasterData = () => {

  tabID === 0 ? Institution_load = InstitutionAct :  Institution_load = InstitutionInact ;
  tabID === 1 ? Subject_load = SubjectAct : Subject_load = SubjectInact ;
  tabID === 2 ? Topic_load = TopicAct : Topic_load = TopicInact ;
  tabID === 3 ? Teacher_load = TeacherAct :  Teacher_load = TeacherInact ;
  tabID === 4 ? Student_load = StudentAct :  Student_load = StudentInact ;
  tabID === 5 ? StudyCircle_load = StudyCircleAct :  StudyCircle_load = StudyCircleInact ;

  const [state, setState] = useState({
    Institution_link: Institution_load,
    Subject_link: Subject_load,
    Topic_link: Topic_load,
    Teacher_link: Teacher_load,
    student_link: Student_load,
    StudyCircle_link: StudyCircle_load
  })

  const tabSelected = (ID) => {
    ID === 0 ? Institution_Icon = InstitutionAct :  Institution_Icon = InstitutionInact ;
    ID === 1 ? Subject_Icon = SubjectAct : Subject_Icon = SubjectInact ;
    ID === 2 ? Topic_Icon = TopicAct : Topic_Icon = TopicInact ;
    ID === 3 ? Teacher_Icon = TeacherAct :  Teacher_Icon = TeacherInact ;
    ID === 4 ? student_Icon = StudentAct :  student_Icon = StudentInact ;
    ID === 5 ? StudyCircle_Icon = StudyCircleAct :  StudyCircle_Icon = StudyCircleInact ;
    
    setState({ ...state, 
      Institution_link: Institution_Icon,
      Subject_link: Subject_Icon,
      Topic_link: Topic_Icon,
      Teacher_link: Teacher_Icon,
      student_link: student_Icon,
      StudyCircle_link: StudyCircle_Icon
    })
  }

  return (
    <div>
      <div className={styles.dashboard_txt}>MASTER DATA</div>
      <Tabs defaultIndex={tabID}>
        <TabList>
          <Tab style={{ fontWeight: 600 }} onClick={() =>tabSelected(0)}>
            <img
              src={state.Institution_link}
              alt="inst"
              height={18}
              width={18}
            />
            <span className={styles.txt}>Institution</span>
          </Tab>
          <Tab onClick={() =>tabSelected(1)}>
            <img
              src={state.Subject_link}
              alt="inst"
              height={18}
              width={18}
            />
            <span className={styles.txt}>Subject</span>
          </Tab>
          <Tab onClick={() =>tabSelected(2)}>
            <img
              src={state.Topic_link}
              alt="inst"
              height={18}
              width={18}
            />
            <span className={styles.txt}>Topic</span>
          </Tab>
          <Tab onClick={() =>tabSelected(3)}>
            <img
              src={ state.Teacher_link }
              alt="inst"
              height={18}
              width={18}
            />
            <span className={styles.txt}>Educator</span>
          </Tab>
          <Tab onClick={() =>tabSelected(4)}>
            <img
              src={state.student_link}
              alt="inst"
              height={18}
              width={18}
            />
            <span className={styles.txt}>Student</span>
          </Tab>
          <Tab onClick={() =>tabSelected(5)}>
            <img
              src={state.StudyCircle_link}
              alt="inst"
              height={18}
              width={18}
            />
            <span className={styles.txt}>Study Circle</span>
          </Tab>
        </TabList>

        <TabPanel>
          <Box>
            <Institution />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <Subject />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box>
            <Topic />
          </Box>
        </TabPanel>
        <TabPanel>
          <Educator />
        </TabPanel>
        <TabPanel>
          <Box>
            <Student />
          </Box>
        </TabPanel>
        <TabPanel>
          <Studycircle />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default MasterData;
