import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
// import { Pagination } from "@material-ui/lab";
// import { fade, makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import styles from "./EducatorStyle.module.css";
import Calander from "../../../components/calander/Calander";
import { BASE_URL } from "../../../config/Api";
// import fakeImg from "../../../assets/images/av-1.png";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";

// let months = [
//   "January",
//   "February",
//   "March",
//   "April",
//   "May",
//   "June",
//   "July",
//   "August",
//   "September",
//   "October",
//   "November",
//   "December",
// ];
let prevDate;
let nextDate;
let totaldaysInMonth;
let teacherId = 0;
let today_date = 0;
// let month_pass;

// const useStyles = makeStyles((theme) => ({
//   search: {
//     position: "relative",
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: fade(theme.palette.common.white, 0.15),
//     "&:hover": {
//       backgroundColor: fade(theme.palette.common.white, 0.25),
//     },
//     marginLeft: 0,
//     width: "100%",
//     [theme.breakpoints.up("sm")]: {
//       marginLeft: theme.spacing(1),
//       width: "auto",
//     },
//   },
//   searchIcon: {
//     padding: theme.spacing(0, 2),
//     height: "100%",
//     position: "absolute",
//     pointerEvents: "none",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   inputRoot: {
//     color: "inherit",
//   },
//   inputInput: {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("sm")]: {
//       width: "12ch",
//       "&:focus": {
//         width: "20ch",
//       },
//     },
//   },
// }));

const Educator = (props) => {
  let date_format;
  if (props.location.dashboardProps) {
    date_format = new Date(props.location.dashboardProps);
    localStorage.setItem("educatorCal", date_format);
  } else
    date_format =
      localStorage.getItem("educatorCal") === null
        ? new Date()
        : new Date(localStorage.getItem("educatorCal"));

  let month = date_format.getMonth();
  let year = date_format.getFullYear();
  const [date, setDate] = useState(date_format);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    t_head: [
      "Sl No.",
      "Educator",
      "Date",
      "Live Class",
      "Test Given",
      "Homework Assigned",
      "Videos & Notes",
      "Doubts Solved",
    ],
    educatorList: [],
    educator_table: [],
  });

  const schoolID = window.localStorage.getItem("school_id");
  const token = window.localStorage.getItem("token");
  const Auth = {
    headers: {
      Authorization: "Bearer "+ token,
    },
  };

  

  const handleChange = async (e, val) => {
    if (val === "day") {
      today_date = e.target.value;
    } else if (val === "teacher") {
      teacherId = e.target.value;
    } else if (val === "calander") {
      let date_val = new Date(e);
      localStorage.setItem("educatorCal", date_val);
      month = date_val.getMonth();
      year = date_val.getFullYear();
      const getDaysInMonth_handlechange = (month, year) => {
        return new Date(year, month, 0).getDate();
      };
      const totaldaysInMonth_handlechange = getDaysInMonth_handlechange(
        month + 1,
        year
      );
     
      prevDate = `${year}-${(month+1)}-01`
      nextDate = year + "-" + (month + 1) + "-" + totaldaysInMonth_handlechange;
      setDate(e);
    }
    setIsLoading(true);
    return await Axios.get(
      `${BASE_URL}/institution/${schoolID}/status/educator?day=${today_date}&cmn_teacher_id=${teacherId}&from_date=${prevDate}&to_date=${nextDate}`,
      Auth
    )
      .then((res) => {
        setIsLoading(false);
        setState({
          ...state,
          educator_table: res.data.data,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.message);
        console.log(err.response, "error");
      });
  };

  const getDaysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };
  totaldaysInMonth = getDaysInMonth(month + 1, year);

  let datesofMonth = [];
  for (let k = 1; k <= totaldaysInMonth; k++) {
    datesofMonth.push(k);
  }
  const Example = ({ type, color }) => (
    <div className="loading_animation">
      <ReactLoading type="bars" color="#DCEEDC" height="10%" width="10%" />
    </div>
  );

  useEffect(() => {
    const fetchData = async () => {
      prevDate = `${year}-${(month+1)}-01`
      nextDate = year + "-" + (month + 1) + "-" + totaldaysInMonth;
      const educator_list = Axios.get(
        `${BASE_URL}/institution/${schoolID}/educator/list`,
        Auth
      );
  
      const tableData = Axios.get(
        `${BASE_URL}/institution/${schoolID}/status/educator?day=${today_date}&cmn_teacher_id=${teacherId}&from_date=${prevDate}&to_date=${nextDate}`,
        Auth
      );
      setIsLoading(true);
      await Axios.all([educator_list, tableData])
        .then(
          Axios.spread((...res) => {
            const educator_table_data = res[1].data;
            const educator_list_data = res[0].data;
            setIsLoading(false);
            setState({
              ...state,
              educator_table: educator_table_data.data,
              educatorList: educator_list_data.data,
            });
          })
        )
        .catch((err) => {
          setIsLoading(false);
          // toast.error(err.response.data.message);
        });
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <Box className={styles.container} component="div">
      <Box className={styles.header} component="div">
        <Box component="div" className={styles.top_bar1}>
          <Box className={styles.live_txt} component="div">
            EDUCATOR
          </Box>
          {/* <Box component="div" className={styles.search_box}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <img
                  src={require("../../../assets/icons/search.png")}
                  alt="search"
                />
              </div>
              <InputBase
                placeholder="Search"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </Box> */}
        </Box>
        <Box component="div" className={styles.top_bar2}>
          <div className={styles.top_bar2_left}>
            <div>
              <label htmlFor="study_circle" className={styles.labels}>
                Educator
              </label>
              <div className={styles.study_cir_opt}>
                <select
                  name=""
                  id=""
                  className={styles.select1}
                  onChange={(e) => handleChange(e, "teacher")}
                  value={teacherId}
                >
                  <option value="0">All Split</option>
                  <option value="-1">All Together</option>
                  {state.educatorList &&
                    state.educatorList.map((el, key) => (
                      <option value={el.cmn_teacher_id} key={key}>
                        {el.cmn_teacher_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="subject" className={styles.labels}>
                Day
              </label>
              <div className={styles.subject_opt}>
                <select
                  name=""
                  id=""
                  className={styles.select2}
                  onChange={(e) => handleChange(e, "day")}
                  value={today_date}
                >
                  <option value="0">All Split</option>
                  <option value="-1">All Together</option>
                  {datesofMonth.map((el, key) => (
                    <option value={el} key={key}>
                      {el}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className={styles.top_bar2_right}>
            <Calander
              selected={date}
              onChange={(e) => handleChange(e, "calander")}
              id="date"
              name="date"
              type="date"
              dateFormat="MMM yyyy"
              showMonthYearPicker
            />
          </div>
        </Box>
      </Box>
      <Box className={styles.body} component="div">
        <div className={styles.table_box}>
          {isLoading === true ? (
            <Example />
          ) : (
            // <table className={styles.content_table}>
            //   <thead>
            //     <tr>
            //       {state.t_head.map((elm, key) => (
            //         <th key={key}>{elm}</th>
            //       ))}
            //     </tr>
            //   </thead>
            //   <tbody>
            //     {state.educator_table.length === 0 ? (
            //       <tr>
            //         <td colSpan={8}>{"No Data Found"}</td>
            //       </tr>
            //     ) : (
            //       state.educator_table &&
            //       state.educator_table.map((elm, key) => (
            //         <tr key={key}>
            //           <td>{key + 1}</td>
            //           <td>
            //             <div className={styles.educator}>
            //               {/* <div>
            //                 <Avatar
            //                   src={fakeImg}
            //                   alt="avatar"
            //                   style={{
            //                     width: "16px",
            //                     height: "16px",
            //                     marginRight: "20px",
            //                   }}
            //                 />
            //               </div> */}
            //               <div>{elm.teacher_name}</div>
            //             </div>
            //           </td>
            //           <td>{elm.activity_date}</td>
            //           <td>{elm.count_live_class}</td>
            //           <td>{elm.count_test_given}</td>
            //           <td>{elm.count_homework_given}</td>
            //           <td>{elm.count_videos_notes}</td>
            //           <td>{elm.count_doubts_solved}</td>
            //         </tr>
            //       ))
            //     )}
            //   </tbody>
            // </table>

            <div className={styles.schoolList_Table}>
              <div className={styles.schoolList_Table_head}>
                {state.t_head.map((elm, key) => (
                  <div key={key} className={styles.schoolList_Table_head_data}>
                    {elm}
                  </div>
                ))}
              </div>
              {
                <div className={styles.schoolList_Table_body}>
                  {state.educator_table.length === 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        background:'#F5F7FD',
                        borderRadius:'10px',
                        height:'40px'
                      }}
                    >
                      No Data Found<span role='img' aria-label='jsx-a11y/accessible-emoji'>😔</span>
                    </div>
                  ) : (
                    state.educator_table.map((elm, key) => (
                      <div
                        key={key}
                        className={styles.schoolList_Table_body_row}
                        style={{ cursor: "pointer" }}
                      >
                        <div className={styles.schoolList_Table_body_row_td2}>
                          {key + 1}
                        </div>
                        <div className={styles.schoolList_Table_body_row_td3}>
                          {/* <div>
                                <Avatar
                                  src={fakeImg}
                                  alt="avatar"
                                  style={{
                                    width: "16px",
                                    height: "16px",
                                    marginRight: "20px",
                                  }}
                                />
                              </div> */}
                          <div>{elm.teacher_name}</div>
                        </div>
                        <div className={styles.schoolList_Table_body_row_td3}>
                          {elm.activity_date}
                        </div>
                        <div className={styles.schoolList_Table_body_row_td3}>
                          {elm.count_live_class}
                        </div>
                        <div className={styles.schoolList_Table_body_row_td3}>
                          {elm.count_test_given}
                        </div>
                        <div className={styles.schoolList_Table_body_row_td3}>
                          {elm.count_homework_given}
                        </div>
                        <div className={styles.schoolList_Table_body_row_td3}>
                          {elm.count_videos_notes}
                        </div>
                        <div className={styles.schoolList_Table_body_row_td3}>
                          {elm.count_doubts_solved}
                        </div>
                      </div>
                    ))
                  )}
                </div>
              }
            </div>
          )}
        </div>
      </Box>
      {/* <Box className={styles.footer} component="div">
        <Pagination count={10} />
      </Box> */}
    </Box>
  );
};

export default Educator;
