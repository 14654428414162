import React, { Component } from "react";
import { List, ListItem, Collapse, Drawer, Icon } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { NavLink, Link } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import styless from "./SidebarStyle.module.css";
import DialogBox from "../dialog/DialogBox";
import Axios from "axios";
import { BASE_URL } from "../../config/Api";
import imgSchool from "../../assets/images/add.PNG";

let showFooter = true;

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      logo: "",
      address: "",
      schoolname: "",
      UserName: "",
      UserEmail: "",
      schoolID: window.localStorage.getItem("school_id"),
      token: window.localStorage.getItem("token"),
    };
    this.onClickOpen = this.onClickOpen.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }
  // this method sets the current state of a menu item i.e whether it is in expanded or collapsed or a collapsed state
  handleClick(item) {
    this.setState((prevState) => ({ [item]: !prevState[item] }));
  }
  onClickOpen = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };
  handleClose = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  fetchData = async () => {
    try {
      this.state.schoolID = window.localStorage.getItem("school_id");
      const T_data = await (
        await Axios.get(`${BASE_URL}/institution/${this.state.schoolID}`, {
          headers: {
            Authorization: "Bearer " + this.state.token,
          },
        })
      ).data;
      this.setState({
        logo:
          T_data.data.cmn_school_emblem_file_path !== null
            ? T_data.data.cmn_school_emblem_file_path
            : imgSchool,
        address: T_data.data.cmn_school_display_info,
        schoolname: T_data.data.cmn_school_name,
      });
    } catch (err) {
      console.log(err, "error from get req.");
    }
  };

  // if the menu item doesn't have any child, this method simply returns a clickable menu item that redirects to any location and if there is no child this method uses recursion to go until the last level of children and then returns the item by the first condition.
  handler(children) {
    const { state } = this;
    return children.map((subOption) => {
      // console.log(this)
      if (!subOption.children) {
        return (
          <div key={subOption.name}>
            <ListItem button key={subOption.name} className={styless.listItems}>
              <NavLink
                to={subOption.path}
                className={styless.link}
                activeClassName="active"
              >
                <div className={styless.links}>
                  <div>
                    <Icon>
                      <img
                        src={subOption.icon === null ? null : subOption.icon}
                        alt=""
                      />
                    </Icon>
                  </div>
                  <div className={styless.listItem}>{subOption.name}</div>
                </div>
              </NavLink>
            </ListItem>
          </div>
        );
      }
      return (
        <div key={subOption.name}>
          <ListItem
            button
            onClick={() => this.handleClick(subOption.name)}
            style={{ display: "flex" }}
          >
            <Icon style={{ display: "flex", marginLeft: "17px" }}>
              <img src={subOption.icon} alt="icon" />
            </Icon>
            <div className={styless.listItem}>{subOption.name}</div>
            {state[subOption.name] ? (
              <ExpandLess style={{ marginLeft: "70px" }} />
            ) : (
              <ExpandMore style={{ marginLeft: "70px" }} />
            )}
          </ListItem>
          <Collapse in={state[subOption.name]} timeout="auto" unmountOnExit>
            {this.handler(subOption.children)}
          </Collapse>
        </div>
      );
    });
  }

  resetPassword = () => {
    return (window.location.pathname = "/reset-password");
  };
  logout = () => {
    return window.localStorage.clear();
  };

  async UNSAFE_componentWillMount() {
    return this.fetchData();
  }

  render() {
    const { routes, handleDrawerToggle } = this.props;
    return (
      <div>
        <Drawer
          variant="persistent"
          anchor="left"
          open
          onClose={handleDrawerToggle}
        >
          <div className={styless.list}>
            <List>
              <ListItem key="menuHeading">
                <div className={styless.top_box} onClick={this.onClickOpen}>
                  <div
                    style={{
                      marginBottom: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {this.state.logo !== "/static/media/add.c7368eeb.PNG" ? (
                      <img
                        src={
                          this.state.logo && this.state.logo
                            ? this.state.logo
                            : imgSchool
                        }
                        alt="bitmap"
                        style={{
                          display: "flex",
                          height: "80px",
                          width: "80px",
                          borderRadius: "10px",
                        }}
                      />
                    ) : null}
                  </div>
                  <div className={styless.box_item}>
                    <div className={styless.box_item_txt1}>
                      {this.state.schoolname}
                    </div>
                    <div className={styless.box_item_txt2}>
                      {this.state.address}
                    </div>
                  </div>
                </div>
              </ListItem>
              {this.handler(routes.data)}
            </List>
          </div>
          { showFooter ?  <div className={styless.footer}>
            <img
              src={require("../../assets/images/stucle-logo.png")}
              alt="footer"
              style={{ height: "20px", width: "88px" }}
            />
          </div> : null}
        </Drawer>
        {this.state.open ? (
          <DialogBox
            open={this.state.open}
            close={this.handleClose}
            style={{ padding: "0px" }}
          >
            <div className={styless.dialog}>
              <div className={styless.close}>
                <div>
                  {" "}
                  <img
                    src={require("../../assets/icons/close.png")}
                    alt="close"
                    style={{ height: "16px", width: "16px", cursor: "pointer" }}
                    onClick={this.handleClose}
                  />
                </div>
              </div>
              <div className={styless.boxex}>
                <div className={styless.box1}>
                  <div className={styless.img_file}>
                    {this.state.logo !== "/static/media/add.c7368eeb.PNG" ? (
                      <img
                        src={
                          this.state.logo && this.state.logo
                            ? this.state.logo
                            : imgSchool
                        }
                        alt="bitmap"
                        style={{
                          display: "flex",
                          height: "80px",
                          width: "80px",
                          borderRadius: "10px",
                        }}
                      />
                    ) : null}
                  </div>
                  <div className={styless.txt_file}>
                    <div className={styless.acharya_vidya_kula}>
                      {this.state.schoolname}
                    </div>
                    <div className={styless.acharyashrama_tem}>
                      {this.state.address}
                    </div>
                  </div>
                </div>
                <div className={styless.box2}>
                  <div className={styless.role}>Administrator</div>
                  <div className={styless.userName}>
                    {window.localStorage.getItem("cmn_user_full_name")}
                  </div>
                  <div className={styless.email}>
                    {window.localStorage.getItem("cmn_user_email")}
                  </div>
                  <div></div>
                </div>
                <div className={styless.btns}>
                  <div className={styless.reset} onClick={this.resetPassword}>
                    Reset Password
                  </div>
                  <Link to="/">
                    <div className={styless.logout} onClick={this.logout}>
                      <Icon>
                        <ExitToAppIcon />
                      </Icon>
                      Logout
                    </div>
                  </Link>
                </div>
                <div className={styless.an_email_to_your_reg}>
                  An email to your registered email id has been sent to reset
                  password. Thank You
                </div>
              </div>
            </div>
          </DialogBox>
        ) : null}
      </div>
    );
  }
}
export default SideBar;
