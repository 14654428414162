import React, { useState, useEffect} from "react";
import { Box, Icon } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { toast, ToastContainer } from "react-toastify";
import Axios from "axios";
import * as Yup from "yup";
import { saveAs } from "file-saver";
import styles from "./SubjectStyle.module.css";
import { ModalComponent } from "../../../../components/index";
import { Dialogbox } from "../../../../components/index";
import { BASE_URL } from "../../../../config/Api";
import * as ReactBootStrap from "react-bootstrap";
import ReactLoading from "react-loading";

var boardvalue = "";
var classvalue = "";

const initialValues = {
  board: "",
  class: "",
  subject: "",
  subject_name: "",
};

const validationBox = Yup.object({
  board: Yup.string(),
  class: Yup.string(),
  subject: Yup.string(),
  subject_name: Yup.string(),
});

const Subject = () => {
  const [editVal, setEditVal] = useState(false);
  const [errorUpload, setErrorUpload] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState([]);
  const [state, setState] = useState({
    t_head: ["Subject", "Board", "Grade", "Subject(Stucle)", ""],
    t_row: [],
    option_board: [],
    option_class: [],
    drop_board: [],
    drop_class: [],
    drop_sub: [],
    board: "",
    subject: "",
    class: "",
    subject_name: "",
    cmn_subjectID: "",
    cmn_school_syllabusID: "",
    qb_classID: "",
    qb_subjectID: "",
  });

  let schoolId = window.localStorage.getItem("school_id");
  window.localStorage.setItem("tabIndex", 1);
  const token = window.localStorage.getItem("token");
  const Auth = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const fetchResult = async() => {
    setIsLoading(true);
    try {
      const getSubBoard = await (
        await Axios.get(
          `${BASE_URL}/institution/${schoolId}/subject/board`,
          Auth
        )
      ).data;

      const getSubClass = await (
        await Axios.get(
          `${BASE_URL}/institution/${schoolId}/subject/class`,
          Auth
        )
      ).data;

      const getBoard = await (
        await Axios.get(`${BASE_URL}/institution/${schoolId}/board`, Auth)
      ).data;

      const getSubject = await (await Axios.get(`${BASE_URL}/subject`, Auth))
        .data;

      const getTableData = await (
        await Axios.get(
          `${BASE_URL}/institution/${schoolId}/subject/search?board_id=${state.board}&class_id=${state.class}`,
          Auth
        )
      ).data;

      setState({
        ...state,
        t_row: getTableData.data,
        option_board: getSubBoard.data,
        option_class: getSubClass.data,
        drop_board: getBoard.data,
        drop_sub: getSubject.data,
        // drop_class: getClass.data,
      });
      setIsLoading(false);
    } catch (err) {
      console.log(err, "error");
    }
  }

  useEffect(() => {
    fetchResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changedBoard = async (e, selected) => {
    try {
      if (selected === "selectedboard") {
        boardvalue = e.target.value;
        if (boardvalue === "Board") {
          boardvalue = "";
        }
      } else if (selected === "selectedclass") {
        classvalue = e.target.value;
        if (classvalue === "Grade") {
          classvalue = "";
        }
      }

      const getTableData = await (
        await Axios.get(
          `${BASE_URL}/institution/${schoolId}/subject/search?board_id=${boardvalue}&class_id=${classvalue}`,
          Auth
        )
      ).data;
      setIsLoading(false);
      setState({
        ...state,
        t_row: getTableData.data,
      });
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleClose = (value) => {
    setState({ ...state, showHide: false });
  };
  const handleClickOpen = () => {
    setState({ ...state, showHide: true });
    setEditVal(false);
  };

  //use on handling options
  const selectedBoard = () => {
    let o = state.drop_board.findIndex(
      (e) => e.cmn_syllabus_name === state.board
    );
    return o === -1 ? 0 : state.drop_board[o].qb_syllabus_id;
  };

  const selectedClass = () => {
    let o = state.drop_class.findIndex((e) => e.qb_class_number === state.class);
    return o === -1 ? 0 : state.drop_class[o].qb_class_id;
  };

  const selectedSubject = () => {
    let o = state.drop_sub.findIndex((e) => e.name === state.subject);
    return o === -1 ? 0 : state.drop_sub[o].id;
  };

  const handleSave = (values, submitProps, formik) => {
    submitProps.setSubmitting(false);
    submitProps.resetForm();
    if (editVal) {
      Axios.put(
        `${BASE_URL}/subject/${state.cmn_subjectID}`,
        {
          cmn_school_syllabus_id: state.cmn_school_syllabusID,
          name: values.subject_name,
          qb_class_id: state.qb_classID,
          qb_subject_id: state.qb_subjectID,
        },
        Auth
      )
        .then((res) => {
          toast.success("data edited Successfully!");
          if (res.data.status === true) {
            setTimeout(() => {
              window.location.reload(false);
            }, 3000);
          } else {
            toast.error("error while edit");
          }
        })
        .catch((err) => console.log(err.response, "error"));
    } else {
      Axios.post(
        `${BASE_URL}/subject`,
        {
          cmn_school_syllabus_id: state.cmn_school_syllabusID,
          name: values.subject_name,
          qb_class_id: state.qb_classID,
          qb_subject_id: state.qb_subjectID,
        },
        Auth
      )
        .then((res) => {
          toast.success("data created successfully!");
          if (res.data.status === true) {
            setTimeout(() => {
              window.location.reload(false);
            }, 3000);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  const handleEdit = (
    cmn_subject_id,
    cmn_subject_name,
    cmn_syllabus_name,
    qb_class_number,
    qb_subject_name,
    cmn_school_syllabus_id,
    qb_class_id,
    qb_subject_id
  ) => {
    setEditVal(true);
    setState({
      ...state,
      cmn_subjectID: cmn_subject_id,
      board: cmn_syllabus_name,
      subject: qb_subject_name,
      class: qb_class_number,
      subject_name: cmn_subject_name,
      cmn_school_syllabusID: cmn_school_syllabus_id,
      qb_classID: qb_class_id,
      qb_subjectID: qb_subject_id,
      showHide: true,
    });
    selectedBoard();
    selectedClass();
    selectedSubject();
  };

  const handleDelete = (e) => {
    Axios.delete(`${BASE_URL}/subject/${state.cmn_subjectID}`, Auth)
      .then((res) => {
        toast.error("data deleted Successfully!");
        if (res.data.status === true) {
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        }
      })
      .catch((err) => console.log(err, "error"));
  };

  const handleBoardChange = async (e) => {
    let board = e.target.value;
    const getClass = await (
      await Axios.get(
        `${BASE_URL}/institution/${schoolId}/board/${board}/class`,
        Auth
      )
    ).data;
    setState({
      ...state,
      board: board,
      drop_class: getClass.data,
      cmn_school_syllabusID: board,
    });
  };

  const downloadFile = async () => {
    await Axios.get(`${BASE_URL}/institution/${schoolId}/subject/export`, {
      ...Auth,
      responseType: "arraybuffer",
    }).then((response) => {
      var blob = new Blob([response.data], {
        type: "application/octet-stream",
      });
      saveAs(blob, "SubjectDetails.xlsx");
      toast.success("file downloaded");
    });
  };

  const uploadFile = async (e) => {
    let formdata = new FormData();
    let file = e.target.files[0];
    setLoading(true);

    formdata.append("filetoupload", file);
    Axios({
      url: `${BASE_URL}/institution/${schoolId}/subject/import`,
      mode: "no-cors",
      method: "POST",
      headers: {
        ...Auth.headers,
        "Content-Type": "multipart/form-data",
        Accept: "*",
        type: "formData",
        "Access-Control-Allow-Headers": "*",
      },
      data: formdata,
    })
      .then((res) => {
        setLoading(false);
        toast.success("File Uploaded Successfully");
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
      })
      .catch((err) => {
        setLoading(false);
        if (err) {
          setErrorUpload(true);
          toast.error(`oops! error while upload file  check in the modal..`);
          setMsg(err.response.data.message);
          setErrMsg(err.response.data.data);
        }
      });
  };

  const handleClosePopup = () => setErrorUpload(false);
  const Example = ({ type, color }) => (
    <div className="loading_animation">
      <ReactLoading type="bars" color="#DCEEDC" height="10%" width="10%" />
    </div>
  );

  const ErrModal = () => {
    let errHead = ["Subject Id", "Subject Name", "Error"];
    return (
      <Dialogbox Dialogbox open={errorUpload} handleClose={handleClosePopup}>
        <div className={styles.errMod}>
          <div className={styles.Msg}>
            <div>{`${msg}`}</div>
            <div className={styles.closeBtn} onClick={handleClosePopup}>
              <img
                src={require("../../../../assets/icons/close.png")}
                alt="closeBtn"
                style={{ height: "16px", width: "16px", cursor: "pointer" }}
              />
            </div>
          </div>
          <table className={styles.content_table}>
            <thead>
              <tr>
                {errHead.map((elm, key) => (
                  <th key={key}>{elm}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {errMsg.map((elm, key) => {
                return (
                  <tr key={key}>
                    <td>{elm.stucle_subject_id}</td>
                    <td>{elm.stucle_subject_name}</td>
                    <td>{elm.result ? elm.result : elm.message}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dialogbox>
    );
  };

  return (
    <div className={styles.container}>
      <Box className={styles.top_box} component="div">
        <Box component="div" className={styles.top_box_l}>
          <Box
            component="select"
            className={styles.options}
            onChange={(e) => {
              changedBoard(e, "selectedboard");
            }}
          >
            <option>{"Board"}</option>
            {state.option_board.map((el, key) => (
              <option value={el.cmn_school_syllabus_id} key={key}>
                {el.cmn_syllabus_name}
              </option>
            ))}
          </Box>
          <Box
            component="select"
            className={styles.options}
            onChange={(e) => {
              changedBoard(e, "selectedclass");
            }}
          >
            <option>{"Grade"}</option>
            {state.option_class
              .sort((a, b) => a.qb_class_number - b.qb_class_number)
              .map((el, key) => (
                <option value={el.qb_class_id} key={key}>
                  {el.qb_class_number}
                </option>
              ))}
          </Box>
        </Box>
        <Box component="div" className={styles.buttons}>
          <Box
            component="div"
            className={styles.buttons_add}
            onClick={handleClickOpen}
          >
            Add
          </Box>
          <Box
            component="div"
            className={styles.buttons_format}
            onClick={downloadFile}
          >
            <img
              src={require("../../../../assets/icons/down.png")}
              alt="down"
              style={{ paddingRight: "10px" }}
            />
            Download Subject List
          </Box>
          <Box component="div" className={styles.buttons_data}>
            <Formik>
              {(formik) => {
                return (
                  <div>
                    <Form>
                      <Field
                        type="file"
                        id="filetoupload"
                        name="filetoupload"
                        onChange={(e) => {
                          uploadFile(e);
                          e.target.value = null;
                        }}
                        style={{ display: "none" }}
                      />
                      <label
                        htmlFor="filetoupload"
                        style={{
                          width: "120px",
                          height: "32px",
                          marginBottom: "0px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                          fontWeight: "bold",
                          fontSize: "10px",
                          color: "rgb(155, 139, 139)",
                          cursor: "pointer",
                          textAlign: "center",
                          padding: "11px",
                        }}
                      >
                        <img
                          src={require("../../../../assets/icons/up.png")}
                          alt="up"
                          style={{ paddingRight: "10px" }}
                        />
                        Upload Subject List
                      </label>
                    </Form>
                  </div>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </Box>
      {loading ? (
        <div className={styles.loader}>
          <ReactBootStrap.Spinner animation="border" variant="success" /> &nbsp;
          <span className={styles.loaderTxt}>Loading...</span>
        </div>
      ) : null}
      <Box component="div" className={styles.tableBox}>
        <div className={styles.table_box}>
          {isLoading === true ? (
            <Example />
          ) : (
            <div className={styles.schoolList_Table}>
              <div className={styles.schoolList_Table_head}>
                {state.t_head.map((elm, key) => (
                  <div key={key} className={styles.schoolList_Table_head_data}>
                    {elm}
                  </div>
                ))}
              </div>
              {
                <div className={styles.schoolList_Table_body}>
                  {state.t_row.length === 0
                    ? <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                    }}
                  >
                    No Data Found<span role='img' aria-label='jsx-a11y/accessible-emoji'>😔</span>
                  </div>
                    : state.t_row.map((elm, key) => (
                        <div
                          key={key}
                          className={styles.schoolList_Table_body_row}
                        >
                          <div className={styles.schoolList_Table_body_row_td2}>
                            {elm.cmn_subject_name}
                          </div>
                          <div className={styles.schoolList_Table_body_row_td3}>
                            {elm.cmn_syllabus_name}
                          </div>
                          <div className={styles.schoolList_Table_body_row_td3}>
                            {elm.qb_class_number}
                          </div>
                          <div className={styles.schoolList_Table_body_row_td3}>
                            {elm.qb_subject_name}
                          </div>
                          <div
                            className={styles.schoolList_Table_body_row_td3}
                            onClick={() => {
                              handleEdit(
                                elm.cmn_subject_id,
                                elm.cmn_subject_name,
                                elm.cmn_syllabus_name,
                                elm.qb_class_number,
                                elm.qb_subject_name,
                                elm.cmn_school_syllabus_id,
                                elm.qb_class_id,
                                elm.qb_subject_id
                              );
                            }}
                          >
                            <Icon>
                              <img
                                src={require("../../../../assets/icons/edit.png")}
                                alt="edit"
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  cursor: "pointer",
                                }}
                              />
                            </Icon>
                          </div>
                        </div>
                      ))}
                </div>
              }
            </div>
          )}
        </div>
      </Box>

      <ModalComponent show={state.showHide} onClick={handleClose}>
        <Box
          className={styles.main_box}
          component="div"
          style={{ marginTop: "-20px" }}
        >
          <Formik
            initialValues={initialValues || state}
            validationSchema={validationBox}
            onSubmit={handleSave}
            enableReinitialize
          >
            {(formik) => {
              return (
                <Box component="div" style={{ width: "100%", height: "auto" }}>
                  <Form className={styles.modal_Form}>
                    <label htmlFor="board">Board</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        as="select"
                        name="board"
                        onChange={handleBoardChange}
                        value={
                          editVal ? state.cmn_school_syllabusID : undefined
                        }
                        className={styles.d_options}
                        // onChange={(e)=>setState({...state,board:e.target.value})}
                      >
                        <option>{"Board"}</option>
                        {state.drop_board.map((elm, key) => {
                          return (
                            <option
                              value={elm.cmn_school_syllabus_id}
                              key={key}
                            >
                              {elm.cmn_syllabus_name}
                            </option>
                          );
                        })}
                      </Field>
                    </Box>
                    <label htmlFor="board">Grade</label>
                    <Box className={styles.main_box_2} component="div">
                      <Field
                        as="select"
                        name="class"
                        className={styles.d_options}
                        value={editVal ? state.qb_classID : undefined}
                        onChange={(e) =>
                          setState({ ...state, qb_classID: e.target.value })
                        }
                      >
                        <option>{editVal ? state.class : "Grade"}</option>
                        {state.drop_class.map((elm, key) => {
                          return (
                            <option value={elm.qb_class_id} key={key}>
                              {elm.qb_class_number}
                            </option>
                          );
                        })}
                      </Field>
                    </Box>
                    <label htmlFor="board">Subject (Stucle) to be linked</label>
                    <Box className={styles.main_box_2} component="div">
                      <Field
                        as="select"
                        name="subject"
                        className={styles.d_options}
                        value={editVal ? selectedSubject() : undefined}
                        onChange={(e) =>
                          setState({ ...state, qb_subjectID: e.target.value })
                        }
                        // onChange={(e)=>formik.setFieldValue('subject',e.target.value)}
                      >
                        <option>{"Subject"}</option>
                        {state.drop_sub.map((elm, key) => {
                          return (
                            <option value={elm.id} key={key}>
                              {elm.name}
                            </option>
                          );
                        })}
                      </Field>
                    </Box>
                    <label htmlFor="board">Subject Display Name</label>
                    <Box className={styles.main_box_3} component="div">
                      <Field
                        name="subject_name"
                        placeholder="Subject display name"
                        className={styles.B_Field}
                        id="subject_name"
                        value={editVal ? state.subject_name : undefined}
                        onChange={(e) => {
                          setState({
                            ...state,
                            subject_name: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Box className={styles.buttonss}>
                      {editVal ? (
                        <Box
                          className={styles.deleteBtn}
                          onClick={handleDelete}
                        >
                          <img
                            src={require("../../../../assets/icons/DeleteBox.png")}
                            alt="deletebox"
                            style={{ cursor: "pointer" }}
                          />
                        </Box>
                      ) : null}
                      <button
                        className={styles.cancelBtn}
                        onClick={handleClose}
                        type="reset"
                      >
                        Cancel
                      </button>
                      <button
                        className={styles.saveBtn}
                        type="submit"
                        onClick={(e) => {
                          let addedit_value = document.getElementById(
                            "subject_name"
                          ).value;
                          formik.setFieldValue("subject_name", addedit_value);
                        }}
                        disabled={!formik.isValid || formik.isSubmitting}
                      >
                        Save
                      </button>
                    </Box>
                  </Form>
                </Box>
              );
            }}
          </Formik>
        </Box>
      </ModalComponent>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
      {errorUpload ? <ErrModal /> : null}
    </div>
  );
};

export default Subject;
