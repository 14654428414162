import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Icon, Box } from "@material-ui/core";
import * as Yup from "yup";
import Axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import TextError from "../../../../variables/errors/Text_error";
import { BASE_URL } from "../../../../config/Api";
import add_emb from "../../../../assets/images/add_emblem.PNG";
import addImg from "../../../../assets/images/add.PNG";
import styles from "./Institution.module.css";
import { ModalComponent } from "../../../../components/index";
import s3 from "../../../../config/DigitalOcean";
import configuration from "../../../../config/config";

const initialValues = {
  institute_name: "",
  display_info: "",
  emblem: [],
  images: [],
};
const initialBox = {
  board_name: "",
  board: "",
  medium: "",
};

var emb_file_path;
var imgpath1;
var imgpath2;
var imgpath3;
var imgpath4;
var imgpath5;

const validationSchema = Yup.object({
  add_emblem: Yup.string(),
  institute_name: Yup.string(),
  display_info: Yup.string(),
});

const validationBox = Yup.object({
  board_name: Yup.string(),
  medium: Yup.string(),
  board: Yup.string(),
});

const Institution = () => {
  const [editVal, setEditVal] = useState(false);
  const [state, setState] = useState({
    t_head: ["Board", "Board (stucle)", "Medium Of Instruction", ""],
    institute_name: "",
    display_info: "",
    emblem: "",
    images: [],
    apiData: [],
    optBD: [],
    optMed: [],
    T_data: [],
    form_values: [],
    showHide: false,
    value: "any value",
    cmn_syllabus_name: "",
    board: "",
    medium: "",
    cmn_school_syllabus_id: "",
    file: "",
    imagePreview: add_emb,
    imgPrev1: addImg,
    imgPrev2: addImg,
    imgPrev3: addImg,
    imgPrev4: addImg,
    imgPrev5: addImg,
  });

  const school_id = window.localStorage.getItem("school_id");
  const token = window.localStorage.getItem("token");
  window.localStorage.setItem("tabIndex", 0);
  const Auth = {
    headers: {
      Authorization: "Bearer " +token,
    },
  };

  useEffect(() => {
    const fetchAPI = async () => {
      try {
        const T_data = await (
          await Axios.get(`${BASE_URL}/institution/${school_id}`, Auth)
        ).data;
        const optBD = await (await Axios.get(`${BASE_URL}/board`, Auth)).data;
        const optMed = await (await Axios.get(`${BASE_URL}/medium`, Auth)).data;

        emb_file_path = T_data.data.cmn_school_emblem_file_path;
        imgpath1 = T_data.data.cmn_school_image_1_file_path;
        imgpath2 = T_data.data.cmn_school_image_2_file_path;
        imgpath3 = T_data.data.cmn_school_image_3_file_path;
        imgpath4 = T_data.data.cmn_school_image_4_file_path;
        imgpath5 = T_data.data.cmn_school_image_5_file_path;

        setState({
          ...state,
          optBD: optBD.data,
          optMed: optMed.data,
          T_data: T_data.data,
          institute_name: T_data.data.cmn_school_name,
          imagePreview:
            T_data.data.cmn_school_emblem_file_path !== null
              ? T_data.data.cmn_school_emblem_file_path
              : add_emb,
          imgPrev1:
            T_data.data.cmn_school_image_1_file_path !== null
              ? T_data.data.cmn_school_image_1_file_path
              : addImg,
          imgPrev2:
            T_data.data.cmn_school_image_2_file_path !== null
              ? T_data.data.cmn_school_image_2_file_path
              : addImg,
          imgPrev3:
            T_data.data.cmn_school_image_3_file_path !== null
              ? T_data.data.cmn_school_image_3_file_path
              : addImg,
          imgPrev4:
            T_data.data.cmn_school_image_4_file_path !== null
              ? T_data.data.cmn_school_image_4_file_path
              : addImg,
          imgPrev5:
            T_data.data.cmn_school_image_5_file_path !== null
              ? T_data.data.cmn_school_image_5_file_path
              : addImg,
          display_info: T_data.data.cmn_school_display_info,
        });
        window.localStorage.setItem("logo", emb_file_path);
      } catch (err) {
        console.log(err.response, "error");
      }
    };
    fetchAPI();
    // eslint-disable-next-line
  }, [])

  const handleClose = (value) => {
    setState({ ...state, showHide: false });
  };
  const handleClickOpen = (e) => {
    setState({ ...state, showHide: true });
    setEditVal(false);
  };
  const selectedBoard = () => {
    let o = state.optBD.findIndex((e) => e.qb_syllabus_name === state.board);
    return o === -1 ? 0 : state.optBD[o].qb_syllabus_id;
  };

  const selectedMedium = () => {
    let o = state.optMed.findIndex((e) => e.qb_language_name === state.medium);
    return o === -1 ? 0 : state.optMed[o].qb_language_id;
  };

  const handleSave = (values, submitProps) => {
    submitProps.setSubmitting(false);
    submitProps.resetForm();
    if (editVal) {
      Axios.put(
        `${BASE_URL}/institution/${school_id}/board/${state.cmn_school_syllabus_id}`,
        {
          cmn_syllabus_name: values.board_name,
        },
        Auth
      )
        .then((res) => {
          toast.success("data edited Successfully!");
          if (res.data.status === true) {
            setTimeout(() => {
              window.location.reload(false);
            }, 3000);
          }
        })
        .catch((err) => console.log(err, "error"));
    } else {
      Axios.post(
        `${BASE_URL}/institution/${school_id}/board`,
        {
          qb_syllabus_id: values.board,
          cmn_syllabus_name: values.board_name,
          qb_language_id: values.medium,
          cmn_school_id: school_id,
        },
        Auth
      )
        .then((res) => {
          toast.success("new board created successfully");
          if (res.data.status) {
            setTimeout(() => {
              window.location.reload(false);
            }, 3000);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  const handleEdit = (
    qb_syllabus_name,
    qb_language_name,
    cmn_school_name,
    cmn_school_syllabus_id
  ) => {
    setEditVal(true);
    setState({
      ...state,
      board: qb_syllabus_name,
      medium: cmn_school_name,
      cmn_syllabus_name: qb_language_name,
      cmn_school_syllabus_id: cmn_school_syllabus_id,
      showHide: true,
    });
    selectedBoard();
    selectedMedium();
  };

  const handleDelete = (e) => {
    Axios.delete(
      `${BASE_URL}/institution/${school_id}/board/${state.cmn_school_syllabus_id}`,
      Auth
    )
      .then((res) => {
        if (res.data.status === true) {
          toast.error(`${res.statusText},data deleted successfully`);
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        }
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  var FilePath;

  const handleChange = (e, imgPath) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (imgPath === "add_emblem") {
      reader.onloadend = () => {
        setState({
          ...state,
          file: file,
          imagePreview: reader.result,
        });
      };
    } else if (imgPath === "imagepath1") {
      reader.onloadend = () => {
        setState({
          ...state,
          file: file,
          imgPrev1: reader.result,
        });
      };
    } else if (imgPath === "imagepath2") {
      reader.onloadend = () => {
        setState({
          ...state,
          file: file,
          imgPrev2: reader.result,
        });
      };
    } else if (imgPath === "imagepath3") {
      reader.onloadend = () => {
        setState({
          ...state,
          file: file,
          imgPrev3: reader.result,
        });
      };
    } else if (imgPath === "imagepath4") {
      reader.onloadend = () => {
        setState({
          ...state,
          file: file,
          imgPrev4: reader.result,
        });
      };
    } else if (imgPath === "imagepath5") {
      reader.onloadend = () => {
        setState({
          ...state,
          file: file,
          imgPrev5: reader.result,
        });
      };
    }
    reader.readAsDataURL(file);
    if (e.target.files && e.target.files[0]) {
      const blob = e.target.files[0];
      let fileType = e.target.files[0].type;
      let ext = fileType.substr(fileType.lastIndexOf("/") + 1);

      if (imgPath === "add_emblem") {
        FilePath = "folder/emblem"+ school_id + "." + ext;
        emb_file_path =
          "https://stucle-dev.sgp1.digitaloceanspaces.com/" + FilePath;
      } else if (imgPath === "imagepath1") {
        FilePath = "images/image1_"+ school_id + "." + ext;
        imgpath1 = "https://stucle-dev.sgp1.digitaloceanspaces.com/" + FilePath;
      } else if (imgPath === "imagepath2") {
        FilePath = "images/image2_"+ school_id + "." + ext;
        imgpath2 = "https://stucle-dev.sgp1.digitaloceanspaces.com/" + FilePath;
      } else if (imgPath === "imagepath3") {
        FilePath = "images/image3_"+ school_id + "." + ext;
        imgpath3 = "https://stucle-dev.sgp1.digitaloceanspaces.com/" + FilePath;
      } else if (imgPath === "imagepath4") {
        FilePath = "images/image4_"+ school_id + "." + ext;
        imgpath4 = "https://stucle-dev.sgp1.digitaloceanspaces.com/" + FilePath;
      } else if (imgPath === "imagepath5") {
        FilePath = "images/image5_"+ school_id + "." + ext;
        imgpath5 = "https://stucle-dev.sgp1.digitaloceanspaces.com/" + FilePath;
      }

      const params = {
        Body: blob,
        Bucket: `${configuration.bucketName}`,
        Key: FilePath,
      };
      s3.putObject(params)
        .on("build", (request) => {
          request.httpRequest.headers.Host = `${configuration.digitalOceanSpaces}`;
          request.httpRequest.headers["Content-Length"] = blob.size;
          request.httpRequest.headers["Content-Type"] = blob.type;
          request.httpRequest.headers["x-amz-acl"] = "public-read";
        })
        .send((err) => {
          if (err) console.log(err, "Img error");
          else {
            const imageUrl = `${configuration.digitalOceanSpaces}` + blob.name;
            console.log(imageUrl)
          }
        });
    }
  };

  const handleFormSave = () => {
    Axios.put(
      `${BASE_URL}/institution/${school_id}`,
      {
        imagepath1: imgpath1,
        imagepath2: imgpath2,
        imagepath3: imgpath3,
        imagepath4: imgpath4,
        imagepath5: imgpath5,
        emblem_file_path: emb_file_path,
        display_info: state.display_info,
        name: state.institute_name,
        id: school_id,
      },
      Auth
    )
      .then((res) => {
        toast.success("data edited Successfully!");
        if (res.data.status === true) {
          setState({
            ...state,
            imagePreview: emb_file_path,
            imgPrev1: imgpath1,
            imgPrev2: imgpath2,
            imgPrev3: imgpath3,
            imgPrev4: imgpath4,
            imgPrev5: imgpath5,
          });
          window.localStorage.setItem("school_logo", state.imagePreview);
          window.localStorage.setItem("school_address", state.display_info);
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        }
      })
      .catch((err) => console.log(err, "error"));
  };

  return (
    <Box component="div" className={styles.main_box}>
      <Box className={styles.top_box}>
        <div className={styles.top_box_body}>
          <div className={styles.top_box_format}>
            <img
              src={require("../../../../assets/icons/down.png")}
              alt="down"
              style={{ paddingRight: "10px" }}
            />
            Download Institute
          </div>
          <div className={styles.top_box_data}>
            <img
              src={require("../../../../assets/icons/up.png")}
              alt="down"
              style={{ paddingRight: "10px" }}
            />
            Upload Institute
          </div>
        </div>
      </Box>
      <Box className={styles.form_box}>
        <Formik
          initialValues={state || initialValues}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {(formik) => {
            return (
              <Box className={styles.form_container} component="div">
                <Form className={styles.form_box}>
                  <Box className={styles.form_box_fields} component="div">
                    <Box
                      component="div"
                      className={styles.form_box_fields_l}
                      id="get_embed"
                    >
                      <Field
                        type="file"
                        id="add_emblem"
                        name="add_emblem"
                        className={styles.add_Emblem}
                        onChange={(e) => handleChange(e, "add_emblem")}
                        accept="image/x-png,image/jpeg,image/jpg"
                        multiple
                      />

                      <img
                        htmlFor="add_emblem"
                        src={
                          state.imagePreview !== "" ? state.imagePreview : null
                        }
                        alt=""
                        style={{
                          width: "195px",
                          height: "195px",
                          borderRadius: "6px",
                        }}
                      />

                      <ErrorMessage name="add_emblem" component={TextError}>
                        {(error) => <div className="error">{error}</div>}
                      </ErrorMessage>
                    </Box>
                    <Box component="div" className={styles.form_box_fields_r}>
                      <label htmlFor="institute_name" className={styles.labels}>
                        Institute Name
                      </label>
                      <Box component="div" className={styles.institute_name}>
                        <Field
                          type="text"
                          id="institute_name"
                          name="institute_name"
                          className={styles.institute_name_f}
                          value={state.institute_name}
                          onChange={(e) =>
                            setState({
                              ...state,
                              institute_name: e.target.value,
                            })
                          }
                        />
                      </Box>
                      <label htmlFor="display_info" className={styles.labels}>
                        Display Info
                      </label>
                      <Box component="div" className={styles.display_info}>
                        <Field
                          type="text"
                          id="display_info"
                          name="display_info"
                          value={state.display_info}
                          className={styles.display_info_f}
                          onChange={(e) =>
                            setState({ ...state, display_info: e.target.value })
                          }
                        />
                      </Box>
                      <label htmlFor="images" className={styles.labels}>
                        Images
                      </label>
                      <Box component="div" className={styles.images}>
                        <Box className={styles.imgs1} component="div">
                          <Field
                            type="file"
                            id="imagepath1"
                            name="imagepath1"
                            className={styles.add_Emblem}
                            onChange={(e) => handleChange(e, "imagepath1")}
                            accept="image/x-png,image/jpeg,image/jpg"
                            multiple
                          />
                          <img
                            htmlFor="image_1"
                            src={
                              state.imgPrev1 !== "" ? state.imgPrev1 : add_emb
                            }
                            alt=""
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "6px",
                            }}
                          />
                        </Box>
                        <Box className={styles.imgs2} component="div">
                          <Field
                            type="file"
                            id="imagepath2"
                            name="imagepath2"
                            className={styles.add_Emblem}
                            onChange={(e) => handleChange(e, "imagepath2")}
                            accept="image/x-png,image/jpeg,image/jpg"
                            multiple
                          />
                          <img
                            htmlFor="image_1"
                            src={state.imgPrev2 !== "" ? state.imgPrev2 : null}
                            alt=""
                            className={styles.imags}
                          />
                        </Box>
                        <Box className={styles.imgs3} component="div">
                          <Field
                            type="file"
                            id="imagepath3"
                            name="imagepath3"
                            className={styles.add_Emblem}
                            onChange={(e) => handleChange(e, "imagepath3")}
                            accept="image/x-png,image/jpeg,image/jpg"
                            multiple
                          />
                          <img
                            htmlFor="image_1"
                            src={state.imgPrev3 !== "" ? state.imgPrev3 : null}
                            alt=""
                            className={styles.imags}
                          />
                        </Box>
                        <Box className={styles.imgs4} component="div">
                          <Field
                            type="file"
                            id="imagepath4"
                            name="imagepath4"
                            className={styles.add_Emblem}
                            onChange={(e) => handleChange(e, "imagepath4")}
                            accept="image/x-png,image/jpeg,image/jpg"
                            multiple
                          />
                          <img
                            htmlFor="image_1"
                            src={state.imgPrev4 !== "" ? state.imgPrev4 : null}
                            alt=""
                            className={styles.imags}
                          />
                        </Box>
                        <Box className={styles.imgs5} component="div">
                          <Field
                            type="file"
                            id="imagepath5"
                            name="imagepath5"
                            className={styles.add_Emblem}
                            onChange={(e) => handleChange(e, "imagepath5")}
                            accept="image/x-png,image/jpeg,image/jpg"
                            multiple
                          />
                          <img
                            htmlFor="image_1"
                            src={
                              state.imgPrev5 !== ""
                                ? state.imgPrev5
                                : state.addImg
                            }
                            alt=""
                            className={styles.imags}
                          />
                        </Box>
                      </Box>
                      <Box component="div" className={styles.table_f}>
                        <div className={styles.schoolList_Table}>
                          <div className={styles.schoolList_Table_head}>
                            {state.t_head.map((elm, key) => (
                              <div
                                key={key}
                                className={styles.schoolList_Table_head_data}
                              >
                                {elm}
                              </div>
                            ))}
                          </div>
                          <div className={styles.schoolList_Table_body}>
                            {state.T_data.cmn_school_syllabus &&
                              state.T_data.cmn_school_syllabus.map(
                                (elm, key) => (
                                  <div
                                    key={key}
                                    className={styles.schoolList_Table_body_row}
                                  >
                                    <div
                                      className={
                                        styles.schoolList_Table_body_row_td2
                                      }
                                    >
                                      {elm.cmn_syllabus_name}
                                    </div>
                                    <div
                                      className={
                                        styles.schoolList_Table_body_row_td3
                                      }
                                    >
                                      {elm.qb_syllabus_name}
                                    </div>
                                    <div
                                      className={
                                        styles.schoolList_Table_body_row_td3
                                      }
                                    >
                                      {elm.qb_language_name}
                                    </div>
                                    <div
                                      className={
                                        styles.schoolList_Table_body_row_td3
                                      }
                                      onClick={() =>
                                        handleEdit(
                                          elm.qb_syllabus_name,
                                          elm.cmn_syllabus_name,
                                          elm.qb_language_name,
                                          elm.cmn_school_syllabus_id
                                        )
                                      }
                                    >
                                      <Icon>
                                        <img
                                          src={require("../../../../assets/icons/edit.png")}
                                          alt="edit"
                                          style={{
                                            width: "16px",
                                            height: "16px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Icon>
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                      </Box>
                      <Box
                        className={styles.btn_add}
                        onClick={() => handleClickOpen("add")}
                      >
                        Add
                      </Box>
                    </Box>
                  </Box>
                </Form>
                <Box className={styles.buttons}>
                  <div className={styles.buttons_div}>
                    <Box component="button" className={styles.btn_cancel}>
                      Cancel
                    </Box>
                    <Box
                      component="button"
                      className={styles.btn_save}
                      onClick={handleFormSave}
                    >
                      Save
                    </Box>
                  </div>
                </Box>
              </Box>
            );
          }}
        </Formik>
        <ModalComponent show={state.showHide} onClick={handleClose}>
          <Formik
            initialValues={initialBox || state}
            validationSchema={validationBox}
            onSubmit={handleSave}
            enableReinitialize
          >
            {(formik) => {
              return (
                <Box component="div" style={{ width: "100%", height: "auto" }}>
                  <Form className={styles.modal_Form}>
                    <label htmlFor="board">Board(stucle)</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        as="select"
                        name="board"
                        value={editVal ? selectedBoard() : undefined}
                        className={styles.options}
                      >
                        <option>{"Board"}</option>
                        {state.optBD.map((elm, key) => {
                          return (
                            <option value={elm.qb_syllabus_id} key={key}>
                              {elm.qb_syllabus_name}
                            </option>
                          );
                        })}
                      </Field>
                    </Box>

                    <label htmlFor="board">Medium of Instruction</label>
                    <Box className={styles.main_box_2} component="div">
                      <Field
                        as="select"
                        name="medium"
                        className={styles.options}
                        value={editVal ? selectedMedium() : undefined}
                      >
                        <option>{"Medium"}</option>
                        {state.optMed.map((elm, key) => {
                          return (
                            <option value={elm.qb_language_id} key={key}>
                              {elm.qb_language_name}
                            </option>
                          );
                        })}
                      </Field>
                    </Box>

                    <label htmlFor="board">Board Display Name</label>
                    <Box className={styles.main_box_3} component="div">
                      <Field
                        name="board_name"
                        placeholder="Board Display Name"
                        className={styles.B_Field}
                        id="board_name"
                        value={editVal ? state.cmn_syllabus_name : undefined}
                        onChange={(e) => {
                          setState({
                            ...state,
                            cmn_syllabus_name: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Box className={styles.buttonss}>
                      {editVal ? (
                        <Box
                          className={styles.deleteBtn}
                          onClick={handleDelete}
                        >
                          <img
                            src={require("../../../../assets/icons/DeleteBox.png")}
                            alt="deletebox"
                            style={{ cursor: "pointer" }}
                          />
                        </Box>
                      ) : null}
                      <button
                        className={styles.cancelBtn}
                        onClick={handleClose}
                        type="reset"
                      >
                        Cancel
                      </button>
                      <button
                        className={styles.saveBtn}
                        type="submit"
                        onClick={(e) => {
                          let addedit_value = document.getElementById(
                            "board_name"
                          ).value;
                          formik.setFieldValue("board_name", addedit_value);
                        }}
                        disabled={!formik.isValid || formik.isSubmitting}
                      >
                        Save
                      </button>
                    </Box>
                  </Form>
                </Box>
              );
            }}
          </Formik>
        </ModalComponent>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
        />
      </Box>
    </Box>
  );
};

export default Institution;
