import React from "react";
import { Bar } from "react-chartjs-2";

const BarChart = ({ values,dates }) => {
  const data = {
    labels: dates,
    datasets: [
      {
        label: "live class",
        data: values,
        borderColor: "",
        backgroundColor: "#6DD669",
        pointBackgroundColor: "black",
        pointBorderColor: "red",
        categoryPercentage: 0.7,
        barPercentage: 0.6,
      },
    ],
  };
  const options = {
    plugins: {
      labels: false,
    },
    responsive: true,
    title: {
      display: false,
      text: "Bar chart",
      position: "top",
      Align: "end",
    },
    legend: false,
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            max: values.max,
            stepSize: 10,
            padding: 20,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            maxTicksLimit: 30,
            padding: 5,
          },
        },
      ],
    },
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Bar data={data} options={options} height={70} />
    </div>
  );
};

export default BarChart;
