import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Switch, Redirect, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import styles from "../../assets/styles/Layout/AdminStyle";
import { Sidebar } from "../index";

import routes from "../../routes";
import {
  Dashboard,
  Status,
  LiveClass,
  Student,
  MasterData,
  Manage,
  Educator,
  Reports,
  Settings,
} from "../../screens/index";

const switchRoutes = (
  <Switch>
    <Route exact path="/admin/dashboard" component={Dashboard} />
    <Route exact path="/admin/status" component={Status} />
    <Route exact path="/admin/manage" component={Manage} />
    <Route exact path="/admin/master-data" component={MasterData} />
    <Route exact path="/admin/settings" component={Settings} />
    <Route exact path="/admin/reports" component={Reports} />
    <Route exact path="/admin/status/live-class" component={LiveClass} />
    <Route exact path="/admin/status/educator" component={Educator} />
    <Route exact path="/admin/status/student" component={Student} />
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);

const useStyles = makeStyles(styles);

const Layout = ({ ...rest }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const mainPanel = React.createRef();
  const classes = useStyles();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== null;
  };

  setTimeout(() => {
    if (window.location.pathname !== "/") {
      toast.error("Session has expired. Login again to continue...");
      setTimeout(() => {
        window.location = "/";
        window.localStorage.clear();
      }, 3000);
    }
  }, window.localStorage.getItem("sessionExp") * 1000);

  if (window.localStorage.getItem("token") === null) {
    return (window.location.href = "/");
  }
  return (
    <>
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          {...rest}
        />
        <div className={classes.mainPanel} ref={mainPanel}>
          {getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes}</div>
            </div>
          ) : null}
        </div>
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
    </>
  );
};

export default Layout;
