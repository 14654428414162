import React, { useState, useEffect} from "react";
import { Icon, Box } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { toast, ToastContainer } from "react-toastify";
import { Formik, Form, Field } from "formik";
import Axios from "axios";
import * as Yup from "yup";
import ReactLoading from "react-loading";
import styles from "./TopicStyle.module.css";
import { BASE_URL } from "../../../../config/Api";
import { ModalComponent } from "../../../../components/index";

var boardvalue = "";
var classvalue = "";
var subjectvalue = "";
var pageNum = 1;
var rows = 10;
var boardID;
var classID;
var subjectID;
var topicID;
var default_page = 1;

const initialValues = {
  board: "",
  class: "",
  subject: "",
  topic: "",
  topic_name: "",
};

const validationBox = Yup.object({
  board: Yup.string(),
  class: Yup.string(),
  subject: Yup.string(),
  topic: Yup.string(),
  topic_name: Yup.string(),
});

let pageNo = 10;

const Topic = () => {
  const [editVal, setEditVal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    t_head: ["Topic", "Subject", "Grade", "Board", "Topic(Stucle)", " "],
    t_body: [],
    option_board: [],
    option_class: [],
    option_sub: [],
    drop_board: [],
    drop_class: [],
    drop_sub: [],
    drop_topic: [],
    board_id: "",
    class_id: "",
    subject_id: "",
    language_id: "",
    topic_id: "",
    cmn_topic_id: "",
    topic_name: "",
    pagecount: null,
    board_editvalue: "Board",
    class_editvalue: "Class",
    subject_editvalue: "Subject",
    topic_stucle_editvalue: "Topic(Stucle) to be linked",
    topic_editvalue: "",
    qb_topic_id_editvalue: "",
    cmn_subject_id_editvalue: "",
    cmn_topic_id_editvalue: "",
  });

  const schoolId = window.localStorage.getItem("school_id");
  window.localStorage.setItem("tabIndex", 2);
  const token = window.localStorage.getItem("token");
  const Auth = {
    headers: {
      Authorization: "Bearer "+ token,
    },
  };

  const fetchResults = async() => {
    setIsLoading(true);
    try {
      const getTopicBoard = await (
        await Axios.get(
          `${BASE_URL}/institution/${schoolId}/topic/board`,
          Auth
        )
      ).data;

      const getTopicClass = await (
        await Axios.get(
          `${BASE_URL}/institution/${schoolId}/topic/class`,
          Auth
        )
      ).data;

      const getTopicSubject = await (
        await Axios.get(
          `${BASE_URL}/institution/${schoolId}/topic/subject`,
          Auth
        )
      ).data;

      const getBoard = await (
        await Axios.get(`${BASE_URL}/institution/${schoolId}/board`, Auth)
      ).data;

      const TableList = await (
        await Axios.get(
          `${BASE_URL}/institution/${schoolId}/topic/search?board_id=&class_id=&subject_id=&rows=10&page_number=1`,
          Auth
        )
      ).data;

      let extraPage = TableList.count % pageNo === 0 ? 0 : 1;

      setIsLoading(false);
      setState({
        ...state,
        t_body: TableList.data,
        option_board: getTopicBoard.data,
        option_class: getTopicClass.data,
        option_sub: getTopicSubject.data,
        drop_board: getBoard.data,
        pagecount: Math.floor(TableList.count / pageNo + extraPage),
      });
    } catch (err) {
      console.log(err, "error while fetching data");
    }
  }

  useEffect(() => {
    fetchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changedBoard = async (e, selectedvalue) => {
    if (selectedvalue === "selectedBoard") {
      pageNum = 1;
      default_page = 1;
      boardvalue = e.target.value;
      if (boardvalue === "Board") {
        boardvalue = "";
        pageNum = 1;
        default_page = 1;
      }
    } else if (selectedvalue === "selectedClass") {
      classvalue = e.target.value;
      pageNum = 1;
      default_page = 1;
      if (classvalue === "Grade") {
        classvalue = "";
        pageNum = 1;
        default_page = 1;
      }
    } else if (selectedvalue === "selectedSubject") {
      subjectvalue = e.target.value;
      pageNum = 1;
      default_page = 1;
      if (subjectvalue === "Subject") {
        subjectvalue = "";
        pageNum = 1;
        default_page = 1;
      }
    } else if (selectedvalue === "pagenumber") {
      window.scrollTo(0, 0);
      window.screenTop = 0;
      pageNum = e;
    } else if (selectedvalue === "showrow") {
      window.screenTop = 0;
      window.scrollTo(0, 0);
      rows = e.target.value;
      pageNo = rows;
      setState({ ...state });
      pageNum = 1;
      default_page = 1;
    }
    try {
      const getTableData = await (
        await Axios.get(
          `${BASE_URL}` +
            `/institution/${schoolId}/topic/search?board_id=${boardvalue}&class_id=${classvalue}&subject_id=${subjectvalue}&rows=${rows}&page_number=${pageNum}`,
          Auth
        )
      ).data;

      let extraPage = getTableData.count % rows === 0 ? 0 : 1;
      setState({
        ...state,
        t_body: getTableData.data,
        pagecount: Math.floor(getTableData.count / rows + extraPage),
      });
    } catch (err) {
      console.log(err, "error");
    }
  };

  const pagechange = (e, value) => {
    default_page = value;
    changedBoard(value, "pagenumber");
  };

  const handleClose = (value) => {
    setState({ ...state, showHide: false });
  };
  const handleClickOpen = () => {
    setState({ ...state, showHide: true });
    setEditVal(false);
  };

  const handleSave = (values, submitProps, formik) => {
    submitProps.setSubmitting(false);
    submitProps.resetForm();
    if (editVal) {
      Axios.put(
        `${BASE_URL}/topic`,
        {
          name: values.topic_name,
          cmn_subject_id: state.cmn_subject_id_editvalue,
          qb_topic_id: state.qb_topic_id_editvalue,
          cmn_topic_id: state.cmn_topic_id_editvalue,
        },
        Auth
      )
        .then((res) => {
          toast.success("data edited successfully!");
          if (res.data.status === true) {
            setTimeout(() => {
              window.location.reload(false);
            }, 3000);
          }
        })
        .catch((err) => toast.error(err.response.data.message));
    } else {
      Axios.post(
        `${BASE_URL}/topic`,
        {
          name: values.topic_name,
          topic_id: topicID,
          subject_id: subjectID,
          // cmn_topic_id: state.cmn_topic_id_editvalue,
        },
        Auth
      )
        .then((res) => {
          toast.success("Topic Crerated successfully!");
          if (res.data.status === true) {
            setTimeout(() => {
              window.location.reload(false);
              toast.success(res.data.data.status);
            }, 3000);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  const handleEdit = async (
    cmn_syllabus_name,
    qb_class_number,
    cmn_subject_name,
    qb_topic_name,
    cmn_topic_name,
    qb_topic_id,
    cmn_subject_id,
    cmn_topic_id
  ) => {
    setEditVal(true);
    setState({
      ...state,
      board_editvalue: cmn_syllabus_name,
      class_editvalue: qb_class_number,
      subject_editvalue: cmn_subject_name,
      topic_stucle_editvalue: qb_topic_name,
      topic_name: cmn_topic_name,
      qb_topic_id_editvalue: qb_topic_id,
      cmn_subject_id_editvalue: cmn_subject_id,
      cmn_topic_id_editvalue: cmn_topic_id,
      showHide: true,
    });
  };

  let request = "";
  const handleDelete = async () => {
    if (state.cmn_topic_id_editvalue)
      request = request + "cmn_topic_id=" + state.cmn_topic_id_editvalue;
    if (state.qb_topic_id_editvalue)
      request =
        request + (request.length === 0)
          ? ""
          : "&qb_topic_id=" + state.qb_topic_id_editval;
    if (state.cmn_subject_id_editvalue)
      request =
        request + (request.length === 0)
          ? ""
          : "&cmn_subject_id=" + state.cmn_subject_id_editvalue;

    Axios.delete(
      `${BASE_URL}/topic?cmn_topic_id=${
        state.cmn_topic_id_editvalue === null
          ? ""
          : state.cmn_topic_id_editvalue
      }&qb_topic_id=${state.qb_topic_id_editvalue}&cmn_subject_id=${
        state.cmn_subject_id_editvalue
      }&name=delete`,
      Auth
    )
      .then((res) => {
        toast.error("data deleted Successfully!");
        if (res.data.status === true) {
          setTimeout(() => {
            window.location.reload(false);
          }, 3000);
        }
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  const handleBoardChange = async (e) => {
    boardID = e.target.value;
    // languageID = selectedBoard();
    const getClass = await (
      await Axios.get(
        `${BASE_URL}/institution/${schoolId}/board/${boardID}/class`,
        Auth
      )
    ).data;
    setState({ ...state, board: boardID, drop_class: getClass.data });
  };

  const handleClassChange = async (e) => {
    classID = e.target.value;
    const getSubject = await (
      await Axios.get(
        `${BASE_URL}/institution/${schoolId}/board/${boardID}/class/${classID}/subject`,
        Auth
      )
    ).data;
    setState({ ...state, class_id: classID, drop_sub: getSubject.data });
  };

  const handleSubjectChange = async (e) => {
    subjectID = e.target.value;
    const getSubject = await (
      await Axios.get(
        `${BASE_URL}/topic/search?syllabus_id=${boardID}&class_id=${classID}&subject_id=${subjectID}`,
        Auth
      )
    ).data;
    setState({ ...state, drop_topic: getSubject.data });
  };
  const handleTopicChange = async (e) => {
    topicID = e.target.value;
    // setState({ ...state, drop_topic: getSubject.data });
  };

  const Example = ({ type, color }) => (
    <div className="loading_animation">
      <ReactLoading type="bars" color="#DCEEDC" height="10%" width="10%" />
    </div>
  );

  return (
    <div className={styles.container}>
      <Box className={styles.top_box} component="div">
        <Box component="div" className={styles.top_box_l}>
          <Box
            component="select"
            className={styles.options}
            onChange={(e) => {
              changedBoard(e, "selectedBoard");
            }}
          >
            <option>{"Board"}</option>
            {state.option_board.map((el, key) => (
              <option value={el.cmn_school_syllabus_id} key={key}>
                {el.cmn_syllabus_name}
              </option>
            ))}
          </Box>
          <Box
            component="select"
            className={styles.options}
            onChange={(e) => {
              changedBoard(e, "selectedClass");
            }}
          >
            <option>{"Grade"}</option>
            {state.option_class
              .sort((a, b) => a.qb_class_number - b.qb_class_number)
              .map((el, key) => (
                <option value={el.qb_class_id} key={key}>
                  {el.qb_class_number}
                </option>
              ))}
          </Box>
          <Box
            component="select"
            className={styles.options}
            onChange={(e) => {
              changedBoard(e, "selectedSubject");
            }}
          >
            <option>{"Subject"}</option>
            {state.option_sub.map((el, key) => (
              <option value={el.cmn_subject_id} key={key}>
                {el.cmn_subject_name}
              </option>
            ))}
          </Box>
        </Box>
        {/* <Box component="div" className={styles.buttons}>
          <Box
            component="div"
            className={styles.buttons_add}
            onClick={handleClickOpen}
          >
            Add
          </Box>
          <Box component="div" className={styles.buttons_format}>
            <img
              src={require("../../../../assets/icons/down.png")}
              alt="down"
              style={{ paddingRight: "10px" }}
            />
            Download Topics List
          </Box>
          <Box component="div" className={styles.buttons_data}>
            <img
              src={require("../../../../assets/icons/up.png")}
              alt="up"
              style={{ paddingRight: "10px" }}
            />
            Upload Topics List
          </Box>
        </Box> */}
      </Box>
      <Box component="div" className={styles.tableBox}>
        <div className={styles.table_box}>
          {isLoading === true ? (
            <Example />
          ) : (
            <div className={styles.schoolList_Table}>
              <div className={styles.schoolList_Table_head}>
                {state.t_head.map((elm, key) => (
                  <div key={key} className={styles.schoolList_Table_head_data}>
                    {elm}
                  </div>
                ))}
              </div>
              {
                <div className={styles.schoolList_Table_body}>
                  {state.t_body.length === 0
                    ? <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                    }}
                  >
                    No Data Found<span role='img' aria-label='jsx-a11y/accessible-emoji'>😔</span>
                  </div>
                    : state.t_body.map((elm, key) => (
                        <div
                          key={key}
                          className={styles.schoolList_Table_body_row}
                        >
                          <div className={styles.schoolList_Table_body_row_td2}>
                            {elm.cmn_topic_name}
                          </div>
                          <div className={styles.schoolList_Table_body_row_td3}>
                            {elm.cmn_subject_name}{" "}
                          </div>
                          <div className={styles.schoolList_Table_body_row_td3}>
                            {elm.qb_class_number}
                          </div>
                          <div className={styles.schoolList_Table_body_row_td3}>
                            {elm.cmn_syllabus_name}
                          </div>
                          <div className={styles.schoolList_Table_body_row_td3}>
                            {elm.qb_topic_name}
                          </div>
                          <div
                            className={styles.schoolList_Table_body_row_td3}
                            onClick={() => {
                              handleEdit(
                                elm.cmn_syllabus_name,
                                elm.qb_class_number,
                                elm.cmn_subject_name,
                                elm.qb_topic_name,
                                elm.cmn_topic_name,
                                elm.qb_topic_id,
                                elm.cmn_subject_id,
                                elm.cmn_topic_id
                              );
                            }}
                          >
                            <Icon>
                              <img
                                src={require("../../../../assets/icons/edit.png")}
                                alt="edit"
                                style={{
                                  width: "16px",
                                  height: "16px",
                                  cursor: "pointer",
                                }}
                              />
                            </Icon>
                          </div>
                        </div>
                      ))}
                </div>
              }
            </div>
          )}
        </div>
      </Box>
      <Box className={styles.pages} component="div">
        <div
          className="col-md-10"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "50px",
          }}
        >
          <Pagination
            page={default_page}
            count={state.pagecount}
            onChange={pagechange}
          />
        </div>
        <div className="col-md-2 mr-5">
          <Box
            component="select"
            className={styles.options}
            onChange={(e) => {
              changedBoard(e, "showrow");
            }}
          >
            {/* <option value="all">{"Show All"}</option> */}
            <option value="10">{"Show 10"}</option>
            <option value="20">{"Show 20"}</option>
          </Box>
        </div>
      </Box>
      <ModalComponent show={state.showHide} onClick={handleClose}>
        <Box
          className={styles.main_box}
          component="div"
          style={{ marginTop: "-20px" }}
        >
          <Formik
            initialValues={initialValues || state}
            validationSchema={validationBox}
            onSubmit={handleSave}
            enableReinitialize
          >
            {(formik) => {
              return (
                <Box component="div" style={{ width: "100%", height: "auto" }}>
                  <Form className={styles.modal_Form}>
                    <label htmlFor="board">Board</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        as="select"
                        name="board"
                        value={editVal ? state.board_editvalue : undefined}
                        // value={undefined}
                        className={styles.d_options}
                        onChange={(e) => handleBoardChange(e)}
                      >
                        <option>
                          {editVal ? state.board_editvalue : "Board"}
                        </option>
                        {state.drop_board.map((elm, key) => {
                          return (
                            <option
                              value={elm.cmn_school_syllabus_id}
                              key={key}
                            >
                              {elm.cmn_syllabus_name
                                ? elm.cmn_syllabus_name
                                : elm.qb_syllabus_name}
                            </option>
                          );
                        })}
                      </Field>
                    </Box>
                    <label htmlFor="board">Grade</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        as="select"
                        name="class"
                        id="class"
                        value={editVal ? state.class_editvalue : undefined}
                        // value={undefined}
                        className={styles.d_options}
                        onChange={(e) => handleClassChange(e)}
                      >
                        <option>
                          {editVal ? state.class_editvalue : "Grade"}
                        </option>
                        {state.drop_class.map((elm, key) => {
                          return (
                            <option value={elm.qb_class_id} key={key}>
                              {elm.qb_class_number}
                            </option>
                          );
                        })}
                      </Field>
                    </Box>
                    <label htmlFor="board">Subject</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        as="select"
                        name="subject"
                        className={styles.d_options}
                        // value={undefined}
                        value={editVal ? state.subject_editvalue : undefined}
                        onChange={handleSubjectChange}
                      >
                        <option>
                          {editVal ? state.subject_editvalue : "Subject"}
                        </option>
                        {state.drop_sub.map((elm, key) => {
                          return (
                            <option value={elm.cmn_subject_id} key={key}>
                              {elm.cmn_subject_name}
                            </option>
                          );
                        })}
                      </Field>
                    </Box>
                    <label htmlFor="board">Topic (Stucle) to be linked</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        as="select"
                        name="topic"
                        className={styles.d_options}
                        value={
                          editVal ? state.topic_stucle_editvalue : undefined
                        }
                        // value={undefined}
                        onChange={(e) => handleTopicChange(e)}
                      >
                        <option>
                          {editVal
                            ? state.topic_stucle_editvalue
                            : "Topic(Stucle) to be linked"}
                        </option>
                        {state.drop_topic.map((elm, key) => {
                          return (
                            <option value={elm.qb_topic_id} key={key}>
                              {elm.qb_topic_name}
                            </option>
                          );
                        })}
                      </Field>
                    </Box>
                    <label htmlFor="board">Topic Display Name</label>
                    <Box className={styles.main_box_1} component="div">
                      <Field
                        name="topic_name"
                        placeholder="Topic Display Name"
                        className={styles.B_Field}
                        id="topic_name"
                        value={editVal ? state.topic_name : undefined}
                        // value={undefined}
                        onChange={(e) => {
                          setState({
                            ...state,
                            topic_name: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Box className={styles.buttonss}>
                      {editVal ? (
                        <Box
                          className={styles.deleteBtn}
                          onClick={handleDelete}
                        >
                          <img
                            src={require("../../../../assets/icons/DeleteBox.png")}
                            alt="deletebox"
                            style={{ cursor: "pointer" }}
                          />
                        </Box>
                      ) : null}
                      <button
                        className={styles.cancelBtn}
                        onClick={handleClose}
                        type="reset"
                      >
                        Cancel
                      </button>
                      <button
                        className={styles.saveBtn}
                        type="submit"
                        onClick={(e) => {
                          let addedit_value = document.getElementById(
                            "topic_name"
                          ).value;
                          formik.setFieldValue("topic_name", addedit_value);
                        }}
                        disabled={!formik.isValid || formik.isSubmitting}
                      >
                        Save
                      </button>
                    </Box>
                  </Form>
                </Box>
              );
            }}
          </Formik>
        </Box>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
        />
      </ModalComponent>
    </div>
  );
};

export default Topic;
