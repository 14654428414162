import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import {AppBar,Toolbar,IconButton,Hidden,Button} from "@material-ui/core";
import Menu from "@material-ui/icons/Menu";
import styles from '../../assets/styles/Layout/HeaderStyle'
const useStyles = makeStyles(styles);

const Navbar = (props) => {
  const classes = useStyles();
    function makeBrand() {
        var name = window.location.pathname       
        return name
    }
    return (
        <AppBar className={classes.appBar }>
        <Toolbar className={classes.container}>
          <div className={classes.flex}>
            {/* Here we create navbar brand, based on route name */}
            <Button color="primary" href="#" className={classes.title}>
              {makeBrand()}
            </Button>
          </div>
          <Hidden mdUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={props.handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    )
}

export default Navbar
